<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReceiptListItemPlaceholder",
});
</script>

<template>
  <div class="w-full bg-white flex flex-col rounded-md overflow-hidden">
    <div class="bg-gray-200 h-12 w-full flex items-center px-4 gap-8">
      <div class="w-14 h-7 bg-gray-400 rounded-md animate-pulse" />
      <div class="flex items-center gap-2 animate-pulse">
        <div class="w-8 h-8 bg-gray-300 rounded-full" />
        <div class="w-20 h-5 bg-gray-300 rounded-md" />
      </div>
      <div class="w-24 h-5 bg-gray-400 rounded-md animate-pulse" />
    </div>
    <div class="flex p-4 gap-4">
      <div class="w-40 h-32 bg-gray-200 rounded" />
      <div class="flex flex-col gap-4 w-full animate-pulse">
        <div class="w-20 h-5 bg-gray-300 rounded-md" />
        <div class="w-3/4 h-12 bg-gray-400 rounded-md" />
        <div class="grid grid-cols-4 gap-4">
          <div class="w-24 h-5 bg-gray-200 rounded-md" />
          <div class="w-14 h-5 bg-gray-200 rounded-md" />
          <div class="w-[10rem] h-5 bg-gray-200 rounded-md" />
          <div class="w-16 h-5 bg-gray-200 rounded-md" />
          <div class="w-[4rem] h-5 bg-gray-200 rounded-md" />
          <div class="w-[7rem] h-5 bg-gray-200 rounded-md" />
          <div class="w-[3rem] h-5 bg-gray-200 rounded-md" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
