<template>
  <MainHeader />
  <div class="flex">
    <SearchMenu ref="searchMenu" endpoint="etsy/listing" @loaded="handleData" />
    <div v-if="listings" class="grid grid-cols-4 gap-4 w-full p-4">
      <EtsyListingCard
        v-for="listing in listings"
        :key="listing.id"
        :listing="listing"
      />
    </div>
  </div>
</template>

<script setup>
import MainHeader from "@/components/MainHeader.vue";
import { onMounted, ref } from "vue";
import EtsyListingCard from "./EtsyListingCard.vue";
import SearchMenu from "@/components/misc/SearchMenu.vue";

const listings = ref(null);

function handleData(data) {
  console.log(data);
  listings.value = data.data;
}
onMounted(() => {
  console.log("mounted");
});
</script>
