<script setup>
const props = defineProps({
  progress: {
    type: Number,
    default: 0,
  },
  name: {
    type: String,
    default: null,
  },
});
</script>

<template>
  <div>
    <div class="flex justify-between">
      <span>{{ props.name }}</span>
      <span>{{ props.progress }}%</span>
    </div>
    <div class="w-full bg-gray-200 rounded-full h-1">
      <div
        class="bg-sky-600 h-1 rounded-full"
        :style="{ width: `${props.progress}%` }"
      />
    </div>
  </div>
</template>

<style scoped></style>
