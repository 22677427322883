<script>
import { defineComponent } from "vue";
import UserBadge from "@/components/misc/UserBadge.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useSettingsStore } from "@/stores/settings";

export default defineComponent({
  name: "UserAttacher",
  components: { FontAwesomeIcon, UserBadge },
  props: {
    resourceId: {
      type: Number,
      required: true,
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:users"],
  setup() {
    const { users } = useSettingsStore();
    const allUsers = users;
    return { allUsers };
  },
  methods: {
    availableUsers() {
      return this.allUsers.filter((a) => {
        return this.users.findIndex((u) => u.id === a.id) === -1;
      });
    },
    attachUser(user) {
      this.axios
        .put(`order/${this.resourceId}/attach-user/${user.id}`)
        .then((r) => {
          this.$emit("update:users", r.data);
        });
    },
    detachUser(user) {
      this.axios
        .put(`order/${this.resourceId}/detach-user/${user.id}`)
        .then((r) => {
          this.$emit("update:users", r.data);
        });
    },
  },
});
</script>

<template>
  <div class="flex items-center gap-2 flex-wrap">
    <UserBadge
      v-for="user in users"
      :key="user.id"
      :user="user"
      editable
      @detach-user="detachUser(user)"
    />
    <button
      v-if="availableUsers().length > 0"
      class="default !w-7 !h-7 !rounded-full group relative"
      title="Görevli Ekle"
    >
      <font-awesome-icon icon="plus" size="sm"></font-awesome-icon>
      <div
        class="group-focus:flex flex-col hidden absolute top-8 z-50 w-min text-left rounded-2xl gap-1"
      >
        <div
          v-for="user in availableUsers()"
          :key="user.id"
          class="rounded-full whitespace-nowrap relative overflow-hidden shadow-xl transition-colors bg-gray-100"
          @click="attachUser(user)"
        >
          <div
            class="!z-[99] w-full h-full bg-gray-800 hover:!bg-sky-600 transition-colors px-2 py-0.5"
          >
            <font-awesome-icon icon="circle-user" class="w-4" />
            {{ user.name }}
          </div>
        </div>
      </div>
    </button>
  </div>
</template>

<style scoped></style>
