<template>
  <div class="pagination">
    <button
      :disabled="disabled || data.current_page === 1"
      class="!w-8 !h-8"
      @click="changePage(data.current_page - 1)"
    >
      <font-awesome-icon icon="fas fa-caret-left" />
    </button>
    <ul class="flex flex-wrap justify-center gap-2 px-4">
      <li v-for="page in pages" :key="page.label">
        <button
          :class="{
            active: page.active,
            '!bg-transparent !border-none !text-white': !page.url,
          }"
          :disabled="disabled || !page.url"
          @click="
            () => {
              page.url ? changePage(page.label) : null;
            }
          "
        >
          {{ page.label }}
        </button>
      </li>
    </ul>
    {{ data.links.next }}
    <button
      :disabled="disabled || data.current_page === data.last_page"
      @click="changePage(data.current_page + 1)"
    >
      <font-awesome-icon icon="fas fa-caret-right" />
    </button>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "PaginationMeta",
  components: { FontAwesomeIcon },
  props: ["data", "disabled"],
  emits: ["pageChanged"],
  computed: {
    pages() {
      return this.data
        ? this.data.links.filter(
            (p) => Number.parseInt(p.label) || p.label === "..."
          )
        : null;
    },
  },
  methods: {
    changePage(page) {
      this.$emit("pageChanged", page);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";
.pagination {
  @apply flex flex-row items-start justify-center #{!important};
}
.pagination button {
  @apply w-8 h-8 transition-colors py-1 border bg-white hover:bg-sky-300 text-sky-950 hover:text-white font-bold border-none #{$default-rounded};
}

.pagination button:hover {
  @apply border-none;
}
.pagination button.active {
  @apply bg-sky-950 border-none text-white #{!important};
}
.pagination button[disabled],
button:disabled {
  @apply opacity-50 cursor-not-allowed #{!important};
}
</style>
