<script>
export default {
  name: "UrgentNotification",
};
</script>

<template>
  <div class="urgent" title="Acil"></div>
</template>

<style lang="scss" scoped>
.urgent {
  @apply bg-red-500 w-3 h-3 rounded-full flex items-center justify-center;
}
.urgent:before {
  content: "";
  @apply bg-red-500 w-3 h-3 rounded-full animate-ping;
}
</style>
