<script>
import { defineComponent } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { toRaw } from "vue";
import { cloneDeep } from "lodash";
import { clean } from "@/lib/Helpers";
import { tr } from "@formkit/i18n";
export default defineComponent({
  name: "CustomOrganizer",
  components: { FontAwesomeIcon },
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ["update:modelValue", "deleteField"],
  data() {
    return {
      editMode: false,
      data: null,
      customOrganizerInput: null,
      customOrganizerInputForm: {
        side_a: {
          inside: null,
          outside: null,
          top: null,
        },
        side_b: {
          inside: null,
          outside: null,
          top: null,
        },
        side_c: {
          inside: null,
          outside: null,
          top: null,
        },
        side_d: {
          inside: null,
          outside: null,
          top: null,
        },
        cover: null,
      },
    };
  },
  computed: {
    filledFields() {
      return this.data ? this.checkValues(this.data) : {};
      // return this.data;
    },
  },
  mounted() {
    this.data = Object.assign(
      this.customOrganizerInputForm,
      this.modelValue ?? {},
    );
    if (this.modelValue === null) this.toggleEditMode(true);

    // console.log("model", this.modelValue);
    // console.log(this.filledFields);
  },
  methods: {
    hasChild(customField, key) {
      return customField !== null && typeof customField === "object";
    },
    toggleEditMode(to = !this.editMode) {
      this.editMode = to;
      this.customOrganizerInput = Object.assign(
        this.customOrganizerInputForm,
        this.data,
      );
      // if (this.editMode) {
      //   this.customOrganizerInput = null;
      //   this.editMode = false;
      // } else {
      //   this.customOrganizerInput = cloneDeep(this.customOrganizerInputForm);
      //   this.editMode = true;
      // }
    },
    checkValues(data) {
      const filled = {};
      const filled_keys = Object.keys(data).filter((key) => {
        return Boolean(data[key]);
      });
      filled_keys.forEach((f) => {
        if (typeof data[f] === "object") {
          filled[f] = this.checkValues(data[f]);
        } else {
          filled[f] = data[f];
        }
      });
      return filled;
    },
    getKeyName(key) {
      const names = {
        inside: "İç",
        outside: "Dış",
        top: "Üst",
        side_a: "A Kenarı",
        side_b: "B Kenarı",
        side_c: "C Kenarı",
        side_d: "D Kenarı",
        cover: "Kapak",
      };
      return names[key] ? names[key] : key;
    },
  },
});
</script>

<template>
  <div class="flex flex-col bg-white p-2 border rounded">
    <div class="text-lg font-bold mb-2 flex justify-between">
      <span>Organizer Detayları</span>
      <div class="right">
        <div v-if="editMode" class="flex gap-2">
          <button
            class="success sm"
            @click="
              $emit('update:modelValue', customOrganizerInput);
              editMode = false;
            "
          >
            <font-awesome-icon icon="floppy-disk" /> Kaydet
          </button>
          <button
            class="danger sm"
            @click="
              $emit('deleteField');
              editMode = false;
            "
          >
            <font-awesome-icon icon="trash" /> Sil
          </button>
          <button class="gray sm" @click="toggleEditMode()">İptal Et</button>
        </div>
        <div v-else>
          <button @click="toggleEditMode()">
            <font-awesome-icon icon="fa-pen-to-square" />
          </button>
        </div>
      </div>
    </div>
    <div v-if="!editMode" class="grid grid-cols-2 gap-2">
      <table v-for="(custom_field, key) in filledFields" :key="key">
        <tr v-if="Object.keys(custom_field).length > 0" class="font-semibold">
          <td colspan="2">
            {{ getKeyName(key) }}
          </td>
        </tr>
        <template v-if="hasChild(custom_field)">
          <tr v-for="(field, key) in custom_field" :key="key">
            <td>
              {{ getKeyName(key) }}
            </td>
            <td>
              {{ field }}
            </td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="2" class="!text-left">
            {{ custom_field }}
          </td>
        </tr>
      </table>
    </div>
    <div v-else class="flex flex-col gap-2">
      <table
        v-for="(custom_field, key) in customOrganizerInput"
        :key="key"
        :class="{ 'col-span-2': key === 'cover' }"
      >
        <tr class="font-semibold">
          <td colspan="2">
            {{ getKeyName(key) }}
          </td>
        </tr>

        <template v-if="hasChild(custom_field, key)">
          <tr v-for="(field, field_key) in custom_field" :key="field_key">
            <td>
              {{ getKeyName(field_key) }}
            </td>
            <td class="!p-0">
              <FormKit
                v-model="customOrganizerInput[key][field_key]"
                type="text"
                placeholder="Detay girin..."
                :classes="{
                  outer: '!mb-0',
                  inner:
                    '!mb-0 !ring-0 focus-within:ring-0 rounded-none border-0',
                  input: '!px-2 !py-1 text-sm',
                }"
              ></FormKit>
            </td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="2">
            <FormKit
              v-model="customOrganizerInput[key]"
              type="text"
              :classes="{
                outer: '!mb-0',
                inner: '!mb-0 ring-0 focus-within:ring-0 rounded-none',
                input: '!px-2 !py-1 text-sm',
              }"
            ></FormKit>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
table {
  & {
    @apply w-full;
  }
  & td {
    @apply border px-2 py-1 bg-white rounded;
  }
  & tr:not(:first-child) td:first-child {
    @apply text-right w-1/3;
  }
  .flex-col > & tr:not(:first-child) td:first-child {
    @apply text-right w-1/4;
  }
}
</style>
