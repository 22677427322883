<template>
  <div
    class="border"
  >
    <div class="flex justify-between items-center border-b p-2 bg-gray-50">
      <div class="flex gap-2">
        <div class="rounded-full border border-gray-500 h-5 w-5 text-xs flex items-end justify-center overflow-hidden bg-gray-100 font-bold text-gray-500">
          <font-awesome-icon
            icon="user"
            class="w-3.5 h-3.5"
          />
        </div>
        <a
          href="#"
          class="font-semibold"
        >
          {{ log.user.name }}
        </a>
      </div>
      <h4
        class="text-sm opacity-50"
        :title="log.created_at"
      >
        {{ diffForHumans(log.created_at) }}
      </h4>
    </div>
    <div class="flex py-2 px-4">
      <div class="w-full lg:w-3/8">
        <ul>
          <li
            v-for="(value, key, index) in log.old_data"
            :key="index"
            class="flex justify-start whitespace-nowrap"
          >
            <span class="font-bold">{{ keyTranslate(key) }}</span>: {{ formatValue(key, value) }}
          </li>
        </ul>
      </div>
      <div class="w-full lg:w-2/8 flex center">
        <font-awesome-icon
          icon="arrow-right"
          class="opacity-20"
        />
      </div>
      <div class="w-full lg:w-3/8">
        <ul>
          <li
            v-for="(value, key, index) in log.new_data"
            :key="index"
            class="flex justify-start whitespace-nowrap"
          >
            <span class="font-bold">{{ keyTranslate(key) }}</span>: {{ formatValue(key, value) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/tr'

dayjs.extend(relativeTime);
dayjs.locale('tr')
export default {
  name: "SchemaChangeLog",
  props: ['log'],
  methods: {
    diffForHumans: (date) => {
      if (!date){
        return null;
      }

      return dayjs(date).fromNow();
    },
    formatValue(key, value){
      const dimKeys = [
        'width', 'width_second', 'height', 'height_second', 'depth', 'depth_second'
      ]
      if (dimKeys.includes(key)){
        return value / 10 + ' CM'
      } else if (value === null) return '-'
      return value;
    },
    keyTranslate(key){
      const translate = {
        'width': 'Genişlik',
        'width_second': 'İkinci Genişlik',
        'height': 'Yükseklik',
        'height_second': 'İkinci Yükseklik',
        'depth': 'Derinlik',
        'depth_second': 'İkinci Derinlik',
        'name': 'Şema Adı',
        'description': 'Açıklama',
        'compatible_brand': 'Uyumlu Marka',
        'compatible_model': 'Uyumlu Model',
      }

      if (translate[key]) return translate[key]
      else return key
    },
  }
}
</script>

<style scoped>

</style>