import axios from "axios";
const env = import.meta.env;

const axiosClient = axios.create({
  baseURL: env.VITE_BACKEND_URL,
  withCredentials: true,
});

export function initAxios(connection) {
  const router = connection.router;
  const toast = connection.toast;
  const auth = connection.auth;
  // AXIOS SETUP
  axiosClient.interceptors.response.use(
    (response) => {
      if (response.data.message) {
        toast.success(response.data.message);
      }
      return response;
    },
    function (error) {
      if (!error.response) return Promise.reject(error);
      if (error.response.status === 401) {
        console.log(auth.isAuthenticated);
        if (
          auth.isAuthenticated &&
          !error.response.request.responseURL.endsWith("logout")
        ) {
          auth.logout();
        }
        return router.push("/login");
      } else if (error.response.status >= 400) {
        if (error.response.data.message) {
          toast.error(error.response.data.message);
        }
      } else {
        return Promise.reject(error);
      }
    }
  );
  axiosClient.interceptors.request.use((config) => {
    if (auth.isAuthenticated) {
      config.headers.Authorization = `Bearer ${auth.getToken}`;
    }
    return config;
  });
}
// AXIOS SETUP END

export default axiosClient;
