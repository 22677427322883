<script>
import MainHeader from "@/components/MainHeader.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "SettingsPage",
  components: { FontAwesomeIcon, MainHeader },
};
</script>

<template>
  <MainHeader></MainHeader>
  <div class="flex container mx-auto py-4 gap-4">
    <div
      class="flex flex-col w-1/4 bg-white rounded-md p-2 setting__menu h-min"
    >
      <router-link :to="{ name: 'personal' }">
        <font-awesome-icon icon="user"></font-awesome-icon>
        Kişisel Ayarlar
      </router-link>
      <router-link
        v-if="$auth.can('update settings.global')"
        :to="{ name: 'global' }"
      >
        <font-awesome-icon icon="globe"></font-awesome-icon>
        Global Ayarlar
      </router-link>
      <router-link
        v-if="$auth.can('update settings.tag')"
        :to="{ name: 'tags' }"
      >
        <font-awesome-icon icon="tags"></font-awesome-icon>
        Etiketler
      </router-link>
      <router-link
        v-if="$auth.can('update settings.user')"
        :to="{ name: 'users' }"
      >
        <font-awesome-icon icon="users" size="sm"></font-awesome-icon>
        Kullanıcılar
      </router-link>
      <router-link
        v-if="$auth.can('update permission')"
        :to="{ name: 'permissions' }"
      >
        <font-awesome-icon icon="file-signature" size="sm"></font-awesome-icon>
        İzinler
      </router-link>
    </div>
    <div class="w-3/4 bg-white rounded-md p-4 settings__tab">
      <component :is="$route.meta.tab"></component>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.setting__menu {
  button,
  a {
    & {
      @apply py-2 text-gray-700 transition-colors box-border inline-block flex items-center gap-2 justify-start px-6;
    }
    &:not(:last-child) {
      @apply border-b;
    }
    &.router-link-active,
    &.active,
    &:hover {
      @apply bg-gray-200 border-b-transparent;
    }
  }
}
</style>
<style lang="scss">
.settings__tab {
  h1 {
    @apply text-2xl font-bold mb-2;
  }
  h2 {
    @apply text-xl font-bold mb-2;
  }
}
</style>
