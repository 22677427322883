<template>
  <MultiSelect
    :selected-options="selectedSchemas"
    :options="schemaOptions"
    placeholder="Şema bağlantısı ekleyin..."
    @searchchange="(e) => searchDebounce(e, getGuides)"
    @select="(e) => emit('updateSchemas', e)"
  />
</template>

<script setup>
import { MultiSelect } from "vue-search-select";
import { defineProps, ref, onMounted, watch } from "vue";
import axiosClient from "@/lib/AxiosClient";
const emit = defineEmits(["updateSchemas"]);
const props = defineProps({
  guides: {
    type: Object,
    required: true,
  },
});
watch(
  () => props.guides,
  (guides) => {
    selectedSchemas.value = guides.map(optionFormatter);
  }
);
function optionFormatter(s) {
  return {
    text: s.name ? s.name : `${s.compatible_brand} • ${s.compatible_model}`,
    value: s.id,
  };
}

const timeout = ref(null);
function searchDebounce(keyword, cb) {
  clearTimeout(timeout.value);
  timeout.value = setTimeout(() => {
    cb(keyword);
  }, 500);
}
const schemaOptions = ref([]);
function getGuides(keyword) {
  if (keyword === "") return;
  return axiosClient
    .get(`/guide`, {
      params: {
        keyword,
        limit: 10,
      },
    })
    .then((r) => {
      schemaOptions.value = r.data.data.map(optionFormatter);
    });
}

const selectedSchemas = ref([]);
onMounted(() => {
  selectedSchemas.value = props.guides.map(optionFormatter);
});
</script>
