<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "TranslateButton"
})
</script>

<template>
  <span
    class="text-xs text-blue-500 flex gap-1 justify-end items-center cursor-pointer"
    title="Metni Çevir"
  ><font-awesome-icon icon="language" />Çevir</span>
</template>

<style scoped>

</style>