<template>
  <MainHeader />
  <div class="container mx-auto">
    <div class="flex my-2">
      <button class="success px-2 py-1 rounded" @click="addShop">
        <font-awesome-icon icon="plus" />
        Dükkan Ekle
      </button>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
      <EtsyShopCard v-for="shop in shops" :key="shop.id" :shop="shop" />
    </div>
  </div>
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import DropdownButton from "@/components/misc/DropdownButton.vue";
import EtsyShopCard from "@/pages/etsy/EtsyShopCard.vue";

export default {
  name: "EtsyShops",
  components: { EtsyShopCard, FontAwesomeIcon, MainHeader },

  data() {
    return {
      shops: [],
    };
  },
  mounted() {
    this.callbackRequest();
    this.getShops().then((r) => {
      this.shops = r.data;
    });
  },
  methods: {
    callbackRequest() {
      if (!this.$route.query.code && !this.$route.query.state) {
        return;
      }
      this.axios.get("etsy/callback1", {
        params: {
          code: this.$route.query.code,
          status: this.$route.query.state,
        },
      });
      this.getShops();
      this.$router.replace({ name: "etsy_shops" }, {});
    },
    addShop() {
      this.axios("etsy/addshop").then((r) => {
        window.open(r.data.etsy_login_url, "_blank");
      });
    },
    getShops() {
      return this.axios.get("/etsy/shop");
    },
  },
};
</script>

<style scoped></style>
