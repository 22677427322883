<template>
  <MainHeader />
  <div class="flex">
    <SearchMenu
      ref="searchMenu"
      endpoint="guide"
      :extra-sort-options="[
        {
          label: 'Önce Son Güncellenen',
          value: {
            by: 'updated_at',
            order: 'DESC',
          },
        },
        {
          label: 'Önce Eski Güncellenen',
          value: {
            by: 'updated_at',
            order: 'ASC',
          },
        },
      ]"
      @loaded="(response) => (schemas = response.data)"
      @loading="(l) => (loading = l)"
    >
      <FormKit
        type="checkbox"
        name="no_etsy_listing"
        :value="false"
        :classes="{ label: 'text-white' }"
        label="Eşleşmesi Olmayan"
      />
      <FormKit
        type="checkbox"
        name="no_product_dimension"
        :value="false"
        :classes="{ label: 'text-white' }"
        label="Uyumlu Ürün Boyutu Olmayan"
      />
    </SearchMenu>
    <div class="w-full mx-auto">
      <div class="flex px-4 py-4 w-full bg-gray-800">
        <button class="success" @click="() => createModal.open()">
          <font-awesome-icon icon="fa-plus"></font-awesome-icon>
          Yeni Şema Oluştur
        </button>
      </div>
      <SchemasTable
        :schemas="schemas"
        :brands="brands"
        @schema-deleted="() => $refs.searchMenu.getResources()"
      >
      </SchemasTable>
    </div>
  </div>
</template>

<script>
import CreateSchemaModalVue from "@/components/modals/CreateSchemaModal.vue";
import MainHeader from "@/components/MainHeader.vue";
import { useModal, useVfm } from "vue-final-modal";
import SchemasTable from "@/components/tables/SchemasTable.vue";
import SearchMenu from "@/components/misc/SearchMenu.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  name: "SchemasPage",
  components: {
    FontAwesomeIcon,
    SchemasTable,
    // SchemaCard,
    MainHeader,
    SearchMenu,
  },
  setup() {
    const createModal = useModal({
      component: CreateSchemaModalVue,
    });
    return { createModal };
  },
  data() {
    return {
      schemas: [],
      brands: null,
      loading: false,
      extraParameters: {},
    };
  },

  mounted() {
    this.axios.get("guide/brands").then((r) => (this.brands = r.data.data));
  },
  methods: {},
};
</script>

<style scoped></style>
