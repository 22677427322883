<template>
  <slot name="default" :print="print"></slot>
  <iframe id="labelPrint" style="display: none" class="hidden"></iframe>
</template>

<script>
export default {
  name: "PrintLabel",
  props: ["orderId"],
  data() {
    return {
      iframe: null,
    };
  },
  mounted() {
    this.iframe = this.getIframe();
  },
  methods: {
    getIframe() {
      return document.getElementById("labelPrint");
    },
    print() {
      // this.src = `/order/${this.item.id}/get-label`;
      this.axios.get(`/order/${this.orderId}/get-label`).then((r) => {
        this.iframe.src = "about:blank";
        this.iframe.contentDocument.write(r.data);
        this.iframe.contentWindow.print();
        this.iframe.src = "about:blank";
        this.iframe.contentDocument.write(null);
      });
    },
  },
};
</script>

<style scoped>
.print-area {
  display: none;
}
</style>
