<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import TagSpan from "@/components/misc/TagSpan.vue";
import { useAuthStore } from "@/stores/auth";

export default {
  name: "InlineTags",
  components: { TagSpan, FontAwesomeIcon },
  props: {
    tags: {
      type: Array,
      default() {
        return [];
      },
    },
    taggableId: {
      type: [String, Number],
      default() {
        return null;
      },
    },
    taggableType: {
      type: String,
      default() {
        return null;
      },
    },
  },
  setup() {
    const auth = useAuthStore();
    return {
      auth,
    };
  },
  emits: ["update:tags"],
  data() {
    return {
      allTags: [],
    };
  },
  mounted() {
    if (this.taggableType && this.taggableId) this.getTags();
  },
  methods: {
    doesExists(id) {
      return this.tags.find((t) => t.id === id);
    },
    getTags() {
      this.axios.get("tag").then((r) => (this.allTags = r.data.data));
    },
    getAvailableTags() {
      return this.allTags.filter((t) => !this.doesExists(t.id));
    },
    addTag(id) {
      if (!this.auth.can("assign tag to " + this.taggableType.toLowerCase()))
        return;
      this.axios
        .put(
          `${this.taggableType.toLowerCase()}/${
            this.taggableId
          }/attach-tag/${id}`
        )
        .then((r) => {
          this.$emit("update:tags", r.data.tags);
        });
    },
    detachTag(id) {
      if (!this.auth.can("assign tag to " + this.taggableType.toLowerCase()))
        return;
      if (!this.taggableId || !this.taggableType) return;
      this.$swal({
        title: "Bu etiket bu siparişten silinsin mi?",
        showConfirmButton: true,
        showDenyButton: true,
        denyButtonText: "İptal Et",
        confirmButtonText: "Evet",
      }).then((r) => {
        if (r.isConfirmed) {
          this.axios
            .put(
              `${this.taggableType.toLowerCase()}/${
                this.taggableId
              }/detach-tag/${id}`
            )
            .then((r) => this.$emit("update:tags", r.data.tags));
        }
      });
    },
  },
};
</script>

<template>
  <div
    v-if="tags.length > 0 || taggableType"
    class="flex flex-row items-center gap-2 flex-wrap"
  >
    <TagSpan
      v-for="(tag, index) in tags"
      :key="index"
      :background="tag.color"
      :created-at="tag.pivot.created_at"
      @click="detachTag(tag.id)"
      >{{ tag.name }}</TagSpan
    >
    <button
      v-if="
        taggableType &&
        taggableId &&
        getAvailableTags().length > 0 &&
        auth.can('assign tag to ' + $props.taggableType.toLowerCase())
      "
      class="default !w-7 !h-7 !rounded-full group relative"
      title="Etiket Ekle"
    >
      <font-awesome-icon icon="plus" size="sm"></font-awesome-icon>
      <div
        class="group-focus:flex flex-col p-1 hidden absolute top-8 z-50 w-min text-left rounded-2xl gap-1"
      >
        <div
          v-for="tag in getAvailableTags()"
          :key="tag.id"
          class="rounded-full whitespace-nowrap relative overflow-hidden shadow-xl transition-colors bg-gray-100"
          :style="{
            'background-color': tag.color,
          }"
          @click="addTag(tag.id)"
        >
          <div
            class="!z-[99] w-full h-full bg-gray-800 px-3 py-1 hover:!bg-opacity-20 transition-colors"
          >
            {{ tag.name }}
          </div>
        </div>
      </div>
    </button>
  </div>
</template>

<style scoped></style>
