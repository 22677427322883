<template>
  <!-- <div class="table w-full">
    <div class="table-header-group">
      <div class="table-row">
        <div class="table-cell">İsim</div>
        <div class="table-cell">Uyumlu Marka</div>
        <div class="table-cell">Uyumlu Model</div>
      </div>
    </div>
  </div> -->
  <table>
    <thead>
      <tr>
        <th>Uyumlu Marka</th>
        <th>Uyumlu Model</th>
        <th></th>
        <th>İsim</th>
        <th>Eşleşme</th>
        <th>Düzenleme</th>
      </tr>
    </thead>
    <tbody v-if="schemas">
      <tr
        v-for="(schema, index) in schemas"
        :key="index"
        class="group"
        @click="openSchemaPage(schema.id)"
      >
        <td class="text-sm">{{ schema.compatible_brand }}</td>
        <td>{{ schema.compatible_model }}</td>
        <td>
          <CopyDimensionButton
            :schema="schema"
            class="invisible group-hover:visible focus:visible"
          />
        </td>
        <td>{{ schema.name }}</td>
        <td>
          <span
            v-if="schema.etsy_listings_count > 0"
            class="rounded bg-sky-200 px-1 mx-auto text-sky-800 flex items-center justify-center border border-sky-300 text-sm font-bold"
          >
            {{ schema.etsy_listings_count }}
          </span>
        </td>
        <td>
          <span
            class="text-sm"
            :title="dayjs(schema.updated_at).format('DD/MM/YYYY')"
          >
            {{ diffForHumans(schema.updated_at) }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import dayjs from "dayjs";
import { diffForHumans } from "@/lib/Helpers";
import axiosClient from "@/lib/AxiosClient";
import CopyDimensionButton from "../misc/CopyDimensionButton.vue";

export default {
  name: "SchemasTable",
  components: { CopyDimensionButton },
  props: {
    schemas: {
      type: Array,
      default: null,
    },
    brands: {
      type: Array,
      default: null,
    },
  },
  emits: ["schemaDeleted"],
  setup() {
    return {
      axiosClient,
      dayjs,
    };
  },
  data() {
    return {
      schemaModal: null,
    };
  },
  methods: {
    diffForHumans,
    openSchemaPage(schemaId) {
      this.$router.push({
        name: "schema",
        params: { schemaId: schemaId },
      });
    },
  },
};
</script>
<style scoped lang="scss">
table {
  & {
    @apply w-full;
  }
  thead {
    tr {
      @apply border-b text-left;
    }
    th {
      @apply py-2.5 bg-gray-800 text-white;
    }
    th:first-child {
      @apply pl-8;
    }
    th:last-child {
      @apply pr-8;
    }
  }
  tbody {
    & {
      @apply mt-2;
    }
    tr:nth-child(odd) {
      @apply bg-gray-200;
    }
    tr {
      @apply transition-colors;
    }
    tr:hover {
      @apply bg-gray-50;
    }
    td:first-child {
      @apply pl-8;
    }
    td:last-child {
      @apply pr-8;
    }
    td {
      @apply py-1.5 cursor-pointer;
    }
  }
  td,
  th {
    @apply px-2;
  }
}
</style>
