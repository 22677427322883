<template>
  <MainHeader />
  <Loading v-if="loading" :is-loading="true" :no-bg="true" />

  <div
    v-else
    class="grid transition-all duration-500 relative h-screen overflow-y-auto no-scrollbar"
    :class="{
      'grid-cols-[0.8fr_0.2fr]': mode === 'default',
      'grid-cols-[0.5fr_0.5fr] ': mode !== 'default',
    }"
  >
    <div class="transition-all duration-500">
      <!--        SAVE BUTTONS START-->
      <section class="py-2 px-4 sticky top-0 z-10 bg-gray-600">
        <div class="container flex justify-between mx-auto">
          <div class="flex gap-4">
            <button
              v-if="mode === 'default'"
              class="info"
              @click="toggleMode('linking')"
            >
              <FontAwesomeIcon icon="link" />
              Şemayı Eşleştir ({{ schema.etsy_listings.length }})
            </button>
            <button
              v-if="mode === 'default'"
              class="info"
              @click="toggleMode('history')"
            >
              <FontAwesomeIcon icon="history" />
              Sipariş Geçmişi
            </button>
            <button
              v-if="mode !== 'default'"
              class="info"
              @click="toggleMode('default')"
            >
              <FontAwesomeIcon icon="xmark" />
              Geri Dön
            </button>
          </div>
          <div class="flex gap-4">
            <ConfirmButton
              class="danger"
              text="Şemayı kalıcı olarak sileceksiniz."
              @confirmed="removeSchema(schema.id)"
            >
              <FontAwesomeIcon icon="trash" />
              Şemayı Sil
            </ConfirmButton>
            <button class="success" @click="updateSchema">
              <font-awesome-icon icon="save" />
              Kaydet
            </button>
          </div>
        </div>
      </section>
      <!--        SAVE BUTTONS END-->

      <section
        id="info"
        class="w-full flex flex-col gap-2 flex-1 transition-all relative container mx-auto p-4"
        :class="{
          'px-4': mode !== 'default',
        }"
      >
        <table>
          <tr>
            <td>Şema İsmi</td>
            <td>
              <form-kit v-model="schemaInput.name" type="text" name="name" />
            </td>
          </tr>
        </table>
        <table class="compatible">
          <tr>
            <td>Uyumlu Marka</td>
            <td>
              <BrandSelector v-model="schemaInput.compatible_brand" />
              <!-- <vSelect
                    v-model="schemaInput.compatible_brand"
                    placeholder="Uyumlu ürünün markası..."
                    class="bg-white"
                    :options="brands"
                    taggable
                  /> -->
            </td>
          </tr>
          <tr>
            <td>Uyumlu Model</td>
            <td>
              <form-kit
                v-model="schemaInput.compatible_model"
                placeholder="Uyumlu modelin ismi..."
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td>Uyumlu Ürün Ölçüsü</td>
            <td>
              <SchemaDimension
                v-if="schema.product_dimension"
                v-model:dimension="schema.product_dimension"
                :schema="schemaHelper"
                as="productDimension"
                view-mode="simple"
                class="w-full"
                @deleted="schema.product_dimension = null"
              />
              <button
                v-if="!schema.product_dimension"
                class="info"
                @click="addProductDimension"
              >
                <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                Ölçü Ekle
              </button>
            </td>
          </tr>
        </table>
        <h3>Üretim Ölçüleri</h3>
        <SchemaDimension
          v-for="(dimension, index) in schema.dimensions"
          :key="index"
          v-model:dimension="schema.dimensions[index]"
          :schema="schemaHelper"
          @deleted="schema.dimensions.splice(index, 1)"
        />
        <button class="info" @click="addDimension">
          <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
          Ölçü Ekle
        </button>
        <div v-if="mode === 'default'" class="flex flex-col">
          <h3>Görseller</h3>
          <ImageViewer
            resource="guide"
            :resource-id="schema.id"
            :images="schema.images"
            class="compact"
            @update:images="
              (imgs) => {
                schema.images = imgs;
              }
            "
          />
        </div>
        <div
          class="grid"
          :class="{
            'h-[32rem] grid-cols-1 gap-14': mode !== 'default',
            'h-[16rem] grid-cols-2 gap-4': mode === 'default',
          }"
        >
          <div class="w-full">
            <h3>Detaylar</h3>
            <FormKit
              v-model="schemaInput.description"
              type="textarea"
              placeholder="Bu alana yazılan metin, sipariş sayfasında görünür."
              help="Bu alana yazılan metin, sipariş sayfasında görünür."
              input-class="bg-red-50"
              :classes="{
                outer: 'h-full',
                wrapper: 'h-full',
                inner: 'h-full',
              }"
            />
          </div>
          <div class="w-full">
            <h3>Özel Bilgi</h3>
            <FormKit
              v-model="schemaInput.attention"
              input-class="bg-red-50 h-full"
              type="textarea"
              placeholder="Bu alan sipariş sayfasında görünmez."
              help="Bu alan sipariş sayfasında görünmez."
              :classes="{
                outer: 'h-full',
                wrapper: 'h-full',
                inner: 'h-full',
              }"
            />
          </div>
        </div>
      </section>
    </div>
    <div
      class="transition-all duration-500 bg-gray-100 w-full h-screen sticky top-0 shadow-xl"
      :class="{ 'overflow-y-auto scrollbar-thin': mode === 'default' }"
    >
      <div v-if="mode === 'default'" class="flex flex-col h-full">
        <EtsyListingCard
          v-for="listing in schema.etsy_listings"
          :key="listing.id"
          :listing="listing"
        />
        <div
          v-if="schema.etsy_listings?.length === 0"
          class="flex flex-col items-center justify-center h-full py-4 px-4"
        >
          <h2>Eşleşme Bulunamadı!</h2>
          <p>Bu şema ile eşleşen bir ürün yok.</p>
        </div>
      </div>
      <SchemaLinkTool
        v-if="mode === 'linking'"
        v-model:etsy-listings="schema.etsy_listings"
        :default-keyword="schema?.compatible_model"
        :schema-id="schema.id"
      />
      <SchemaOrderHistory
        v-if="mode === 'history'"
        :snapshots="schema.snapshots"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import Schema from "@/lib/Schema";
import "vue-search-select/dist/VueSearchSelect.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Dimensions from "@/lib/Dimensions.js";
import Loading from "@/components/misc/Loading.vue";
import SchemaChangeLog from "@/components/misc/SchemaChangeLog.vue";
import dayjs from "dayjs";
import OrderModal from "@/components/modals/OrderModal.vue";
import SchemaDimension from "@/components/misc/custom_fields/SchemaDimension.vue";
import BrandSelector from "@/components/form/BrandSelector.vue";
import ImageViewer from "@/components/misc/ImageViewer.vue";
import SchemaOrderHistory from "@/pages/schema/SchemaOrderHistory.vue";

import axios from "@/lib/AxiosClient.js";
import MainHeader from "@/components/MainHeader.vue";
import { useRoute, useRouter } from "vue-router";
import ConfirmButton from "@/components/form/ConfirmButton.vue";
import { useModal } from "vue-final-modal";
import SchemaLinkTool from "./SchemaLinkTool.vue";
import EtsyListingCard from "../etsy/EtsyListingCard.vue";

const mode = ref("default");
const schemaHelper = ref(null);
const schema = ref(null);
const dimensions = ref(null);
const schemaInput = ref(null);
const loading = ref(true);
const route = useRoute();
const router = useRouter();

function toggleMode(newMode) {
  if (mode.value === "default") mode.value = newMode;
  else mode.value = "default";
}

const getSchema = async () => {
  loading.value = true;
  const response = await axios.get(`guide/${route.params.schemaId}`);
  schema.value = response.data;
  schemaHelper.value = new Schema(response.data);
  dimensions.value = new Dimensions(schema.value);
  schemaInput.value = structuredClone(response.data);
  loading.value = false;
};

onMounted(() => {
  getSchema();
});

const removeSchema = (schemaId) => {
  axios.delete(`guide/${schemaId}`).then(() => {
    router.push({
      name: "schemas",
    });
  });
};

const addDimension = () => {
  schema.value.dimensions.push({
    width: [0],
    height: [0],
    depth: [0],
    name: null,
    guide_id: schema.value.id,
  });
};

const addProductDimension = () => {
  schema.value.product_dimension = {
    width: [0],
    height: [0],
    depth: [0],
    name: null,
    guide_id: schema.value.id,
    product_dimension: true,
  };
};

const isSnapshotChanged = (path, index) => {
  let before_value = null;
  if (index !== 0) {
    before_value = path
      .split(".")
      .reduce((o, i) => o[i], schema.value.snapshots[index]);
  } else if (index === schema.value.snapshots.length) {
    return false;
  }
  const now_value = path
    .split(".")
    .reduce((o, i) => o[i], schema.value.snapshots[index]);
  return before_value !== now_value;
};

const openOrderModal = (orderId) => {
  useModal({
    component: OrderModal,
    attrs: {
      orderId,
    },
  }).open();
};

const getAllProducts = () => {
  axios.get(`guide/${schema.value.id}/products`).then((r) => {
    schema.value.products = r.data;
  });
};

const getAllLogs = () => {
  axios.get(`guide/${schema.value.id}/logs`).then((r) => {
    schema.value.change_logs = r.data;
  });
};

const updateSchema = () => {
  axios
    .put(`guide/${schema.value.id}`, {
      ...schemaInput.value,
    })
    .then((r) => {
      schema.value = r.data.data;
    });
};
</script>
<style lang="scss" scoped>
.formkit-help {
  @apply text-sm;
}
.formkit-inner,
.formkit-input {
  @apply max-w-full m-0 #{!important};
}
.formkit-outer {
  @apply mb-0 #{!important};
}
h3 {
  @apply text-lg font-bold mb-1;
}
.left table {
  & {
    @apply w-full;
  }
  tr th {
    @apply font-semibold bg-gray-200 border text-xs #{!important};
  }
  tr:nth-child(even) td {
    @apply bg-gray-50;
  }
  tr {
    td {
      @apply border py-2 px-2 text-center;
    }
  }
}
section table {
  tr {
    td:first-child {
      @apply w-1/3 text-right bg-white relative;
    }
    td:first-child span.date {
      @apply absolute right-2 top-2 bg-gray-600 rounded-full py-0.5 px-2 text-sm text-gray-100 text-center;
    }
    td:first-child span.orderID {
      @apply absolute left-2 top-2 bg-sky-600 rounded-full py-0.5 px-2 text-sm text-sky-100 text-center font-bold;
    }
    td {
      @apply border py-2 px-4;
    }
  }
}
.compatible {
  tr {
    td {
      @apply align-middle h-4;
    }
    //td:nth-child(2){
    //  @apply font-bold ;
    //}
  }
}
</style>
