<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ConfirmButton",
  props: {
    title: {
      type: String,
      default: "Emin misiniz?",
    },
    text: {
      type: String,
      default: null,
    },
    confirmText: {
      type: String,
      default: "Evet",
    },
    denyText: {
      type: String,
      default: "Hayır",
    },
  },
  emits: ["confirmed", "denied"],
  methods: {
    confirmPopup() {
      this.$swal({
        title: this.title,
        text: this.text,
        showConfirmButton: true,
        showDenyButton: true,
        denyButtonText: this.denyText,
        confirmButtonText: this.confirmText,
      }).then((r) => {
        r.isConfirmed ? this.$emit("confirmed") : this.$emit("denied");
      });
    },
  },
});
</script>

<template>
  <button @click="confirmPopup"><slot name="default"></slot></button>
</template>

<style scoped></style>
