<template>
  <div>
    <main>
      <ModalsContainer />
      <router-view v-slot="{ Component }">
        <keep-alive include="Receipts">
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </main>
  </div>
</template>

<script>
import { ModalsContainer } from "vue-final-modal";
import { useAuthStore } from "./stores/auth";
import { useSettingsStore } from "@/stores/settings";
import { onBeforeMount, watch } from "vue";
import { useScanHandler } from "./lib/BarcodeScanHandler.js";
export default {
  name: "App",
  components: { ModalsContainer },
  setup() {
    const auth = useAuthStore();
    const settings = useSettingsStore();
    useScanHandler();
    const boot = () => {
      settings.fetchUsers();
      settings.fetchTags();
      settings.fetchGlobalSettings();
      auth.fetchUser();
      window.Echo.private(`User.${auth.getUser.id}`).listen(
        "UserUpdatedEvent",
        (e) => {
          console.log("User Updated");
          this.auth.updateUser(e.user);
        }
      );
    };

    onBeforeMount(() => {
      if (auth.isAuthenticated) {
        boot();
      } else {
        const unwatch = watch(
          () => auth.isAuthenticated,
          (isLoggedIn) => {
            if (isLoggedIn) {
              boot();
              unwatch();
            }
          }
        );
      }
    });
  },
};
</script>

<style scoped></style>
