import Dimensions from "@/lib/Dimensions";
import { useSettingsStore } from "@/stores/settings";

class Schema {
  constructor(schema) {
    this.schema = schema;
    this.dimensions = this.schema.dimensions.map((d) => new Dimensions(d));
  }

  updateDimension(newDimension) {
    if (!newDimension.hasOwnProperty("id")) return;
    const index = this.dimensions.findIndex((d) => d.id === newDimension.id);
    // console.log("Dimension updated!");
    // console.log(this.dimensions[index], new Dimensions(newDimension));
    this.dimensions[index] = new Dimensions(newDimension);
  }

  updateProductDimension(newDimension) {
    if (!newDimension.hasOwnProperty("id")) return;
    this.schema.product_dimension = new Dimensions(newDimension);
    this.schema.compatible_product_dimension_id = newDimension.id;
    // console.log("Dimension updated!");
    // console.log(this.dimensions[index], new Dimensions(newDimension));
  }

  copyModelText(dimension_index) {
    const globalSettings = useSettingsStore();
    let template = globalSettings.getGlobalSetting(
      "schema_dimension_copy_template"
    );
    template = template.replace(
      "{{dimensions}}",
      this.dimensions[dimension_index].getDimensionsText()
    );
    template = template.replace(
      "{{compatible_model}}",
      this.schema.compatible_model
    );
    navigator.clipboard.writeText(template);
  }
}

export default Schema;
