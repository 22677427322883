<template>
  <div class="p-4 bg-white border rounded-md flex flex-col">
    <h3 v-if="title" class="text-center mb-4">{{ title }}</h3>
    <Line id="my-chart-id" :options="chartOptions" :data="data" />
  </div>
</template>

<script>
import colors from "tailwindcss/colors";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export default {
  name: "LineChart",
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Line },
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      chartOptions: {
        borderColor: colors.sky[600],
        pointBackgroundColor: colors.sky[600],
        borderWidth: 5,
        lineWidth: 5,
        responsive: true,
        tension: 0.2,
      },
    };
  },
};
</script>
