<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import DropdownButton from "@/components/misc/DropdownButton.vue";
import dayjs from "dayjs";
import ProgressBar from "@/components/misc/ProgressBar.vue";
import { computed, onMounted } from "vue";
import axiosClient from "@/lib/AxiosClient";
import { useRoute } from "vue-router";
import { diffForHumans } from "@/lib/Helpers";
import { onBeforeUnmount } from "vue";
import { onBeforeMount } from "vue";
import { ref } from "vue";

const props = defineProps({
  shop: {
    type: Object,
    default: null,
  },
  shopId: {
    type: [Number, String],
    default: null,
  },
  showDescription: {
    type: Boolean,
    default: false,
  },
  noDropdown: {
    type: Boolean,
    default: false,
  },
});
const route = useRoute();

const emit = defineEmits(["update:shop"]);
const jobDescriptions = {
  cache_listings: "Etsy ürünleri listesi yenileniyor.",
  import_listings: "Etsy ürünleri sisteme kopyalanıyor.",
  bulk_update_listing_images: "Seçilen görsel ürünlere yükleniyor.",
};
function timestampFormat(ts) {
  return dayjs(ts * 1000).format("DD/MM/YYYY");
}

function updateMeta(id) {
  axiosClient.get(`etsy/shop/${id}/sync`);
}
function isRunning(job) {
  return job && !job.finishedAt && !job.cancelledAt;
}

function stopShopJobs(shop) {
  axiosClient.get(`etsy/shop/${shopData.value.id}/stop-jobs`).then((r) => {
    shopData.value.active_batches = r.data.active_batches ?? [];
    emit("update:shop", shopData.value);
  });
}

function startJob(jobName) {
  axiosClient
    .post(`etsy/shop/${shopData.value.id}/start-job`, {
      job_name: jobName,
    })
    .then((r) => {
      shopData.value.active_batches = r.data.bacthes;
      emit("update:shop", shopData.value);
    });
}

function updateProgress(data) {
  if (data.job_name) {
    const batch_index = shopData.value.active_batches.findIndex(
      (b) => b.id === data.batch.id
    );
    shopData.value.active_batches[batch_index] = data.batch;
    emit("update:shop", shopData.value);
  }
}
const isOld = computed(() => {
  return shopData.value
    ? Math.abs(dayjs(shopData.value.cached_at).diff(dayjs(), "days")) > 3
    : false;
});
const timeDiff = computed(() => {
  return shopData.value ? dayjs(shopData.value.cached_at).fromNow() : 0;
});

onBeforeMount(() => {
  if (props.shop) shopData.value = props.shop;
  else if (props.shopId) {
    axiosClient.get(`/etsy/shop/${props.shopId}`).then((r) => {
      shopData.value = r.data;
      window.Echo.private(`EtsyShop.${shopData.value.id}`).listen(
        "ShopJobUpdated",
        updateProgress
      );
    });
  }
});
const shopData = ref(null);
onMounted(() => {
  if (props.shop) {
    window.Echo.private(`EtsyShop.${shopData.value.id}`).listen(
      "ShopJobUpdated",
      updateProgress
    );
  }
});

onBeforeUnmount(() => {
  window.Echo.leave(`EtsyShop.${shopData.value.id}`);
});
</script>

<template>
  <div v-if="shopData" class="etsy__shop">
    <div class="shop__profile">
      <img :src="shopData.meta.avatar" />
    </div>
    <div class="shop__info w-full">
      <div class="flex flex-col">
        <div class="flex justify-between">
          <a
            :href="`https://etsy.com/shop/${shopData.shop_name}`"
            target="_blank"
          >
            <h3>{{ shopData.shop_name }}</h3>
          </a>
          <dropdown-button v-if="!noDropdown">
            <template #dropdown>
              <li>
                <button @click="startJob('cache_listings')">
                  <font-awesome-icon icon="fa-solid fa-download" size="sm" />
                  Önbelleği Güncelle
                </button>
              </li>
              <li>
                <button @click="updateMeta(shopData.id)">
                  <font-awesome-icon
                    icon="fa-solid fa-code-compare"
                    size="sm"
                  />
                  Dükkan Bilgilerini Güncelle
                </button>
              </li>
              <li>
                <button @click="stopShopJobs(shop)">
                  <font-awesome-icon
                    icon="fa-regular fa-circle-xmark"
                    size="sm"
                  />
                  İşlemleri Durdur
                </button>
              </li>
            </template>
          </dropdown-button>
        </div>
        <slot name="description" v-bind="{ isOld, timeDiff }">
          <p>{{ shopData.meta.title }}</p>
        </slot>
      </div>
      <div class="flex gap-2">
        <router-link
          v-if="route.name !== 'etsy_shop_orders'"
          :to="{
            name: 'etsy_shop_orders',
            params: { etsyShopId: shopData.id },
          }"
          class="btn gray sm"
        >
          <font-awesome-icon icon="receipt" />
          Siparişler
        </router-link>
        <router-link
          v-if="route.name !== 'etsy_shop_images'"
          :to="{
            name: 'etsy_shop_images',
            params: { etsyShopId: shopData.id },
          }"
          class="btn gray sm"
        >
          <font-awesome-icon icon="image" />
          Görseller
        </router-link>
        <router-link
          v-if="route.name !== 'etsy_shop_listings'"
          :to="{
            name: 'etsy_shop_listings',
            params: { etsyShopId: shopData.id },
          }"
          class="btn gray sm"
        >
          <font-awesome-icon icon="list" />
          Listelemeler
        </router-link>
      </div>
      <ProgressBar
        v-for="(data, index) in shopData.active_batches"
        :key="index"
        :progress="data.progress"
        :name="jobDescriptions[data.name] ?? data.name"
      />
      <ul v-if="shopData.meta" class="flex text-xs gap-2 mt-2 shop__meta">
        <!--            <li>{{ shopData.meta.created_at }}</li>-->
        <li title="Ürün Sayısı">
          <FontAwesomeIcon icon="fa-cart-shopping"></FontAwesomeIcon
          >{{ shopData.listings_count }}
        </li>
        <li>
          <font-awesome-icon icon="fa-regular fa-clock" />
          {{ timestampFormat(shopData.meta.updated_at) }}
        </li>
        <li>
          <font-awesome-icon icon="fa-regular fa-calendar" />
          {{ timestampFormat(shopData.meta.created_at) }}
        </li>
      </ul>
    </div>
    <slot name="right" v-bind="{ startJob, stopShopJobs }"> </slot>
  </div>
</template>

<style lang="scss" scoped>
.etsy__shop {
  .shop__info {
    @apply flex flex-col gap-2;
  }
  .shop__profile {
    img {
      @apply w-36 aspect-square object-cover object-center rounded-md m-1 border #{!important};
    }
  }
  .shop__meta li {
    @apply flex gap-1 items-center;
  }

  @apply flex items-center p-3 bg-white rounded-md gap-4 border-1;
}
</style>
