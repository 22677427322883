<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "EtsyReceiptPlaceHolder",
  data(){
    return {
      random: Math.random()*5
    }
  }
})
</script>

<template>
  <div class="w-full rounded-md bg-white shadow-lg overflow-hidden mt-4">
    <div class="flex flex-col">
      <div class="w-full flex bg-gray-400 py-2 px-4 justify-between">
        <div class="flex gap-2 items-center">
          <div class="w-28 h-6 bg-gray-300 rounded-md animate-pulse" />
          <div class="w-36 h-4 bg-gray-300 rounded-md animate-pulse" />
        </div>
        <div class="w-32 p-4 bg-gray-200 rounded-md  animate-pulse" />
      </div>
      <div class="flex p-4 justify-between">
        <div class="flex flex-col gap-4">
          <div class="bg-gray-300 h-4 w-20 rounded-md animate-pulse" />
          <div class="bg-gray-200 h-4 w-[12rem] rounded-md animate-pulse" />
          <div
            :class="{'h-[2rem]': random > 2, 'h-[3rem]': random < 3}"
            class="bg-gray-400 w-[20rem] rounded-md animate-pulse"
          />
          <div class="bg-gray-400 h-4 w-[20rem] rounded-md animate-pulse" />
        </div>
        <div class="flex flex-col gap-4">
          <div class="bg-gray-400 h-4 w-[10rem] rounded-md animate-pulse" />
          <div
            :class="{'h-[4rem]': random > 2, 'h-[5rem]': random < 3}"
            class="bg-gray-200 h-[4rem] w-[9rem] rounded-md animate-pulse"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>