import dayjs from "dayjs";
import axios from "axios";
import relativeTime from "dayjs/plugin/relativeTime.js";
import _ from "lodash";

dayjs.extend(relativeTime);
dayjs.locale("tr");

export function diffForHumans(date) {
  if (!date) {
    return null;
  }
  return dayjs(date).fromNow();
}

export function translate(text) {
  return axios.get("https://translateapi.senamon.com/translate", {
    params: {
      tr: text,
      to: "TR",
      from: "EN",
    },
  });
}
export function currencySymbol(currency) {
  const symbols = {
    USD: "$",
    TRY: "₺",
    TRL: "₺",
    EUR: "€",
    CAD: "CA$",
    GPB: "£",
  };
  return symbols[currency] ?? currency;
}

export function priceFormat(price) {
  return parseFloat(price).toFixed(2);
}

export function calculateTaxPercentage(price, tax) {
  return Math.round((tax / price) * 100);
}
export function generateUniqueColor(input) {
  // Check if the input is a letter or digit in the Latin alphabet
  if (!/^[a-zA-Z0-9]$/.test(input)) {
    throw new Error("Invalid input. Only letters and digits are allowed.");
  }

  // Create a seed for the random number generator based on the input character
  const seed = input.charCodeAt(0);

  // Use the seed to generate random RGB values
  const red = (seed * 31) % 256;
  const green = (seed * 53) % 256;
  const blue = (seed * 71) % 256;

  // Convert RGB values to a CSS color string
  const color = `rgb(${red},${green},${blue})`;

  return color;
}

/**
 * Deep diff between two object-likes
 * @param  {Object} fromObject the original object
 * @param  {Object} toObject   the updated object
 * @return {Object}            a new object which represents the diff
 */
export function deepDiff(obj1, obj2) {
  const isObject = (obj) => typeof obj === "object" && obj !== null;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  const addedKeys = keys2.filter((key) => !keys1.includes(key));
  const removedKeys = keys1.filter((key) => !keys2.includes(key));

  const updatedKeys = keys1.filter((key) => {
    if (isObject(obj1[key]) && isObject(obj2[key])) {
      return deepDiff(obj1[key], obj2[key]).length > 0;
    }
    return obj1[key] !== obj2[key];
  });

  const diff = [];

  addedKeys.forEach((key) => {
    diff.push({ type: "added", key, value: obj2[key] });
  });

  removedKeys.forEach((key) => {
    diff.push({ type: "removed", key, value: obj1[key] });
  });

  updatedKeys.forEach((key) => {
    if (isObject(obj1[key]) && isObject(obj2[key])) {
      diff.push({
        type: "updated",
        key,
        value: deepDiff(obj1[key], obj2[key]),
      });
    } else {
      diff.push({
        type: "updated",
        key,
        oldValue: obj1[key],
        newValue: obj2[key],
      });
    }
  });

  return diff;
}

export function clean(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    // Remove null values from array elements
    return obj.map(clean);
  }

  // Remove null values from object properties
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const cleanedValue = clean(value);

    if (cleanedValue !== null) {
      acc[key] = cleanedValue;
    }

    return acc;
  }, {});
}

export function safeJsonParse(str) {
  try {
    return JSON.parse(str);
  } catch {
    return false;
  }
}

export function getEtsyListingID(url) {
  const match = url.match(/(\/listing\/|listing-editor\/edit\/)([\d]+)/);
  return match?.length === 3 ? match[2] : false;
}
