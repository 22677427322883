<template>
  <ModelSelect
    v-model="schemaToBeAdded"
    :options="schemas"
    placeholder="Yeni bir şema eklemek için arayın..."
    @update:model-value="addSchema"
    @searchchange="(e) => searchDebounce(e, searchSchemas)"
  />
</template>

<script setup>
import axiosClient from "@/lib/AxiosClient";
import { ref, defineEmits } from "vue";
import { ModelSelect } from "vue-search-select";

const timeout = ref(null);
const props = defineProps({
  guides: {
    type: Array,
    required: false,
    default: () => [],
  },
});

const schemas = ref([]);
const schemaToBeAdded = ref(null);
const emit = defineEmits(["addSchema"]);

function addSchema(value) {
  emit("addSchema", value);
  schemaToBeAdded.value = null;
}

function searchDebounce(keyword, cb) {
  clearTimeout(timeout.value);
  timeout.value = setTimeout(() => {
    cb(keyword);
  }, 800);
}

function searchSchemas(keyword) {
  if (keyword === "") return;
  axiosClient
    .get(`/guide`, {
      params: {
        keyword,
        limit: 5,
      },
    })
    .then((r) => {
      schemaToBeAdded.value = null;
      schemas.value = r.data.data
        .filter((g) => {
          return !props.guides.find((f) => f.id === g.id);
        })
        .map((g) => {
          return {
            text: g.name
              ? g.name
              : `${g.compatible_brand} • ${g.compatible_model}`,
            value: g.id,
          };
        });
    });
}
</script>
