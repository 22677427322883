<script>
import { defineComponent } from "vue";
import { decode } from "he";
import { useModal } from "vue-final-modal";
import OrderModal from "@/components/modals/OrderModal.vue";
import { currencySymbol } from "../lib/Helpers.js";
import OrderStatus from "@/components/misc/OrderStatus.vue";
import InlineTags from "@/components/misc/InlineTags.vue";
import UserBadge from "@/components/misc/UserBadge.vue";
import { useSettingsStore } from "@/stores/settings";

export default defineComponent({
  name: "OrderCardItem",
  components: { UserBadge, InlineTags, OrderStatus },
  props: ["order", "receipt", "showPrice", "onModalOpen", "notes", "clickable"],
  setup() {
    const settings = useSettingsStore();
    return {
      decode,
      settings,
    };
  },
  data() {
    return {
      orderModalInstance: null,
    };
  },
  methods: {
    currencySymbol,
    openOrderModal() {
      if (!this.onModalOpen) {
        const _this = this;
        useModal({
          component: OrderModal,
          attrs: {
            orderId: this.order.id,
          },
        }).open();
      } else {
        this.onModalOpen(this.order.id);
      }
    },
    listingThumbnail(src) {
      if (src.search("il_fullxfull"))
        return src.replace("il_fullxfull", "il_500xN");
      return;
    },
    thumbnail(image) {
      if (image) return `${this.$assetUrl}/products/${image}`;
      else return "/images/placeholder.png";
    },
    showTags(tag) {
      const invalid = ["finished", "cancelled"];
      return !invalid.find((t) => t === tag);
    },
  },
});
</script>

<template>
  <div
    class="transition-colors cursor-pointer relative"
    @click="clickable ? openOrderModal : null"
  >
    <div class="absolute top-1 left-1 flex gap-1 text-xs flex-wrap">
      <OrderStatus
        v-if="settings.getSetting('gridOrders.showStatus')"
        :status="order.status"
      />
      <InlineTags
        v-if="settings.getSetting('gridOrders.showTags')"
        :tags="order.tags"
      />
      <div
        v-if="settings.getSetting('gridOrders.showUsers')"
        class="flex gap-1"
      >
        <UserBadge
          v-for="user in order.users"
          :key="user.id"
          class="pl-0.5 pr-1.5"
          :user="user"
        />
      </div>
    </div>
    <img
      v-if="order.images && order.images.length > 0"
      :src="listingThumbnail(order.images[0].src)"
      alt=""
      class="w-full aspect-[3/2] object-cover"
    />
  </div>
</template>

<style scoped></style>
