<template>
  <table v-if="dimension && localDimension" class="relative group">
    <div
      class="absolute gap-2 right flex left-2 top-2"
      :class="{ 'hidden group-hover:flex': mode === 'view' }"
    >
      <CopyDimensionButton v-if="schema && mode === 'view'" :schema="schema" />
      <button
        v-if="$props.overwriteable && schema && mode === 'view'"
        class="warning sm !h-7"
        @click="mode = 'overwrite'"
      >
        <font-awesome-icon icon="fas fa-pen-to-square"> </font-awesome-icon>
        Siparişe Özel Düzenle
      </button>
      <button
        v-if="editable && mode === 'view'"
        class="warning sm !h-7"
        @click="mode = 'edit'"
      >
        <font-awesome-icon icon="fas fa-pen-to-square"> </font-awesome-icon>
        Düzenle
      </button>
    </div>
    <tr v-if="mode === 'edit' && as !== 'productDimension'">
      <td>Ölçü Adı</td>
      <td>
        <FormKit
          v-model="localDimension.name"
          type="text"
          placeholder="Ölçü için bir isim girin"
        ></FormKit>
      </td>
    </tr>
    <tr v-if="mode === 'overwrite'">
      <td colspan="2">
        <div class="flex justify-center items-center gap-4">
          <FontAwesomeIcon icon="warning" class="text-[2em] text-yellow-500" />
          <div class="flex flex-col">
            <h3>Siparişe Özel Düzenleme Modu</h3>
            <p>Yapılan değişiklikler yalnızca bu siparişte görünecektir.</p>
          </div>
        </div>
      </td>
    </tr>
    <tr v-if="mode === 'view' && localDimension.overwrite">
      <td colspan="2">
        <div class="text-center font-bold text-red-500">
          <FontAwesomeIcon icon="warning" />
          Ölçüler Bu Siparişe Özel Olarak Düzenlendi
        </div>
      </td>
    </tr>
    <tr v-if="mode === 'view' && localDimension.name">
      <td colspan="2" class="!text-center !bg-gray-600 text-white">
        {{ localDimension.name }}
      </td>
    </tr>
    <template v-for="(axis, index) in axes" :key="index">
      <tr v-if="mode !== 'view' || localDimension[axis.key].length > 0">
        <td>{{ axis.label }}</td>
        <td>
          <div class="flex items-center justify-between">
            <div
              v-if="mode !== 'view'"
              class="w-fit whitespace-nowrap flex items-center"
            >
              <SchemaDimensionInput
                v-for="(dim, i) in localDimension[axis.key]"
                :key="i"
                v-model="localDimension[axis.key][i]"
                type="number"
                :end-field="isLastValue(axis.key, i) ? 'CM' : '/'"
                :flat-right="!isLastValue(axis.key, i)"
                :flat-left="i !== 0"
                class="w-28 text-center"
              />
              <button
                class="ml-2 !rounded-full info !w-5 !h-5 !p-0 flex center text-xs"
                @click="createValue(axis.key)"
              >
                <font-awesome-icon icon="fas fa-plus"> </font-awesome-icon>
              </button>
              <button
                v-if="localDimension[axis.key].length > 0"
                class="ml-2 !rounded-full danger !w-5 !h-5 !p-0 flex center text-xs"
                @click="localDimension[axis.key].pop()"
              >
                <font-awesome-icon icon="fas fa-minus"> </font-awesome-icon>
              </button>
            </div>

            <div v-else class="flex gap-2">
              <template v-for="(dim, i) in localDimension[axis.key]" :key="i">
                <span class="font-bold">
                  {{ formatDimension(dim) }}
                </span>
                <span v-if="!isLastValue(axis.key, i)" class="mx-2">/</span>
              </template>
              <span class="unit">CM</span>
            </div>
            <div class="flex gap-2">
              <template v-for="(dim, i) in localDimension[axis.key]" :key="i">
                <span class="font-bold">
                  {{ Number(formatDimension(dim) * 0.393700787).toFixed(2) }}
                </span>
                <span v-if="!isLastValue(axis.key, i)">/</span>
              </template>
              <span>INCH</span>
            </div>
          </div>
        </td>
      </tr>
    </template>
    <!-- When Editing Schema-->
    <tr v-if="mode !== 'view' && dimension.guide_id">
      <td />
      <td>
        <div class="flex gap-2">
          <button class="default sm" @click="stopEditing">
            <font-awesome-icon icon="fas fa-x"> </font-awesome-icon>
            İptal
          </button>
          <button
            v-if="mode === 'overwrite'"
            class="success sm"
            @click="overwriteDimension"
          >
            <font-awesome-icon icon="fas fa-floppy-disk"> </font-awesome-icon>
            Üzerine Yaz
          </button>
          <button
            v-if="mode === 'edit'"
            class="success sm"
            @click="saveDimension"
          >
            <font-awesome-icon icon="fas fa-floppy-disk"> </font-awesome-icon>
            Kaydet
          </button>
          <button
            v-if="dimension.id && mode === 'edit'"
            class="danger sm"
            @click="deleteDimension"
          >
            <font-awesome-icon icon="fas fa-trash"> </font-awesome-icon>
            Sil
          </button>
          <button
            v-if="dimension.id && mode === 'overwrite'"
            class="danger sm"
            @click="deleteOverwrite"
          >
            <font-awesome-icon icon="fas fa-trash"> </font-awesome-icon>
            Orijinal Ölçüleri Kullan
          </button>
        </div>
      </td>
    </tr>
    <!--    When Creating Schema-->
    <tr v-if="mode !== 'view' && !dimension.guide_id">
      <td />
      <td>
        <div class="flex gap-2">
          <button
            class="success sm"
            @click="
              () => {
                mode = 'view';
                $emit('update:dimension', localDimension);
              }
            "
          >
            <font-awesome-icon icon="fas fa-floppy-disk"> </font-awesome-icon>
            Kaydet
          </button>
          <button class="danger sm" @click="stopEditing">
            <font-awesome-icon icon="fas fa-x"> </font-awesome-icon>
            Sil
          </button>
        </div>
      </td>
    </tr>
  </table>
</template>
<script>
import SchemaDimensionInput from "@/components/form/SchemaDimensionInput.vue";
import Dimensions from "@/lib/Dimensions";
import CopyDimensionButton from "../CopyDimensionButton.vue";
import Schema from "@/lib/Schema";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { cloneDeep } from "lodash";
export default {
  name: "SchemaDimension",
  components: { SchemaDimensionInput, CopyDimensionButton },
  props: {
    dimension: {
      type: Object,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    copyable: {
      type: Boolean,
      default: true,
    },
    overwriteable: {
      type: Boolean,
      default: false,
    },
    as: {
      type: String,
      default: "schemaDimension",
    },
    schema: {
      type: Schema,
      default: null,
    },
  },
  emits: ["deleted", "copied", "update:dimension"],
  data() {
    return {
      axes: [
        { label: "Genişlik", key: "width" },
        { label: "Yükseklik", key: "height" },
        { label: "Derinlik", key: "depth" },
      ],
      dimensionHelper: null,
      localDimension: null,
      mode: "view",
    };
  },
  mounted() {
    this.resetLocalDimension();
    //if does not have an id mount it in edit mode
    if (this.$props.editable && !this.localDimension?.id) {
      this.mode = "edit";
    }
    this.dimensionHelper = new Dimensions(this.localDimension);
  },
  methods: {
    resetLocalDimension() {
      const parsed = JSON.parse(JSON.stringify(this.$props.dimension));
      this.updateLocalDimension(parsed);
    },
    stopEditing() {
      if (this.$props.dimension?.id) {
        this.resetLocalDimension();
        this.mode = "view";
      } else {
        this.$emit("deleted");
      }
    },
    // inEditMode() {
    //   return this.editable ? this.editMode : false;
    // },
    updateLocalDimension(newValue) {
      const axKeys = this.axes.map((axis) => axis.key);
      const overwrite = {};
      if (newValue.overwrite) {
        axKeys.forEach((key) => {
          overwrite[key] = newValue.overwrite[key];
        });
      }
      this.localDimension = cloneDeep(Object.assign(newValue, overwrite));
      this.$emit("update:dimension", newValue);
    },
    saveDimension() {
      // checking if dimension has an id
      if (this.localDimension?.id) {
        // check if all fields are empty
        // if has an id make put request
        this.axios
          .put(`dimension/${this.localDimension.id}`, this.localDimension)
          .then((r) => {
            this.updateLocalDimension(r.data.data);
            this.mode = "view";
          });
      } else {
        // if does not have an id make post request
        this.axios.post(`dimension`, this.localDimension).then((r) => {
          this.updateLocalDimension(r.data.data);
          this.mode = "view";
        });
      }
    },
    overwriteDimension() {
      this.axios
        .post(
          `dimension/${this.localDimension.id}/overwrite/${this.$route.params.orderId}`,
          this.localDimension
        )
        .then((r) => {
          this.updateLocalDimension(r.data.data);
          this.schema.updateDimension(r.data.data);
          this.mode = "view";
        });
    },
    deleteOverwrite() {
      this.axios
        .delete(
          `dimension/${this.localDimension.id}/overwrite/${this.$route.params.orderId}`
        )
        .then((r) => {
          this.updateLocalDimension(r.data.data);
          this.schema.updateDimension(r.data.data);
          this.mode = "view";
        });
    },
    deleteDimension() {
      // checking if dimension has an id
      // if has an id make remove request
      // if does not have an id remove locally
      this.$swal({
        title: "Bu ölçü silinsin mi?",
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: "Evet",
        denyButtonText: "Hayır",
      }).then((r) => {
        if (r.isConfirmed) {
          // make remove request
          this.axios.delete("dimension/" + this.dimension.id).then(() => {
            this.mode = "view";
            this.$emit("deleted");
          });
        }
      });
    },
    isLastValue(key, index) {
      return this.localDimension[key].length - 1 === index;
    },
    isFirstValue(key, index) {
      return this.localDimension[key].length - 1 === index;
    },
    formatDimension(d) {
      return d / 10;
    },
    createValue(key) {
      this.localDimension[key].push(0);
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  tr {
    td:first-child {
      @apply w-1/3 text-right bg-gray-50;
    }
    td {
      @apply border py-2 px-4;
    }
  }
}
</style>
