<template>
  <div class="flex bg-white w-full rounded overflow-hidden">
    <div class="flex flex-col w-full text-gray-800">
      <router-link
        :to="{ name: 'receipt', params: { receiptId: receipt.id } }"
        class="flex w-full justify-between items-center px-4 py-2 border-b bg-gray-200 hover:bg-gray-300 transition-colors cursor-pointer"
      >
        <div class="flex flex-row gap-8 text-gray-800 items-center">
          <UrgentNotification v-if="receipt.is_urgent" />
          <div class="h-full text-xl flex items-center monte">
            #
            <h1 class="text-2xl font-bold">{{ $props.receipt.id }}</h1>
          </div>
          <!--        CUSTOMER -->
          <div v-if="receipt.customer" class="flex items-center text-sky-600">
            <div
              class="rounded-full w-7 h-7 border-2 flex justify-center items-center font-bold text-[0.6rem] bg-sky-100 border-sky-600"
            >
              {{ customerLetters }}
            </div>
            <div class="ml-2 font-bold">
              {{ receipt.customer.name }}
            </div>
          </div>
          <!--        CUSTOMER END-->
          <div
            v-if="!receipt.customer && receipt.shipment_customer_name"
            class="flex items-center font-bold"
          >
            {{ receipt.shipment_customer_name }}
          </div>
          <div
            v-if="$auth.can('view order.shipping_date')"
            class="h-full inline-flex flex-wrap gap-2 justify-center items-center"
          >
            {{ shippingTime }}
          </div>
        </div>
      </router-link>

      <div
        class="grid grid-cols-1 gap-4"
        :class="{
          '!grid-cols-2 gap-0':
            $props.receipt.orders.length > 1 &&
            settings.getSetting('listOrders.splitRow'),
        }"
      >
        <OrderListItem
          v-for="order in $props.receipt.orders"
          :key="order.id"
          :order="order"
          :on-modal-open="onModalOpen"
          :small="
            settings.getSetting('listOrders.splitRow') &&
            $props.receipt.orders.length > 1
          "
        />
      </div>
      <div
        v-if="
          receipt.orders_count && receipt.orders.length !== receipt.orders_count
        "
        class="w-full text-center text-sm"
      >
        <hr />
        <div class="py-2">
          Diğer {{ receipt.orders_count - receipt.orders.length }} ürün
          gösterilmiyor.
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";

import OrderListItem from "@/components/OrderListItem.vue";
import UrgentNotification from "@/components/misc/UrgentNotification.vue";
import { useSettingsStore } from "@/stores/settings";

const props = defineProps({
  receipt: {
    type: Object,
    required: true,
  },
  onModalOpen: {
    type: Function,
    default: undefined,
  },
});

const settings = useSettingsStore();

const shippingTime = computed(() => {
  const now = new Date();
  const shipping_date = new Date(props.receipt.shipping_date);
  const daysLeft = Math.ceil((shipping_date - now) / (1000 * 3600 * 24));
  let text;
  if (daysLeft < 0) {
    text = `${Math.abs(daysLeft)} gün önce`;
  } else if (daysLeft === 0) {
    text = "Bugün";
  } else {
    text = `${daysLeft} gün sonra`;
  }
  return text;
});

const customerLetters = computed(() => {
  return props.receipt.customer.name
    .split(" ")
    .map((w) => w.charAt(0).toUpperCase())
    .join("");
});
</script>
