<script>
import { defineComponent, nextTick } from "vue";
import MainHeader from "@/components/MainHeader.vue";
import OrderListItem from "@/components/OrderListItem.vue";
import DropdownButton from "@/components/misc/DropdownButton.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  calculateTaxPercentage,
  currencySymbol,
  priceFormat,
} from "@/lib/Helpers.js";
import UrgentNotification from "@/components/misc/UrgentNotification.vue";
import { useAuthStore } from "@/stores/auth";
import { useOrderModal } from "@/lib/OrderModalController";
import AddOrderModalVue from "@/components/modals/AddOrderModal.vue";
import { useModal } from "vue-final-modal";

export default defineComponent({
  name: "ReceiptPage",
  components: {
    UrgentNotification,
    FontAwesomeIcon,
    DropdownButton,
    OrderListItem,
    MainHeader,
  },
  // beforeRouteEnter(to, from, next) {
  //   // console.log("enter");
  //   next();
  // },
  beforeRouteUpdate(to, from, next) {
    // console.log("routeUpdate");
    this.onParamChange(to, from);
    next();
  },
  setup() {
    const auth = useAuthStore();
    const regNames = new Intl.DisplayNames(["en"], {
      type: "region",
    });
    const orderModal = useOrderModal();
    return {
      orderModal,
      regNames,
      auth,
    };
  },
  data() {
    return {
      receipt: null,
      currency: "",
    };
  },
  computed: {
    allEmails() {
      let mails = [];
      if (this.receipt.customer) {
        if (this.receipt.customer.email)
          mails.push(this.receipt.customer.email);
        if (this.receipt.customer.other_emails)
          mails.push(...this.receipt.customer.other_emails);
      }
      return mails;
    },
  },
  mounted() {
    if (this.$route.query["markAsShipped"]) {
      this.receiptShipped(this.$route.params.receiptId);
    }

    this.getReceipt().then(() => {
      if (this.$route.query["orderId"]) {
        this.orderModal.open(this.$route.query["orderId"]);
      }
      this.currency = currencySymbol(this.receipt.currency);
    });

    window.Echo.private(`Receipt.${this.$route.params.receiptId}`)
      .listen("ReceiptUpdated", (e) => {
        this.handleReceiptUpdate(e.data.receipt_id);
      })
      .listen("OrderUpdated", (e) => {
        this.handleReceiptUpdate(e.data.receipt_id);
      });
    this.$emitter.on("receipt.updated", this.handleReceiptUpdate);
    this.$emitter.on("order.updated", this.handeOrderUpdateEventLocal);
    this.$emitter.on("orderNotesUpdated", this.handeOrderUpdateEventLocal);
  },
  unmounted() {
    window.Echo.leaveChannel(`Receipt.${this.$route.params.receiptId}`);
    this.$emitter.off("receipt.updated", this.handleReceiptUpdate);
    this.$emitter.off("order.updated", this.handeOrderUpdateEventLocal);
    this.$emitter.off("orderNotesUpdated", this.handeOrderUpdateEventLocal);
  },
  methods: {
    async onParamChange(to, from) {
      // console.log(to.params.receiptId, from.params.receiptId);
      if (to.query["markAsShipped"]) {
        await this.receiptShipped(to.params.receiptId);
      }
      if (to.params.receiptId !== from.params.receiptId) {
        this.getReceipt(to.params.receiptId).then(() => {
          console.log(to.query["orderId"]);
          if (to.query["orderId"]) {
            this.orderModal.open(to.query["orderId"]);
          }
        });
      }
    },
    handleReceiptUpdate(receipt_id) {
      if (Number(receipt_id) === this.receipt?.id) this.getReceipt();
    },
    receiptShipped(receipt_id) {
      return this.axios
        .put(`/receipt/${receipt_id ?? this.receipt.id}/shipped`)
        .then(() => {
          this.$toast.success(receipt_id + " Numaralı Sipariş Kargolandı!", {
            position: "bottom-right",
            duration: 1000,
          });
          this.$emitter.emit("receipt.updated", receipt_id ?? this.receipt.id);
        });
    },
    calculateTaxPercentage,
    priceFormat,
    makeUrgent() {
      this.axios
        .put(`receipt/${this.receipt.id}`, {
          is_urgent: !this.receipt.is_urgent,
        })
        .then((r) => {
          this.receipt = r.data.data;
          this.$emitter.emit("receipt.updated", this.receipt.id);
        });
    },
    async handeOrderUpdateEventLocal(order) {
      let order_id;
      if (typeof order !== "number") order_id = order.id;
      else order_id = order;
      if (order_id) {
        const orderIndex = this.receipt.orders.findIndex(
          (o) => o.id === order_id
        );
        if (orderIndex !== -1) {
          this.receipt = null;
          await nextTick();
          this.getReceipt();
        }
      }
    },
    getCustomerName() {
      return this.receipt.shipment_customer_name
        ? this.receipt.shipment_customer_name
        : this.receipt.customer
          ? this.receipt.customer.name
          : null;
    },
    getReceipt(receiptId) {
      return this.axios
        .get(`/receipt/${receiptId ?? this.$route.params.receiptId}`)
        .then((r) => {
          this.receipt = r.data.data;
        });
    },
    deleteReceipt() {
      this.$swal({
        title: "Bu sipariş silinsin mi?",
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: "Evet",
        denyButtonText: "Hayır",
      }).then((r) => {
        if (r.isConfirmed) {
          this.axios.delete(`/receipt/${this.receipt.id}`).then(() => {
            this.$emitter.emit("receipt.deleted", this.receipt.id);
            this.$router.push({ name: "receipts" });
          });
        }
      });
    },
    openAddOrderModal() {
      useModal({
        component: AddOrderModalVue,
      }).open();
    },
  },
});
</script>

<template>
  <MainHeader />
  <div
    v-if="receipt"
    class="container mx-auto py-4 px-2 lg:px-0 flex flex-col gap-4"
  >
    <div class="flex gap-4">
      <div id="buttons" class="flex gap-6 lg:gap-4 lg:flex-row flex-col">
        <button class="info" @click="openAddOrderModal">
          <font-awesome-icon icon="cart-plus" />
          Ürün Ekle
        </button>
      </div>
      <div id="buttons" class="flex gap-6 lg:gap-4 lg:flex-row flex-col">
        <button class="warning" @click="receiptShipped()">
          <font-awesome-icon icon="truck-ramp-box" />
          Sipariş Kargolandı
        </button>
      </div>
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
      <div
        id="orders"
        class="flex flex-col rounded overflow-hidden lg:col-span-2 h-min gap-4"
      >
        <OrderListItem
          v-for="order in receipt.orders"
          :key="order.id"
          ref="listItems"
          class="bg-white"
          :notes="true"
          :order="order"
          :receipt="receipt"
          show-price="1"
        />
      </div>
      <div
        class="px-4 py-6 bg-white rounded flex flex-col gap-4 relative h-min"
      >
        <div class="absolute right-5 top-5">
          <dropdown-button no-icon align="left">
            <font-awesome-icon icon="ellipsis"></font-awesome-icon>
            <template #dropdown>
              <li>
                <button @click="receiptShipped()">Sipariş Kargolandı</button>
              </li>
              <li>
                <button @click="makeUrgent">
                  {{ receipt.is_urgent ? "Acil Değil" : "Acil Yap" }}
                </button>
              </li>
              <hr class="w-3/4 mx-auto" />
              <li>
                <button @click="deleteReceipt">
                  <font-awesome-icon icon="trash" size="sm" /> Siparişi Sil
                </button>
              </li>
            </template>
          </dropdown-button>
        </div>
        <div>
          <div class="flex flex-col mb-4">
            <div
              v-if="receipt.is_urgent"
              class="flex items-center gap-2 text-red-500 text-sm mb-4"
            >
              <UrgentNotification></UrgentNotification>
              <span>Acil Sipariş</span>
            </div>
            <span class="text text-gray-500">#{{ receipt.id }}</span>
            <span class="text-lg font-bold">{{ getCustomerName() }}</span>
            <div class="flex flex-col gap-2">
              <a
                v-for="(email, index) in allEmails"
                :key="index"
                class="text-sm text-gray-500 flex items-center gap-2"
                :href="`mailto:${email}`"
              >
                <font-awesome-icon icon="envelope" />
                {{ email }}
              </a>
              <a
                v-if="receipt.customer?.other_emails"
                class="btn gray w-min whitespace-nowrap sm"
                :href="`mailto:${allEmails.join(',')}`"
              >
                Tüm Mail Adreslerine Gönder
              </a>
              <a
                class="text-sm text-gray-500 flex items-center gap-2"
                :href="`https://www.etsy.com/your/orders/sold?order_id=${receipt.remote_id}`"
                target="_blank"
              >
                <font-awesome-icon icon="fa-brands fa-etsy" />
                Etsy'de Aç <span class="italic">#{{ receipt.remote_id }}</span>
              </a>
              <span
                v-if="$auth.can('view order.shipping_date')"
                class="text-sm text-gray-500"
              >
                <FontAwesomeIcon icon="truck" class="mr-2" />
                Beklenen Kargo Tarihi
                {{ new Date(receipt.shipping_date).toLocaleDateString() }}
              </span>
            </div>
          </div>

          <p v-if="receipt.address_country">
            {{ receipt.address_first_line }}<br />
            <template v-if="receipt?.address_second_line?.length > 0">
              {{ receipt.address_second_line }} <br />
            </template>
            {{ receipt.address_city }}
            <template v-if="receipt?.address_state?.length > 0">
              , {{ receipt.address_state }} </template
            ><br />
            {{ receipt.address_zip }}<br />
            {{ regNames.of(receipt.address_country) }}<br />
          </p>
          <p v-if="!receipt.address_country">Adres bilgisi bulunamadı</p>
        </div>
        <template v-if="auth.can('view order.price')">
          <hr />
          <table class="w-full">
            <tr>
              <td>Ürün Toplamı</td>
              <td class="text-right text-sm">
                <span class="currency">{{ currency }}</span
                >{{ priceFormat(receipt.total_price) }}
              </td>
            </tr>
            <tr
              :class="{
                'line-through opacity-30': receipt.shipping_price === 0,
              }"
            >
              <td>Kargo</td>
              <td class="text-right text-sm">
                <span class="currency">{{ currency }}</span
                >{{ priceFormat(receipt.shipping_price) }}
              </td>
            </tr>
            <tr
              :class="{
                'line-through opacity-30': receipt.total_vat === 0,
              }"
            >
              <td>VAT</td>
              <td class="text-right text-sm">
                <span class="currency">{{ currency }}</span
                >{{ priceFormat(receipt.total_vat) }}
              </td>
            </tr>
            <tr
              :class="{
                'line-through opacity-30': receipt.total_tax === 0,
              }"
            >
              <td>
                Vergiler
                <span class="text-xs text-gray-600 font-bold"
                  >(%{{
                    calculateTaxPercentage(
                      receipt.total_price,
                      receipt.total_tax
                    )
                  }})</span
                >
              </td>
              <td class="text-right text-sm">
                <span class="currency">{{ currency }}</span
                >{{ priceFormat(receipt.total_tax) }}
              </td>
            </tr>
            <tr v-if="receipt.discount > 0">
              <td>
                İndirim
                <span class="text-xs text-gray-600 font-bold"
                  >(%{{
                    calculateTaxPercentage(
                      receipt.total_price,
                      receipt.discount
                    )
                  }})</span
                >
              </td>
              <td class="text-right text-sm italic">
                - <span class="currency">{{ currency }}</span
                >{{ priceFormat(receipt.discount) }}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <hr class="my-2" />
              </td>
            </tr>
            <tr>
              <td>Genel Toplam</td>
              <td class="text-right">
                <span class="currency">{{ currency }}</span
                >{{
                  priceFormat(
                    receipt.total_vat +
                      receipt.total_tax +
                      receipt.shipping_price +
                      receipt.total_price -
                      receipt.discount
                  )
                }}
              </td>
            </tr>
          </table>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.currency {
  @apply text-xs mr-0.5;
}
</style>
