<template>
  <div
    :class="{ me: note.user_id === auth.user.id }"
    class="flex rounded flex-col p-1 border lg:mr-0 message relative group"
  >
    <div class="flex justify-between">
      <div
        v-if="showUser"
        class="profile flex items-center pl-1 gap-1 font-bold"
      >
        <div
          class="rounded-full w-4 h-4 center text-white text-xs uppercase username"
        >
          {{ note.user.name[0] }}
        </div>
        <span class="text-sm">
          {{ note.user.name }}
        </span>
      </div>
      <div class="flex">
        <div
          v-if="showDate"
          class="text-xs flex items-center mr-2 opacity-50"
          :title="dayjs(note.created_at).format('HH:mm - DD/MM/YYYY')"
        >
          {{
            dayjs().diff(note.created_at, "day") > 1
              ? dayjs(note.created_at).format("HH:mm - DD/MM/YYYY")
              : diffForHumans(note.created_at)
          }}
        </div>
        <button
          class="danger flex center !p-0 !rounded !w-5 aspect-square !hidden group-hover:!flex z-10"
          title="Notu sil!"
          @click="deleteNote"
        >
          <font-awesome-icon icon="close" size="xs" />
        </button>
      </div>
    </div>

    <p class="pl-1 py-0.5">
      <slot />
    </p>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { diffForHumans } from "@/lib/Helpers";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useAuthStore } from "@/stores/auth";
export default {
  name: "MessageBubble",
  components: { FontAwesomeIcon },
  props: {
    showUser: {
      type: Boolean,
    },
    showDate: {
      type: Boolean,
    },
    note: {
      type: Object,
    },
  },
  emits: ["note-deleted"],
  setup() {
    const auth = useAuthStore();
    return {
      diffForHumans,
      dayjs,
      auth,
    };
  },
  methods: {
    deleteNote(note) {
      this.$swal({
        title: "Bu not silinsin mi?",
        html: `<b>${this.note.user.name}:</b> ${this.note.text}`,
        showConfirmButton: true,
        showDenyButton: true,
        denyButtonText: "İptal Et",
        confirmButtonText: "Evet",
      }).then((r) => {
        if (r.isConfirmed) {
          this.axios.delete(`note/${this.note.id}`).then(() => {
            this.$emit("note-deleted", note);
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.message:not(.me) {
  @apply border-sky-200 text-sky-900 bg-sky-50 mr-16;
  & .username,
  & .text-sky-400 {
    @apply bg-sky-400;
  }
}
.message.me {
  @apply border-green-200 text-green-900 bg-green-50 ml-16;
  & .username,
  & .text-green-400 {
    @apply bg-green-400;
  }
}
</style>
