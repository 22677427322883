<script setup>
import TagSelector from "@/components/form/TagSelector.vue";
import { deepDiff } from "@/lib/Helpers";
import { onMounted, ref, toRaw } from "vue";
import { useSettingsStore } from "@/stores/settings";
import { cloneDeep } from "lodash";
import Loading from "@/components/misc/Loading.vue";
import axiosClient from "@/lib/AxiosClient";
const settings = useSettingsStore();

const globalSettings = ref(null);
const loading = ref(false);
const globalSettingsSaved = ref(null);
function getGlobalSettings() {
  loading.value = true;
  axiosClient
    .get("settings")
    .then((r) => {
      globalSettingsSaved.value = cloneDeep(r.data);
      globalSettings.value = r.data;
    })
    .finally(() => (loading.value = false));
}

onMounted(() => {
  getGlobalSettings();
});
function globalSettingUpdate() {
  const changes = deepDiff(
    toRaw(globalSettingsSaved.value),
    toRaw(globalSettings.value)
  );
  const indexes = changes
    .filter((c) => c.type === "updated")
    .map((c) => Number(c.key));

  const payload = indexes.map((i) => ({
    key: globalSettings.value[i].key,
    value: globalSettings.value[i].value,
  }));
  loading.value = true;
  axiosClient
    .put("/settings/bulk", {
      settings: payload,
    })
    .then((r) => {
      globalSettingsSaved.value = cloneDeep(r.data);
      globalSettings.value = r.data;
      settings.fetchGlobalSettings();
    })
    .finally(() => (loading.value = false));
}
</script>

<template>
  <div class="flex flex-col w-full relative">
    <h1>Global Ayarlar</h1>
    <Loading :is-loading="loading" />
    <div class="flex flex-col w-full global">
      <FormKit
        id="globalSettingsForm"
        type="form"
        :classes="{
          form: 'grid grid-cols-2 gap-4',
        }"
        :actions="false"
      >
        <template v-for="(setting, i) in globalSettings" :key="i">
          <template v-if="!setting?.input?.component">
            <FormKit
              v-model="setting.value"
              :label="setting.name"
              :name="setting.key"
              :value="setting.value"
              :help="setting.help"
              :type="`${setting.input?.type ?? 'text'}`"
              :outer-class="`${
                setting.input.type === 'textarea' ? 'col-span-2' : ''
              }`"
            />
          </template>
          <template v-else>
            <component
              :is="TagSelector"
              v-model="setting.value"
              :name="setting.key"
              :help="setting.help"
              :label="setting.name"
            />
          </template>
        </template>
      </FormKit>
    </div>
    <div class="flex justify-end">
      <button class="info" type="button" @click="globalSettingUpdate">
        Ayarları Kaydet
      </button>
    </div>
  </div>
</template>

<style lang="scss">
.settings__tab {
  .formkit-outer {
    @apply mb-4 break-inside-avoid;
  }
  .formkit-inner,
  .formkit-input {
    @apply max-w-full #{!important};
  }
  textarea.formkit-input {
    @apply h-[500px];
  }
}
</style>
