<template>
  <div
    v-if="$props.isLoading"
    class="text-2xl top-0 left-0 bottom-0 right-0 center p-4"
    :class="{
      'absolute backdrop-blur-xl bg-white/30 z-50': !$props.noBg,
    }"
  >
    Yükleniyor
    <font-awesome-icon icon="circle-notch" class="animate-spin ml-2" />
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "Loading",
  components: { FontAwesomeIcon },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    noBg: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
