import { useModal, useVfm } from "vue-final-modal";
import OrderModal from "@/components/modals/OrderModal.vue";

export const useOrderModal = () => {
  const vfm = useVfm();

  const open = (orderId, useHistory = false) => {
    close();
    const modalInstance = useModal({
      component: OrderModal,
      attrs: {
        modalId: "order-modal",
        orderId,
      },
    });
    modalInstance.open();
  };

  const close = () => {
    vfm.close("order-modal");
  };

  return {
    open,
    close,
  };
};
