<script>
import { defineComponent } from "vue";
import MessageBubble from "@/components/misc/MessageBubble.vue";
import { useAuthStore } from "@/stores/auth";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default defineComponent({
  name: "OrderNotes",
  components: { MessageBubble, FontAwesomeIcon },
  props: {
    hideIfEmpty: {
      type: Boolean,
      default: false,
    },
    notes: {
      type: Array,
      required: true,
    },
    submitable: {
      type: Boolean,
      default: true,
    },
    orderId: {
      default: null,
      type: [String, Number],
    },
    timeline: {
      type: Array,
      default: null,
    },
  },
  setup() {
    const auth = useAuthStore();
    return { auth };
  },
  data() {
    return {
      messageInput: "",
      localNotes: [],
    };
  },
  computed: {
    orderEvents() {
      const timeline = this.timeline
        ? this.timeline.map((t) => {
            t.type = "shipping";
            return t;
          })
        : [];
      const messages = this.localNotes.map((n) => {
        n.type = "note";
        return n;
      });
      const events = [...timeline, ...messages];
      return events.sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at);
      });
    },
  },
  mounted() {
    this.localNotes = this.notes;
    window.Echo.private(`Notes.order.${this.orderId}`)
      .listen("NoteCreated", (e) => {
        this.localNotes.push(e.note);
      })
      .listen("NoteDeleted", (e) => {
        const index = this.localNotes.findIndex((n) => n.id === e.note_id);
        if (index > -1) this.localNotes.splice(index, 1);
      });
  },
  unmounted() {
    window.Echo.leave(`Notes.order.${this.orderId}`);
  },
  methods: {
    handleDelete() {
      this.$emitter.emit("orderNotesUpdated", this.orderId);
      this.getNotes();
    },
    showDate(index) {
      return true;
    },
    createNote() {
      this.axios
        .post("note", {
          text: this.messageInput,
          notable_type: "order",
          notable_id: this.orderId,
        })
        .then((r) => {
          this.messageInput = "";
          this.localNotes.push(r.data.data);
          this.$formkit.reset("newNote");
          this.$emitter.emit("orderNotesUpdated", this.orderId);
        });
    },
    sameUser(index) {
      if (index > 0 && this.localNotes.length > 0)
        return (
          this.localNotes[index].user_id === this.localNotes[index - 1].user_id
        );
      return false;
    },
    getNotes() {
      this.axios
        .get(`note`, {
          params: {
            notable_type: "order",
            notable_id: this.orderId,
          },
        })
        .then((r) => {
          this.localNotes = r.data;
        });
    },
  },
});
</script>

<template>
  <div
    v-if="hideIfEmpty ? notes.length !== 0 : true"
    class="w-full flex flex-col gap-2 bg-white p-2 border rounded"
  >
    <div
      class="flex flex-col gap-2"
      :class="{ 'h-72 overflow-y-auto': notes.length > 0 && submitable }"
    >
      <template v-for="(event, index) in orderEvents" :key="index">
        <template v-if="event.type === 'note'">
          <MessageBubble
            :note="event"
            :show-user="true"
            :show-date="showDate(index)"
            @note-deleted="handleDelete"
          >
            {{ event.text }}
          </MessageBubble>
        </template>

        <template v-else-if="event.type === 'shipping'">
          <div class="w-full border p-2 text-center rounded bg-gray-100">
            <FontAwesomeIcon icon="truck" />
            Sipariş
            {{ new Date(event.created_at).toLocaleDateString() }} tarihinde
            gönderildi!
          </div>
        </template>
      </template>
    </div>
    <div
      v-if="localNotes?.length === 0"
      class="mt-4 mx-auto text-center w-2/4 not__found flex items-center justify-center"
    >
      <div class="ping"></div>
      <div
        class="bg-sky-100 w-full h-full px-1 py-0.5 text-sky-800 relative rounded not__found__text"
      >
        Not bulunamadı!
      </div>
    </div>
    <div v-if="submitable" class="pt-4 lg:mt-0 w-full flex">
      <form-kit
        type="form"
        :actions="false"
        form-class="!w-full"
        @submit="createNote"
      >
        <form-kit
          id="newNote"
          v-model="messageInput"
          type="text"
          :classes="{
            inner: '!rounded-r-none !mb-0 !w-full  max-w-none',
            input: '!w-full',
            outer: '!mb-0 w-full z-10',
          }"
          input-class=""
          validation="required"
          validation-label="İçerik"
          autocomplete="off"
          placeholder="Yeni bir not girin..."
        />
      </form-kit>
      <button
        class="info !py-2 !px-3 !rounded-l-none ring-1 min-w-[4rem] !h-10"
        @click="createNote"
      >
        <font-awesome-icon icon="paper-plane" />
      </button>
    </div>
    <!--    <div class="flex gap-2 text-xs font-bold">-->
    <!--      <button class="default !py-0.5 !px-2">-->
    <!--        <font-awesome-icon icon="image" />-->
    <!--        Görsel Yükle-->
    <!--      </button>-->
    <!--      <button class="default !py-0.5 !px-2">-->
    <!--        <font-awesome-icon icon="upload" />-->
    <!--        Dosya Yükle-->
    <!--      </button>-->
    <!--    </div>-->
  </div>
</template>

<style lang="scss" scoped>
.not__found {
  .ping {
    @apply w-full h-full bg-sky-600 animate-ping rounded p-2 box-content;
  }
  .not__found__text {
    @apply absolute w-full h-8 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 flex items-center justify-center;
  }
  @apply relative;
}
</style>
