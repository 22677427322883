<template>
  <header class="w-full lg:h-[60px] bg-sky-600 monte sticky lg:relative z-40">
    <div
      class="mx-auto lg:container lg:px-4 w-full h-full flex lg:items-center items-start lg:justify-between flex-col lg:flex-row"
    >
      <div
        class="px-4 lg:m-0 py-2 lg:py-0 flex lg:block justify-between w-full z-40 bg-sky-600"
      >
        <Logo />
        <button
          class="text-white collapse-button lg:hidden"
          @click="navbarShow = !navbarShow"
        >
          <font-awesome-icon
            icon="bars"
            size="lg"
            :class="{ hidden: navbarShow }"
          />
          <font-awesome-icon
            icon="xmark"
            size="lg"
            :class="{ hidden: !navbarShow }"
          />
        </button>
      </div>
      <div
        class="lg:hidden z-20 w-full bg-sky-500 px-4"
        :class="{ hidden: !navbarShow }"
      >
        <ul class="flex flex-col gap-4 py-5 text-white font-semibold">
          <template v-for="(link, index) in links" :key="index">
            <li v-if="!link.perm || auth.canAnyOf(link.perm)">
              <router-link :to="link.to">
                {{ link.name }}
              </router-link>
            </li>
          </template>
          <DropdownButton>
            <FontAwesomeIcon icon="circle-user" />
            {{ auth.user.name }}
            <template #dropdown>
              <button class="p-2" @click="logout">Çıkış Yap</button>
            </template>
          </DropdownButton>
        </ul>
      </div>
      <div class="hidden lg:flex pl-2 justify-end mr-4 font-semibold">
        <ul class="flex navbar">
          <template v-for="(link, index) in links" :key="index">
            <li v-if="!link.perm || auth.canAnyOf(link.perm)">
              <router-link :to="link.to">
                {{ link.name }}
              </router-link>
            </li>
          </template>
        </ul>

        <button
          class="user text-white flex items-center dropdown relative whitespace-nowrap"
        >
          <span class="flex items-center gap-1">
            <FontAwesomeIcon icon="circle-user" size="xl" />
            {{ auth.user.name }}
          </span>
          <font-awesome-icon icon="fas fa-caret-down" class="ml-2" />
          <div class="top-6 w-32 right-0 menu">
            <ul>
              <li class="px-3 py-2" @click="logout">Çıkış Yap</li>
            </ul>
          </div>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import Logo from "@/components/Logo.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useAuthStore } from "@/stores/auth";
import DropdownButton from "./misc/DropdownButton.vue";

export default {
  name: "MainHeader",
  components: { FontAwesomeIcon, Logo, DropdownButton },
  setup() {
    const auth = useAuthStore();
    return {
      auth,
    };
  },
  data() {
    return {
      navbarShow: false,
      links: [
        {
          name: "Anasayfa",
          to: "/",
        },
        {
          name: "Siparişler",
          to: { name: "receipts" },
          perm: "order",
        },
        // {
        //   name: 'Ürünler',
        //   to: {name: 'products'}
        // },
        {
          name: "Şemalar",
          to: { name: "schemas" },
          perm: "schema",
        },
        {
          name: "Dükkanlar",
          to: { name: "etsy_shops" },
          perm: "shop",
        },
        {
          name: "Listeler",
          to: { name: "etsy_listing_wizard" },
          perm: "shop",
        },
        {
          name: "Ayarlar",
          to: { name: "personal" },
        },
      ],
    };
  },
  methods: {
    logout() {
      this.$auth.logout().then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style scoped>
.router-link-active {
  @apply text-white decoration-2 underline underline-offset-8 font-bold;
}
</style>
