<script setup>
import axiosClient from "@/lib/AxiosClient";
import { onMounted } from "vue";
import { ref } from "vue";
import { computed } from "vue";
import { VueFinalModal, useVfm } from "vue-final-modal";
import { useRoute } from "vue-router";
import EtsyImage from "./EtsyImage.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ConfirmButton from "@/components/form/ConfirmButton.vue";
const vfm = useVfm();
const props = defineProps({
  selectedImages: {
    type: Array,
    required: true,
  },
});

const excludedIds = ref([]);
function toggleExclude(id) {
  const index = excludedIds.value.indexOf(id);
  if (index !== -1) {
    excludedIds.value.splice(index, 1);
  } else {
    excludedIds.value.push(id);
  }
}

const foundImagesWithIds = ref({});
const route = useRoute();
function getListingImages() {
  const codes = [];
  props.selectedImages.forEach((si) => {
    codes.push(si.hex_code);
  });
  axiosClient
    .get(`/etsy/shop/${route.params.etsyShopId}/get-images-by-hex`, {
      params: { hex_codes: codes },
    })
    .then((r) => {
      foundImagesWithIds.value = r.data.data;
    });
}

const replacementImage = ref(null);
const imagePreviewUrl = ref(null);
function handlePreview(e) {
  if (e.length === 0) {
    imagePreviewUrl.value = null;
    return;
  }
  const reader = new FileReader();
  reader.readAsDataURL(e[0].file);
  reader.onload = (e) => {
    imagePreviewUrl.value = e.target.result;
  };
}
function startUpdating() {
  const filteredIds = Object.keys(foundImagesWithIds.value).filter(
    (id) => !excludedIds.value.includes(id),
  );
  const form = new FormData();
  filteredIds.forEach((id) => {
    form.append("ids[]", id);
  });
  form.append("replacement_image", replacementImage.value[0].file);
  axiosClient({
    method: "post",
    url: `/etsy/shop/${route.params.etsyShopId}/start-updating-images`,
    data: form,
    headers: { "Content-Type": "multipart/form-data" },
  }).then((r) => {
    vfm.close("image-validator-modal");
  });
}

const step = ref(1);
function stepTo(stp) {
  step.value = stp;
}
onMounted(() => {
  getListingImages();
});
const emit = defineEmits(["updateStarted"]);
</script>
<template>
  <VueFinalModal ref="modal" modal-id="image-validator-modal">
    <div class="modal-body p-4 max-w-4xl w-[80vw]">
      <div v-if="step === 1" class="flex flex-col mb-4 gap-2">
        <h1 class="uppercase text-3xl font-bold monte text-center">
          <FontAwesomeIcon icon="triangle-exclamation" class="text-red-500" />
          Tüm Görseller Aynı mı?
        </h1>
        <p class="text-center">
          Görsel algılama sırasında
          <span class="text-red-500 font-black">hata</span> olabilir. Hatayı
          önlemek için aşağıdaki görsellerden farklı olanların üzerine
          tıklayarak listeden çıkartabilirsiniz.
        </p>
      </div>
      <div v-if="step === 1" class="grid grid-cols-8 gap-3">
        <EtsyImage
          v-for="(image, id) in foundImagesWithIds"
          :key="id"
          :src="image[0].url_170x135"
          :disabled="excludedIds.includes(id)"
          @toggle="toggleExclude(id)"
        />
      </div>
      <div v-else class="flex flex-col gap-8 items-center justify-center my-4">
        <h1 class="uppercase text-3xl font-bold monte text-center">
          <FontAwesomeIcon icon="image" class="text-green-500" />
          BİR GÖRSEL Yükle
        </h1>
        <p class="text-center">
          Değiştirilmek üzere seçilen görseller yerine yüklemek için yeni bir
          görsel seçin.
        </p>
        <img v-if="imagePreviewUrl" :src="imagePreviewUrl" />
        <FormKit
          v-model="replacementImage"
          type="file"
          label="Yüklenecek Görsel"
          accept=".png,.jpg,.jpeg,.webp"
          @input="handlePreview"
        />
      </div>
      <div class="flex justify-end mt-4 border-t pt-4 gap-4">
        <ConfirmButton
          v-if="step === 2"
          class="danger"
          text="Bu işlem istenmeyen sonuçlara sebep olabilir."
          @confirmed="startUpdating"
        >
          Güncelle
        </ConfirmButton>
        <button v-if="step === 2" class="success" @click="stepTo(1)">
          Önceki Adım
        </button>
        <button v-else-if="step === 1" class="warning" @click="stepTo(2)">
          Sonraki Adım
        </button>
        <button class="gray" @click="vfm.close('image-validator-modal')">
          Vazgeç
        </button>
      </div>
    </div>
  </VueFinalModal>
</template>
