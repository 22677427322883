<script setup>
import TagSpan from "@/components/misc/TagSpan.vue";

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  count: {
    type: Number,
    default: null,
  },
  isChecked: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["toggle"]);
</script>

<template>
  <div
    class="w-full relative rounded-md border overflow-hidden cursor-pointer"
    @click="emit('toggle')"
  >
    <div
      v-if="!props.disabled && props.isChecked"
      class="absolute w-full h-full bg-lime-900 bg-opacity-80 flex items-center justify-center"
    >
      <font-awesome-icon
        icon="check-circle"
        size="2xl"
        class="text-lime-300 bg-lime-800 rounded-full"
      />
    </div>
    <div
      v-if="props.disabled"
      class="absolute w-full h-full bg-gray-900 bg-opacity-80 flex items-center justify-center"
    >
      <font-awesome-icon
        icon="xmark-circle"
        size="2xl"
        class="text-gray-300 bg-gray-800 rounded-full"
      />
    </div>
    <TagSpan
      v-if="props.count"
      class="absolute top-1 right-1 font-semibold text-white bg-gray-600"
      :class="{ 'bg-lime-600': props.isChecked }"
      >{{ props.count }}</TagSpan
    >
    <img
      class="w-full aspect-[3/2] object-cover object-center"
      :src="props.src"
      alt=""
    />
  </div>
</template>

<style scoped></style>
