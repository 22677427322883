<template>
  <div class="flex flex-nowrap relative w-full">
    <input-label v-if="$props.labelText">
      {{ $props.labelText }}
    </input-label>
    <input  
      type="text"
      class="rounded-r-none border-r-0 px-3 placeholder:font-normal w-full"
      :value="modelValue"
      :placeholder="$props.placeholder"
      @input="$emit('update:modelValue', $event.target.value)"
    >
    <div
      class="bg-white border border-l-0 rounded-r-md w-10 hover:bg-gray-300 transition-all flex items-center justify-center cursor-pointer"
      @click="$emit('update:modelValue', '');"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
  </div>
</template>
<script>
import InputLabel from "@/components/misc/InputLabel.vue";

export default {
  components: {InputLabel},
    props: {
        modelValue: {
          type: [String, Number],
          validator: (p) => {
            return ['string', 'number'].indexOf(typeof p) !== -1 || p === null;
          },
        },
        labelText: {
            type: String,
            default: null,
            required: false
        },
        placeholder: {
            type: String,
            default: null,
            required: false
        },
        buttonFunction: {
            type: Function,
            default: null
        }
    },
}
</script>
<style lang="scss" scoped>
@import "@/style/variables";
input{
    &[type="text"], &[type="email"], &[type="number"], &[type="password"]{
        @apply py-2 px-3 border border-gray-100 text-base outline-#{$default-color}-500 #{$default-rounded} outline-1
    }
}
</style>