<template>
  <vue-final-modal @closed="emitClose">
    <div
      v-if="!order"
      class="w-56 h-36 bg-white m-4 relative overflow-hidden rounded-md"
    >
      <Loading :is-loading="true"></Loading>
    </div>

    <div v-if="order" class="modal-body relative">
      <div
        class="w-full mb-4 flex flex-row md:justify-between text-sm font-bold bg-gray-300 border-b-4 p-2 md:p-0 rounded-t-md overflow-hidden"
      >
        <div
          class="top-bar left grid grid-cols-2 md:flex md:flex-row w-full px-4"
        >
          <button
            class="gray !rounded-none col-span-2 md:!hidden block"
            @click="$emit('update:modelValue', false)"
          >
            <font-awesome-icon icon="close" /> Kapat
          </button>
          <button @click="makeUrgent()">
            <font-awesome-icon icon="stopwatch" />
            {{
              order.receipt.is_urgent
                ? "Sipariş Acil Değil"
                : "Siparişi Acil Yap"
            }}
          </button>
          <button v-if="$route.name !== 'receipt'" @click="goToReceipt">
            <font-awesome-icon icon="receipt" />
            Siparişe Git
          </button>
          <a
            v-if="order.receipt.channel.toLowerCase() === 'etsy'"
            target="_blank"
            :href="`https://www.etsy.com/your/orders/sold?order_id=${order.receipt.remote_id}`"
          >
            <font-awesome-icon icon="fa-brands fa-etsy" />
            Etsy'de Aç
          </a>
          <PrintLabel :order-id="order.id">
            <template #default="{ print }">
              <button @click="print">
                <font-awesome-icon icon="print" />
                Yazdır
              </button>
            </template>
          </PrintLabel>
          <ConfirmButton
            v-if="auth.can('delete order')"
            text="Siparişi silmek istediğinizden emin misiniz?"
            @confirmed="deleteOrder"
          >
            <FontAwesomeIcon icon="trash" />
            Siparişi Sil
          </ConfirmButton>
        </div>
        <div class="md:w-full justify-end hidden md:flex">
          <button
            class="danger !rounded-none !h-full aspect-square"
            @click="$emit('update:modelValue', false)"
          >
            <font-awesome-icon icon="close" size="lg" />
          </button>
        </div>
      </div>
      <div
        class="flex gap-4 px-4 flex-col landscape:flex-row portrait:flex-col relative"
      >
        <div class="landscape:lg:w-5/12 w-full flex flex-col gap-4 order-12">
          <div class="border rounded p-1 bg-white">
            <table class="main">
              <tr>
                <td>ID</td>
                <td>
                  <div class="flex items-center gap-4">
                    <span class="font-bold text-xl">#{{ order.id }}</span>
                    <div
                      v-if="order.receipt.is_urgent"
                      class="flex items-center w-full gap-2"
                    >
                      <UrgentNotification></UrgentNotification>
                      <span class="text-red-500">Acil Sipariş</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="w-1/4">Sipariş Durumu</td>
                <td>
                  <div class="flex flex-wrap gap-1.5">
                    <OrderStatusSelect
                      :status="order.status"
                      @update:status="changeStatus"
                    />
                    <InlineTags
                      v-model:tags="order.tags"
                      :taggable-id="order.id"
                      taggable-type="Order"
                      @update:tags="fireUpdatedEvent"
                    ></InlineTags>
                  </div>
                </td>
              </tr>

              <tr v-if="order.customer_note">
                <td class="w-1/4">
                  Müşteri Notu <br />
                  <translate-button
                    @click="translateText('customer_note', order.customer_note)"
                  />
                </td>
                <td>{{ checkTranslate(order, "customer_note") }}</td>
              </tr>

              <tr v-if="auth.can('assign schema to order')">
                <td>Şema Ekle</td>
                <td>
                  <SchemaAttacher :guides="guides" @add-schema="addSchema" />
                </td>
              </tr>
              <tr v-if="auth.can('assign user to order')">
                <td>Görevliler</td>
                <td>
                  <UserAttacher
                    v-model:users="order.users"
                    :resource-id="order.id"
                    @update:users="fireUpdatedEvent"
                  />
                </td>
              </tr>
              <tr
                v-if="
                  availableCustomFields.length > 0 &&
                  auth.can('assign schema to order')
                "
              >
                <td>Özel Alan</td>
                <td>
                  <button
                    v-for="customField in availableCustomFields"
                    :key="customField.key"
                    class="info"
                    @click="addCustomField(customField.key)"
                  >
                    {{ customField.name }} Ekle
                  </button>
                </td>
              </tr>
            </table>
          </div>
          <div class="border rounded p-1 bg-white">
            <table class="main">
              <tr>
                <td class="w-1/4">Adet</td>
                <td class="!p-0">
                  <div
                    class="p-2"
                    :class="{
                      'mb-1 bg-red-200 animate-pulse rounded':
                        order.quantity > 1,
                    }"
                  >
                    {{ order.quantity }}
                  </div>
                </td>
              </tr>
              <tr
                v-if="
                  !order.listing_description &&
                  order.receipt.channel.toLowerCase() === 'etsy'
                "
              >
                <td class="w-1/4">Liste Açıklaması</td>
                <td>
                  <button class="info sm" @click="getListingDescription">
                    Açıklamayı Yükle
                  </button>
                </td>
              </tr>
              <tr v-if="order.listing_description">
                <td class="w-1/4">
                  Liste Açıklaması <br />
                  <translate-button
                    v-if="showDescription"
                    @click="
                      translateText(
                        'listing_description',
                        order.listing_description
                      )
                    "
                  />
                </td>
                <td>
                  <button
                    v-if="!showDescription"
                    class="info sm"
                    @click="showDescription = true"
                  >
                    Açıklamayı Göster
                  </button>
                  <button
                    v-if="showDescription"
                    class="info sm"
                    @click="showDescription = false"
                  >
                    Açıklamayı Gizle
                  </button>
                  <div v-if="showDescription">
                    {{ checkTranslate(order, "listing_description") }}
                  </div>
                </td>
              </tr>

              <tr v-for="(option, i) in order.options" :key="option.value_id">
                <td>
                  {{ option.name }}<br />
                  <translate-button @click="translateOption(option, i)" />
                </td>
                <td>{{ decode(checkOptionTranslate(i)) }}</td>
              </tr>
            </table>
          </div>
          <template v-if="order.custom_fields">
            <component
              :is="getComponent(key)"
              v-for="(value, key) in order.custom_fields"
              :key="key"
              v-model="order.custom_fields[key]"
              @update:model-value="
                updateOrder({ custom_fields: order.custom_fields })
              "
              @delete-field="removeCustomField(key)"
            />
          </template>

          <section v-if="order.guides?.length > 0" class="flex flex-col gap-4">
            <template v-for="(guide, index) in order.guides" :key="guide.id">
              <SchemaView
                v-model:initialGuide="order.guides[index]"
                overwriteable
              />
            </template>
          </section>

          <OrderNotes
            :order-id="order.id"
            :notes="order.notes"
            :timeline="timeline"
          />
        </div>
        <div class="flex flex-col portrait:w-full landscape:lg:w-7/12 gap-4">
          <div class="border rounded">
            <table class="main">
              <tr v-if="order.listing_title">
                <td class="w-1/4">
                  Liste Adı <br />
                  <translate-button
                    @click="translateText('listing_title', order.listing_title)"
                  />
                </td>
                <td>{{ decode(checkTranslate(order, "listing_title")) }}</td>
              </tr>
            </table>
          </div>
          <ImageViewer
            resource="order"
            :resource-id="order.id"
            :images="order.images"
            @update:images="() => $emitter.emit('order.updated', order.id)"
          />
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>
<script>
import { decode } from "he";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { VueFinalModal } from "vue-final-modal";
import DropdownButton from "@/components/misc/DropdownButton.vue";
import OrderNotes from "@/components/misc/OrderNotes.vue";
import CustomOrganizer from "@/components/misc/custom_fields/CustomOrganizer.vue";
import { translate } from "@/lib/Helpers.js";
import TranslateButton from "@/components/misc/TranslateButton.vue";
import OrderStatus from "@/components/misc/OrderStatus.vue";
import "vue-search-select/dist/VueSearchSelect.css";
import receipts from "@/views/Receipts.vue";
import { ModelSelect } from "vue-search-select";
import InlineTags from "@/components/misc/InlineTags.vue";
import SchemaView from "@/components/misc/SchemaView.vue";
import Loading from "@/components/misc/Loading.vue";
import PrintLabel from "@/components/form/PrintLabel.vue";
import UrgentNotification from "@/components/misc/UrgentNotification.vue";
import { useSettingsStore } from "@/stores/settings";
import UserBadge from "@/components/misc/UserBadge.vue";
import UserAttacher from "@/components/form/UserAttacher.vue";
import OrderStatusSelect from "@/components/misc/OrderStatusSelect.vue";
import ImageViewer from "@/components/misc/ImageViewer.vue";
import ConfirmButton from "../form/ConfirmButton.vue";
import { useAuthStore } from "@/stores/auth";
import SchemaAttacher from "../form/SchemaAttacher.vue";

export default {
  name: "OrderModal",
  components: {
    OrderStatusSelect,
    UserAttacher,
    UserBadge,
    UrgentNotification,
    PrintLabel,
    Loading,
    SchemaView,
    InlineTags,
    OrderStatus,
    TranslateButton,
    OrderNotes,
    DropdownButton,
    VueFinalModal,
    FontAwesomeIcon,
    ModelSelect,
    ImageViewer,
    ConfirmButton,
    SchemaAttacher,
  },
  props: {
    orderId: {
      type: [Number, String],
      required: true,
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const settings = useSettingsStore();
    const auth = useAuthStore();
    const usersStore = settings.getUsers;
    const customFields = [
      {
        name: "Organizer Cepleri",
        key: "organizer",
        component: CustomOrganizer,
      },
    ];
    return {
      auth,
      customFields,
      decode,
      usersStore,
    };
  },
  data() {
    return {
      showDescription: false,
      userToBeAdded: null,
      schemas: [],
      users: [],
      order: null,
      translations: {},
      optionTranslations: {},
    };
  },
  computed: {
    timeline() {
      return this.order.shipped_at;
    },
    availableCustomFields() {
      if (!this.order.custom_fields) return this.customFields;
      return this.customFields.filter(
        (cf) => !Object.keys(this.order.custom_fields).includes(cf.key)
      );
    },
    receipts() {
      return receipts;
    },
    guides() {
      let a = Array(...this.order?.guides);
      if (this.order.product) {
        a = a.concat(...this.order?.product?.guides);
      }
      return a;
    },
  },
  mounted() {
    this.users = this.usersStore.map((u) => {
      return {
        value: u.id,
        text: u.name,
      };
    });
    this.$emitter.on("closeOrderModal", this.closeModal);
    this.$emitter.on("orderUpdated", this.handleOrderUpdate);

    window.Echo.private("Receipts")
      .listen("OrderUpdated", (event) => {
        this.handleOrderUpdate(event.data.order_id);
      })
      .listen("ReceiptUpdated", (event) => {
        if (this.order.receipt_id === event.data.receipt_id) this.getOrder();
      });
    this.getOrder();
  },
  methods: {
    addCustomField(fieldType) {
      if (!this.order.custom_fields) this.order.custom_fields = {};
      this.order.custom_fields[fieldType] = null;
    },
    removeCustomField(fieldType) {
      const found = Object.keys(this.order.custom_fields).find(
        (cf) => cf === fieldType
      );
      if (!found) return;
      delete this.order.custom_fields[fieldType];
      this.updateOrder({
        custom_fields:
          this.order.custom_fields.length > 0 ? this.order.custom_fields : null,
      });
    },
    getListingDescription() {
      this.axios
        .put(`/order/${this.order.id}/update-listing-data`)
        .then((r) => {
          this.order = r.data;
          this.showDescription = true;
        });
    },
    handleOrderUpdate(order_id) {
      if (this.order && order_id === this.order?.id) this.getOrder();
    },
    fireUpdatedEvent() {
      this.$emitter.emit("receipt.updated", this.order.receipt_id);
      this.$emitter.emit("order.updated", this.order.id);
    },
    makeUrgent() {
      this.axios
        .put(`receipt/${this.order.receipt_id}`, {
          is_urgent: !this.order.receipt.is_urgent,
        })
        .then((r) => {
          this.order.receipt = r.data.data;
          this.fireUpdatedEvent();
        });
    },
    removeSchema(schemaId) {
      this.axios
        .get(`/order/${this.order.id}/remove-schema/${schemaId}`)
        .then((r) => {
          this.$toast.success("Şema silindi");
          this.order.guides = r.data.guides;
          this.fireUpdatedEvent();
        });
    },
    addSchema(schemaId) {
      this.axios
        .get(`/order/${this.order.id}/add-schema/${schemaId}`)
        .then((r) => {
          this.schemas = [];
          this.$toast.success("Şema eklendi");
          this.order.guides = r.data.guides;
          this.fireUpdatedEvent();
        });
    },

    goToReceipt() {
      this.closeModal();
      this.$router.push({
        name: "receipt",
        params: { receiptId: this.order.receipt_id },
      });
    },
    emitClose() {
      this.$emitter.emit("orderModalClosed", this.order?.id ?? null);
    },
    changeStatus(to) {
      this.axios
        .put(`/order/${this.orderId}`, {
          status: to,
        })
        .then((res) => {
          this.order = res.data;
          this.$emitter.emit("receipt.updated", this.order.receipt_id);
          this.$toast.success("Sipariş durumu güncellendi.");
        });
    },
    updateOrder(to) {
      this.axios.put(`/order/${this.orderId}`, to).then((res) => {
        this.order = res.data;
        this.$toast.success("Sipariş güncellendi.");
      });
    },
    async translateText(key, text) {
      const res = await translate(text);
      this.translations[key] = res.data.text;
    },
    async translateOption(option, i) {
      const res = await translate(decode(option.value));
      this.optionTranslations[i] = res.data.text;
    },
    checkTranslate(parent, key) {
      if (this.translations[key]) {
        return this.translations[key];
      }
      return decode(parent[key]);
    },
    checkOptionTranslate(key) {
      if (this.optionTranslations[key]) {
        return this.optionTranslations[key];
      }
      return this.order.options[key].value;
    },
    closeModal() {
      this.$emit("update:modelValue", false);
    },
    getComponent(field_name) {
      const matches = this.customFields.find((cf) => cf.key === field_name);
      return matches.component;
    },
    getOrder() {
      this.axios(`/order/${this.orderId}`)
        .then((data) => {
          this.order = data.data;
        })
        .catch((e) => {
          this.closeModal();
        });
    },
    async deleteOrder() {
      await this.axios.delete(`/order/${this.orderId}`);
      this.$emitter.emit("receipt.updated", this.order.receipt_id);
      this.closeModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.top-bar {
  & > button,
  & > a {
    @apply whitespace-nowrap border-l border-gray-400 bg-transparent text-white md:text-gray-950 px-4 rounded-none transition-colors font-normal flex items-center gap-2 px-3 py-3 bg-gray-600 md:bg-transparent;
  }
  & > *:last-child {
    @apply border-r;
  }
  & > button:hover,
  & > a:hover {
    @apply bg-gray-950 text-white;
  }
}
.preview.active {
  @apply -translate-y-1 shadow-lg border-2 border-sky-600;
}
table {
  & {
    @apply w-full;
  }
  & td {
    @apply px-2 py-2 bg-white rounded;
  }
  :not(.guide) > & tr:not(:last-child) {
    @apply border-b;
  }
  //:not(.guide) > & tr:not(:last-child) td {
  //  @apply pb-4;
  //}
  & tr:last-child td {
    @apply pt-4;
  }
  .guide > & td {
    @apply border;
  }
}
table.main tr td:first-child:not([colspan]) {
  @apply w-1/4 text-right pr-4 font-bold;
}
</style>
