<template>
  <vue-final-modal>
    <div class="modal-body lg:w-[50vw] p-4 flex gap-8 flex-col items-center">
      <h2>Şema Oluştur</h2>
      <!--        INFO SECTION START-->
      <section id="info" class="w-full flex flex-col gap-4">
        <table>
          <tr>
            <td>Şema İsmi</td>
            <td>
              <form-kit
                v-model="schemaInput.name"
                placeholder="Şema ismi"
                type="text"
              />
            </td>
          </tr>
        </table>
        <table class="compatible">
          <tr>
            <td>Uyumlu Marka</td>
            <td>
              <BrandSelector v-model="schemaInput.compatible_brand" />
            </td>
          </tr>
          <tr>
            <td>Uyumlu Model</td>
            <td>
              <form-kit
                v-model="schemaInput.compatible_model"
                placeholder="Uyumlu modelin ismi..."
                type="text"
              />
            </td>
          </tr>
        </table>
      </section>
      <div class="grid grid-cols-2 gap-4">
        <div class="w-full">
          <h3>Detaylar</h3>
          <FormKit
            v-model="schemaInput.description"
            type="textarea"
            placeholder="Bu alana yazılan metin, sipariş sayfasında görünür."
            help="Bu alana yazılan metin, sipariş sayfasında görünür."
            rows="5"
          />
        </div>
        <div class="w-full">
          <h3>Özel Bilgi</h3>
          <FormKit
            v-model="schemaInput.attention"
            input-class="bg-red-50"
            type="textarea"
            placeholder="Bu alan sipariş sayfasında görünmez."
            help="Bu alan sipariş sayfasında görünmez."
            rows="5"
          />
        </div>
      </div>
      <!--        INFO SECTION END-->
      <div class="flex items-center justify-end mt-4">
        <button class="success" @click="saveSchema">
          <font-awesome-icon icon="floppy-disk" />
          Şemayı Oluştur
        </button>
        <button
          class="success"
          @click="() => $emit('update:modelValue', false)"
        >
          <font-awesome-icon icon="floppy-disk" />
          İptal
        </button>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SchemaDimension from "@/components/misc/custom_fields/SchemaDimension.vue";
import BrandSelector from "../form/BrandSelector.vue";

export default {
  name: "CreateSchemaModal",
  components: {
    FontAwesomeIcon,
    VueFinalModal,
    BrandSelector,
  },
  emits: ["update:modelValue", "SchemaCreated"],
  data() {
    return {
      schemaInput: {
        name: null,
        compatible_brand: null,
        compatible_model: null,
      },
    };
  },
  methods: {
    saveSchema() {
      this.axios
        .post(`guide`, {
          ...this.schemaInput,
        })
        .then((r) => {
          this.$emit("SchemaCreated", r.data.data);
          this.$emit("update:modelValue", false);
          this.$router.push({
            name: "schema",
            params: {
              schemaId: r.data.data.id,
            },
          });
        });
    },
    getBrands() {
      this.axios.get(`guide/brands`).then((r) => {
        this.brands = this.brandsSelectFormat(r.data.data);
      });
    },
  },
};
</script>

<style scoped>
.formkit-outer {
  @apply !mb-0 !p-0;
}
table tr td {
  @apply pb-2;
}
table tr td:first-child {
  @apply align-middle text-right pr-4 h-full w-1/4;
}
table tr td:nth-child(2) {
  @apply w-3/4;
}
</style>
