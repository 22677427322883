<script setup>
import { ref } from "vue";
import TagSpan from "@/components/misc/TagSpan.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const checkList = ref([]);

function checkedIndex(hex_code) {
  return checkList.value.findIndex((i) => i.hex_code === hex_code);
}
function isChecked(hex_code) {
  return checkedIndex(hex_code) !== -1;
}

function addToChecklist(image) {
  if (checkedIndex(image.hex_code) === -1) checkList.value.push(image);
}

function removeFromChecklist(image) {
  const index = checkedIndex(image.hex_code);
  if (index > -1) checkList.value.splice(index, 1);
}

function toggleCheckList(image) {
  const index = checkedIndex(image.hex_code);
  if (index > -1) checkList.value.splice(index, 1);
  else checkList.value.push(image);
}

const scrollContainer = ref(null);
function scrollX(e) {
  scrollContainer.value.scrollLeft += e.deltaY;
}

function resetList() {
  checkList.value = [];
}

const emit = defineEmits(["submit"]);

defineExpose({
  toggleCheckList,
  isChecked,
  removeFromChecklist,
  addToChecklist,
  resetList,
});
</script>

<template>
  <div
    v-if="checkList.length > 0"
    ref="scrollContainer"
    class="fixed left-0 bottom-0 p-3 z-10 w-screen select-none flex gap-3 overflow-x-auto no-scrollbar snap-x scroll-px-3 scroll-smooth bg-sky-950"
    @mousewheel.prevent="scrollX"
  >
    <div class="w-32 flex flex-col justify-between gap-3">
      <button class="info sm w-full flex-1" @click="emit('submit', checkList)">
        <FontAwesomeIcon icon="repeat" />
        Değiştir
      </button>
      <button class="gray sm w-full flex-1" @click="resetList">
        <FontAwesomeIcon icon="xmark" />
        Sıfırla
      </button>
    </div>
    <div class="flex gap-3">
      <div
        v-for="(checked, index) in checkList"
        :key="index"
        class="rounded-md overflow-hidden group relative float-left shrink-0 snap-start"
        @click="removeFromChecklist(checked)"
      >
        <div
          class="absolute opacity-0 w-full h-full bg-red-900 bg-opacity-80 group-hover:opacity-100 transition-all flex items-center justify-center cursor-pointer"
        >
          <font-awesome-icon
            icon="circle-xmark"
            size="2xl"
            class="text-red-300 bg-red-800 rounded-full relative"
          />
        </div>
        <TagSpan
          class="absolute top-1 right-1 bg-sky-600 font-semibold text-white group-hover:bg-red-600"
          >{{ checked.count }}</TagSpan
        >
        <img
          class="h-24 aspect-[3/2] object-cover object-center"
          :src="checked.image.url_170x135"
        />
      </div>
    </div>
  </div>
</template>
