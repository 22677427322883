import { createApp } from "vue";
import { createPinia } from "pinia";
import "./style/tailwind.scss";
import "./style/style.scss";
import boot from "@/boot";
import ToastPlugin from "vue-toast-notification";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "vue-final-modal/style.css";
library.add(fab, fas, far);
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import axios, { initAxios } from "@/lib/AxiosClient";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import VueAxios from "vue-axios";
import { routes } from "./routes.js";
import { useAuthStore } from "@/stores/auth.js";
import { useSettingsStore } from "@/stores/settings.js";
import { createVfm } from "vue-final-modal";
import { plugin, defaultConfig } from "@formkit/vue";
import formkitConfig from "../formkit.config.js";
import * as Sentry from "@sentry/vue";

import VueSweetalert2 from "vue-sweetalert2";
import VueBarcodeScanner from "vue3-barcode-scanner";

import emitter from "./emitter.js";

const vfm = createVfm();
const env = import.meta.env;
export const app = createApp(App);
const pinia = createPinia();

const router = createRouter({
  history: createWebHistory(),
  routes,
});
const auth = useAuthStore(pinia);
const toast = useToast();
initAxios({
  auth,
  router,
  toast,
});
// PUSHER SETUP

window.Echo = new Echo({
  broadcaster: "pusher",
  key: env.VITE_PUSHER_APP_KEY,
  cluster: env.VITE_PUSHER_APP_CLUSTER,
  encrypted: true,
  authEndpoint: `${env.VITE_DOMAIN}/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: `Bearer ${auth.isAuthenticated ? auth.getToken : null}`,
    },
  },
});
window.Echo.connector.pusher.connection.bind("connected", function () {
  axios.defaults.headers.common["X-Socket-ID"] = window.Echo.socketId();
});
// PUSHER SETUP END
app.use(VueAxios, axios);
app.use(plugin, defaultConfig(formkitConfig));
app.use(VueSweetalert2);
app.use(pinia);
app.use(VueBarcodeScanner);
app.use(router);
app.use(ToastPlugin, { duration: 800, position: "top-right" });
app.use(vfm);
app.component("FontAwesomeIcon", FontAwesomeIcon);

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !auth.isAuthenticated)
    return next({ name: "Login" });
  if (to.meta.onlyGuest && auth.isAuthenticated) return next({ path: "/" });
  if (to.meta.requiredPermissions) {
    for (let i = 0; i < to.meta.requiredPermissions.length; i++) {
      const need = to.meta.requiredPermissions;
      // console.log(need, auth.canAnyOf(need));
      if (!auth.canAnyOf(need)) next({ path: "/" });
      break;
    }
  }
  // console.log(to)
  document.title = to.meta.title ? to.meta.title : "OrderBase";
  next();
});
router.afterEach((to, from, next) => {
  to.state = history.state;
  if (to.state.title) {
    document.title = to.state.title;
  }
});

console.log("MODE: " + env.MODE);
if (!env.DEV) {
  Sentry.init({
    app,
    environment: env.MODE,
    dsn: "https://01adbf9a91d38c8c2eb5d022636dac54@o4506174776999936.ingest.sentry.io/4506224489267200",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled

        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.mount("#app");

app.config.globalProperties.$auth = auth;
app.config.globalProperties.$emitter = emitter;
const settings = useSettingsStore(pinia);
app.config.globalProperties.$assetUrl = env.VITE_ASSETS_URL;

window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});

//DEV
if (import.meta.hot) {
  import.meta.hot.on("vite:beforeUpdate", () => console.clear());
}
//DEV END
