<script setup>
import MainHeader from "@/components/MainHeader.vue";
import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import axiosClient from "@/lib/AxiosClient";
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import EtsyImage from "@/pages/etsy/EtsyImage.vue";
import TagSpan from "@/components/misc/TagSpan.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import EtsyImageSelector from "./EtsyImageSelector.vue";
import EtsyImageValidateModal from "./EtsyImageValidateModal.vue";
import { useModal } from "vue-final-modal";
import dayjs from "dayjs";
import { diffForHumans } from "@/lib/Helpers";
import { computed } from "vue";
import EtsyShopCard from "./EtsyShopCard.vue";

const route = useRoute();
const images = ref([]);
function getImages() {
  axiosClient
    .get(`/etsy/shop/${route.params.etsyShopId}/list-images`)
    .then((r) => {
      images.value = r.data.results;
    });
}
const validateModal = useModal({
  component: EtsyImageValidateModal,
});

function openValidationModal(selectedImages) {
  validateModal.patchOptions({
    attrs: {
      selectedImages,
    },
  });
  validateModal.open();
}

const ImageSelector = ref(null);

const shop = ref(null);
function getShop() {
  axiosClient.get(`/etsy/shop/${route.params.etsyShopId}`).then((r) => {
    shop.value = r.data;
  });
}

onMounted(() => {
  getShop();
  getImages();
});
</script>

<template>
  <MainHeader />
  <EtsyImageSelector ref="ImageSelector" @submit="openValidationModal" />
  <div class="container mx-auto pb-40">
    <EtsyShopCard v-if="shop" :shop="shop" class="mt-4" no-dropdown>
      <template #right="{ startJob }">
        <div class="flex flex-col">
          <button
            class="info whitespace-nowrap"
            @click="startJob('cache_listings')"
          >
            <FontAwesomeIcon icon="refresh" />
            Ürünleri Güncelle
          </button>
        </div>
      </template>
      <template #description="{ isOld, timeDiff }">
        <p>
          Görseller en son
          <span :class="{ 'text-red-500 font-bold': isOld }">
            {{ timeDiff }}
          </span>
          güncellendi.
        </p>
      </template>
    </EtsyShopCard>
    <div
      class="grid grid-cols-3 md:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-8 gap-4 mt-4"
    >
      <EtsyImage
        v-for="image in images"
        :key="image.hex_code"
        :src="image.image.url_170x135"
        :is-checked="ImageSelector.isChecked(image.hex_code)"
        :count="image.count"
        @toggle="ImageSelector.toggleCheckList(image)"
      />
    </div>
  </div>
</template>

<style scoped></style>
