<script>
export default {
  name: "TagSpan",
  props: {
    background: String,
    createdAt: String,
  },
  methods: {
    hex_to_rgb(hex) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    },
    hex_inverse_bw(hex) {
      let rgb = this.hex_to_rgb(hex);
      if (!rgb) {
        console.log(hex);
        return "#cccccc";
      }
      let luminance = 0.2126 * rgb["r"] + 0.7152 * rgb["g"] + 0.0722 * rgb["b"];
      return luminance < 140 ? "#ffffff" : "#000000";
    },
  },
};
</script>

<template>
  <div
    class="tag cursor-pointer bg-gray-400 inline select-none"
    :style="{
      'background-color': background,
      color: background ? hex_inverse_bw(background) : false,
    }"
    :title="
      createdAt ? new Date(createdAt).toLocaleString() : 'Bilgi bulunamadı!'
    "
  >
    <slot name="default"></slot>
  </div>
</template>

<style scoped>
.tag {
  @apply px-2 py-0.5 rounded-full w-min h-min whitespace-nowrap;
}
</style>
