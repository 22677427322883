<script>
import { defineComponent } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default defineComponent({
  name: "OrderStatus",
  components: { FontAwesomeIcon },
  props: ["status"],
  computed: {
    orderStatus() {
      const states = {
        finished: {
          name: "Gönderildi",
          icon: "circle-check",
          class: { success: true },
        },
        ready: {
          name: "Kontrol Edildi",
          icon: "circle-check",
          class: { ready: true },
        },
        cancelled: {
          name: "İptal Edildi",
          icon: "circle-xmark",
          class: { danger: true },
        },
        on_hold: {
          name: "Beklemede",
          icon: "clock",
          class: { warning: true },
        },
        processing: {
          name: "Yapılacak",
          // icon: "scissors",
          class: { info: true },
        },
      };
      return states[this.status];
    },
  },
});
</script>

<template>
  <span
    :class="orderStatus.class"
    class="flex items-center gap-1 cursor-pointer"
  >
    <font-awesome-icon :icon="orderStatus.icon" v-if="orderStatus.icon" />
    {{ orderStatus.name }}
  </span>
</template>
<style lang="scss" scoped>
span {
  & {
    @apply px-2 py-0.5 rounded-full w-min h-min whitespace-nowrap;
  }
  &.success {
    @apply text-lime-700 bg-lime-200;
  }
  &.danger {
    @apply text-red-700 bg-red-200;
  }
  &.info {
    @apply text-sky-700 bg-sky-200;
  }
  &.warning {
    @apply text-amber-700 bg-amber-200;
  }
  &.ready {
    @apply text-yellow-700 bg-yellow-200;
  }
}
</style>
