<template>
  <section
    v-if="props.snapshots?.length > 0"
    id="snapshots"
    class="order-1 top-0 p-4"
  >
    <h3>Son Gönderilen Ürünler</h3>
    <table>
      <thead>
        <tr>
          <th>Gönderi</th>
          <!--                  <th>Tarih</th>-->
          <th>Ölçü</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="snapshot in props.snapshots" :key="snapshot.id">
          <td class="cursor-pointer" @click="openOrderModal(snapshot.order.id)">
            <span class="date">
              {{ dayjs(snapshot.created_at).format("DD/MM/YY") }}
            </span>
            <span class="orderID"> #{{ snapshot?.order?.id }} </span>
            <img :src="snapshot?.order?.images[0]?.src" alt="" />
          </td>
          <td class="space-y-4">
            <SchemaDimension
              v-for="(dimension, i) in snapshot?.guide_data.dimensions"
              :key="i"
              class="w-full"
              :editable="false"
              :dimension="dimension"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </section>
  <h3 v-else class="text-center mt-4">Geçmiş Sipariş Bulunamadı!</h3>
</template>
<script setup>
import SchemaDimension from "@/components/misc/custom_fields/SchemaDimension.vue";
import OrderModal from "@/components/modals/OrderModal.vue";
import dayjs from "dayjs";
import { useModal } from "vue-final-modal";
const openOrderModal = (orderId) => {
  useModal({
    component: OrderModal,
    attrs: {
      orderId,
    },
  }).open();
};
const props = defineProps({
  snapshots: {
    type: Array,
    required: true,
  },
});
</script>

<style lang="scss" scoped>
table {
  tr {
    td:first-child {
      @apply w-1/3 text-right bg-white relative;
    }
    td:first-child span.date {
      @apply absolute right-2 top-2 bg-gray-600 rounded-full py-0.5 px-2 text-sm text-gray-100 text-center;
    }
    td:first-child span.orderID {
      @apply absolute left-2 top-2 bg-sky-600 rounded-full py-0.5 px-2 text-sm text-sky-100 text-center font-bold;
    }
    td {
      @apply border py-2 px-4;
    }
  }
}
</style>
