<template>
  <div
    class="flex flex-col items-center justify-start py-4 gap-4 relative overflow-hidden h-screen"
  >
    <div class="w-full flex flex-col gap-2">
      <h2>Ürün Arama</h2>
      <div class="flex">
        <span class="flex gap-2">
          <input
            v-model="hideGuides"
            :value="settings.getSetting('hideGuidedListings')"
            type="checkbox"
            @change="
              (e) => {
                getListings();
                settings.setSetting('hideGuidedListings', e.target.checked);
              }
            "
          />
          <label>Eşleşenleri Gizle</label>
        </span>
      </div>
      <input
        v-model="keyword"
        type="text"
        class="border rounded w-full text-center text-2xl p-2"
        placeholder="Link, Başlık veya SKU ile ara"
        @input="searchHandler"
      />
    </div>
    <Loading class="h-20" :is-loading="loading" no-bg />
    <div
      v-if="!loading"
      class="flex flex-col gap-2 overflow-y-auto pb-[60px] scrollbar-thin"
      @scroll.passive="handleScroll"
    >
      <div
        v-for="(listing, index) in listings"
        :key="listing.listing_id"
        class="flex w-full gap-4 p-2 bg-white border rounded-md group"
      >
        <div class="flex flex-col gap-2">
          <a :href="listing.url" target="_blank" rel="noopener noreferrer">
            <EtsyImage :src="listing.images[0]['url_570xN']" class="!w-52" />
          </a>
          <span
            v-if="listing.guides.length > 0"
            class="text-xs text-gray-2 bg-gray-200 px-2 py-1 rounded-md"
            >Bağlı Şema Sayısı: {{ listing.guides.length }}</span
          >
          <div class="flex invisible group-hover:visible">
            <button class="info sm" @click="selectListing(listing, index)">
              <FontAwesomeIcon icon="link" /> Eşleştir
            </button>
          </div>
        </div>

        <div class="flex flex-col flex-1">
          <a :href="listing.url" target="_blank" rel="noopener noreferrer">
            <p class="font-bold">{{ decode(listing.title) }}</p>
          </a>
          <ul class="text-sm flex flex-col gap-1 mt-2">
            <li>
              <div class="flex gap-2">
                <span
                  class="bg-etsy-500 aspect-square h-5 items-center justify-center p-1 rounded text-white flex"
                >
                  <FontAwesomeIcon icon="fa-brands fa-etsy" size="sm" />
                </span>
                {{ listing.shop.shop_name }}
              </div>
            </li>
            <li v-if="listing.skus.length > 0">
              SKU: <b>{{ listing.skus[0] }}</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <p v-if="!loading && listings.length === 0">Sonuç bulunamadı!</p>
  </div>
</template>

<script setup>
import axiosClient from "@/lib/AxiosClient";
import { debounce } from "lodash";
import { onMounted, ref, watch } from "vue";
import EtsyImage from "./EtsyImage.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { decode } from "he";
import Loading from "@/components/misc/Loading.vue";
import { useSettingsStore } from "@/stores/settings";
import { getEtsyListingID } from "@/lib/Helpers";

const emit = defineEmits(["listingSelected"]);

const props = defineProps({
  exclude: {
    type: Array,
    required: false,
    default: () => [],
  },
  defaultKeyword: {
    type: String,
    required: false,
    default: null,
  },
});
const settings = useSettingsStore();
const listings = ref([]);
const loading = ref(false);
const scrollLoading = ref(false);
const nextPage = ref(false);
const page = ref(1);
const keyword = ref("");
const hideGuides = ref(false);

function selectListing(listing, index) {
  emit("listingSelected", listing);
  listings.value.splice(index, 1);
}

watch(
  () => props.exclude,
  (newValue) => {
    if (listings.value.length > 0) getListings(false);
  }
);

const searchHandler = debounce(getListings, 750);

onMounted(() => {
  if (props.defaultKeyword) {
    keyword.value = props.defaultKeyword;
    getListings();
  }
});

async function handleScroll(e) {
  const leftToBottom =
    e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight;
  if (leftToBottom < 301 && !scrollLoading.value) {
    if (nextPage.value) {
      page.value = page.value + 1;
      scrollLoading.value = true;
      await getListings(false, true);
      scrollLoading.value = false;
    }
  }
}

async function getListings(load = true, append = false) {
  if (load) loading.value = true;

  const urlCheck = getEtsyListingID(keyword.value);
  if (urlCheck) {
    keyword.value = urlCheck;
  }

  if (keyword.value.length === 0) {
    loading.value = false;
    listings.value = [];
    return;
  }

  let params = {
    keyword: keyword.value,
    exclude: props.exclude,
    page: page.value,
  };

  if (hideGuides.value) {
    params.hasGuides = false;
  }

  const { data } = await axiosClient.get("/etsy/listing", {
    params,
  });
  nextPage.value = data.meta.last_page > page.value;
  loading.value = false;
  if (!append) {
    listings.value = data.data;
  } else {
    listings.value.push(...data.data);
  }
}
</script>
