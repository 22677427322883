<template>
  <div ref="dropzoneRef" class="__image-viewer">
    <VueEasyLightbox
      :visible="showLightbox"
      :imgs="localImages"
      :index="activeImageIndex"
      :zoom-scale="1"
      :max-zoom="5"
      @hide="() => (showLightbox = false)"
    />
    <div v-if="uploadProgress && !disabled" class="__image-upload-progress">
      <ProgressBar :progress="uploadProgress" name="Görsel Yükleniyor" />
    </div>

    <div
      v-else-if="
        (localImages.length === 0 || (isOverDropZone && !sortDragging)) &&
        !disabled
      "
      class="__image-uploader"
    >
      <div class="">
        <FontAwesomeIcon
          icon="cloud-arrow-up"
          size="xl"
          class="animate-bounce"
        />
        <p class="text-center">Görsel yüklemek için buraya sürükle.</p>
        <div class="flex items-center justify-center">
          <input
            ref="selectToUpload"
            type="file"
            class="hidden"
            accept="image/png, image/jpeg, image/webp"
            @input="(e) => handleDragToUpload([...e.target.files])"
          />
          <button
            class="info !rounded-full !h-12 aspect-square bg-sky-400 text-white flex items-center justify-center"
            title="Görsel Yükle"
            @click="openFileDialog"
          >
            <FontAwesomeIcon icon="plus" />
          </button>
        </div>
        <ul class="text-sm text-red-400">
          <li v-for="(error, index) in imageErrors" :key="index">
            {{ error }}
          </li>
        </ul>
      </div>
    </div>
    <div
      v-else-if="localImages.length > 0"
      class="__image-main"
      @click="showLightbox = true"
    >
      <img :src="localImages[activeImageIndex].src" alt="" />
    </div>
    <draggable
      v-if="localImages.length > 0"
      v-model="draggableImages"
      class="__image-browser no-scrollbar"
      item-key="index"
      :move="(e) => !e.draggedContext.element.button && !props.disabled"
      @start="sortDragging = true"
      @end="sortDragging = false"
      @update="handleImageSort"
    >
      <template #item="{ element, index }">
        <div
          class="group __image-browser-item"
          :class="{ active: activeImageIndex === index }"
        >
          <div
            v-if="element.button"
            class="flex items-center justify-center h-full bg-sky-100 rounded border text-sky-900 cursor-pointer hover:text-sky-100 hover:bg-sky-900 transition-all"
            title="Görsel Yükle"
            @click="openFileDialog"
          >
            <input
              ref="selectToUpload"
              type="file"
              class="hidden"
              accept="image/png, image/jpeg, image/webp"
              @input="(e) => handleDragToUpload([...e.target.files])"
            />
            <FontAwesomeIcon icon="plus" />
          </div>
          <template v-else>
            <button
              v-if="!props.disabled"
              title="Görseli sil!"
              class="__remove-button"
              @click="removeImage(index)"
            >
              <FontAwesomeIcon icon="xmark" />
            </button>
            <img
              :src="element.src"
              :class="{ active: activeImageIndex === index }"
              alt=""
              @click="activeImageIndex = index"
            />
          </template>
        </div>
      </template>
    </draggable>
  </div>
</template>
<script setup>
import draggable from "vuedraggable";
import VueEasyLightbox from "vue-easy-lightbox";

import { onMounted } from "vue";
import { ref } from "vue";
import axiosClient from "@/lib/AxiosClient";
import ProgressBar from "./ProgressBar.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";
import { useDropZone } from "@vueuse/core";

const activeImageIndex = ref(0);
const showLightbox = ref(false);
const sortDragging = ref(false);
// const inDropzone = ref(false);
const dropzoneRef = ref();
const { isOverDropZone } = useDropZone(dropzoneRef, handleDragToUpload);
const uploadProgress = ref(null);
const localImages = ref([]);
const selectToUpload = ref(null);
const imageErrors = ref([]);
const draggableImages = computed({
  get: () => {
    return props.disabled
      ? localImages.value
      : [...localImages.value, { button: true }];
  },
  set: (newValue) => {
    localImages.value = newValue.filter((item) => !item.button);
  },
});

const props = defineProps({
  images: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  uploaderMode: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: () => "full",
  },
  resource: {
    type: String,
    required: false,
    default: null,
  },
  resourceId: {
    type: Number,
    required: false,
    default: null,
  },
});

onMounted(() => {
  localImages.value = props.images?.length > 0 ? [...props.images] : [];
});

const emit = defineEmits([
  "imageRemoved",
  "imageUploaded",
  "imagesSorted",
  "update:images",
]);
function openFileDialog() {
  selectToUpload.value.click();
}

function uploadProgressEventHandler(evt) {
  uploadProgress.value = evt.progress;
}

function handleDragToUpload(files) {
  if (sortDragging.value || props.disabled) return;
  // inDropzone.value = false;
  if (!files || files.length === 0) return;
  files = files.filter((f) => {
    const check = f.type.split("/")[0] == "image";
    if (!check)
      imageErrors.value = ["Yüklediğiniz dosya görüntü formatında olmalıdır."];
    return check;
  });
  if (props.uploaderMode) {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        localImages.value.push({
          src: e.target.result,
          file,
        });
      };
    });
    emit("update:images", localImages.value);
  } else {
    uploadImage(files);
  }
}

function uploadImage(files) {
  if (props.disabled) return;
  uploadProgress.value = 0;
  const form = new FormData();
  files.forEach((file) => {
    form.append("images[]", file);
  });
  axiosClient({
    method: "post",
    url: `/image/${props.resource}/${props.resourceId}/upload`,
    data: form,
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: uploadProgressEventHandler,
  })
    .then((r) => {
      localImages.value = r.data;
      emit("imageUploaded");
      emit("update:images", localImages.value);
    })
    .finally(() => {
      uploadProgress.value = null;
    });
  return;
}

function handleImageSort(evt) {
  if (props.disabled) return;
  if (props.uploaderMode) {
    const temp = localImages.value[evt.oldIndex];
    localImages.value.splice(evt.oldIndex, 1);
    localImages.value = [
      ...localImages.value.slice(0, evt.oldIndex),
      temp,
      ...localImages.value.slice(evt.oldIndex),
    ];
    emit("update:images", localImages.value);
    return;
  }
  axiosClient
    .put(`/image/${props.resource}/${props.resourceId}}/sort`, {
      from: evt.oldIndex,
      to: evt.newIndex,
    })
    .then((r) => {
      localImages.value = r.data;
      emit("imagesSorted");
      emit("update:images", localImages.value);
    });
}

function removeImage(index) {
  if (props.disabled) return;
  if (props.uploaderMode) {
    localImages.value.splice(index, 1);
    emit("update:images", localImages.value);
    return;
  }
  axiosClient
    .delete(`/image/${props.resource}/${props.resourceId}/delete/${index}`)
    .then((r) => {
      if (
        r.data.length === 0 ||
        index === activeImageIndex.value ||
        index > r.data.length - 1
      ) {
        activeImageIndex.value = 0;
      }
      localImages.value = r.data;
      emit("imageRemoved");
      emit("update:images", localImages.value);
    });
}
</script>

<style lang="scss" scoped>
.__image-viewer {
  @apply flex flex-col gap-2;
}
.__image-viewer {
  &.compact .__image-main img {
    @apply h-auto max-h-[55vh];
  }
  .__image-main {
    img {
      @apply object-contain w-full h-[55vh] rounded-md border bg-white;
    }
    @apply w-full;
  }
  .__image-uploader {
    .fa-xl {
      @apply h-[4rem] text-gray-300 #{!important};
    }

    & > div {
      @apply flex flex-col items-center justify-center gap-4 w-full h-full border-dashed border-2 p-8 rounded-md;
    }
    @apply border rounded-md p-3 bg-white;
  }
  .__image-browser {
    .__image-browser-item {
      .__remove-button {
        &:hover {
          @apply bg-red-900 text-red-500;
        }
        @apply h-5 w-5 absolute right-2 top-2 z-20 bg-red-500 text-red-900 rounded-md flex items-center justify-center transition-all invisible;
      }
      &.active img {
        @apply outline outline-sky-600 rounded;
      }
      &:hover {
        .__remove-button {
          @apply -translate-y-1 visible  #{!important};
        }
      }
      img {
        @apply object-cover w-full h-full z-10 border rounded cursor-pointer group-hover:brightness-75 group-hover:-translate-y-1 transition-all bg-white snap-center;
      }
      @apply w-full aspect-[3/2] relative;
    }
    & {
      @apply grid grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-2;
    }
  }
}
</style>
