<template>
  <div
    class="border rounded-md bg-white overflow-hidden flex flex-col"
  >
    <div class="w-full h-52">
      <img
        class="object-cover h-52 w-full"
        :src="`http://localhost/storage/products/${$props.product.image1}`"
        alt=""
      >
    </div>
    <div
      class="font-bold inline-block px-2 w-full whitespace-nowrap overflow-hidden overflow-ellipsis"
      :title="htmlDecode($props.product.name)"
      v-html="$props.product.name"
    />
    <ul class="px-2 list-disc list-inside text-sm">
      <li
        v-for="listing in $props.product.etsy_listings"
        :key="listing.id"
      >
        <a
          :href="`https://www.etsy.com/listing/${listing.etsy_listing_id}`"
          :title="listing.listing_updated_at"
        >{{ listing.etsy_shop.shop_name }}</a>
      </li>
    </ul>
    <div class="grid grid-cols-2 p-2">
      <span>
        {{ $props.product.price }} {{ $props.product.currency }}
      </span>
      <button class="rounded warning">
        Düzenle
      </button>
    </div>
  </div>
</template>

<script>
import { decode } from 'he';
export default {
  name: "ProductCard",
  props: {
    product:{
      type: Object,
    }
  },
  methods: {
    htmlDecode(text){
      return decode(text)
    },
  }
}
</script>

<style scoped>

</style>