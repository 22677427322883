<template>
  <vSelect
    placeholder="Uyumlu ürünün markası..."
    class="bg-white"
    :options="brands"
    taggable
  />
</template>

<script setup>
import "vue-select/dist/vue-select.css";
import axiosClient from "@/lib/AxiosClient";
import { ref } from "vue";
import { onMounted } from "vue";
import vSelect from "vue-select";

const brands = ref([]);
const getBrands = async () => {
  const response = await axiosClient.get(`guide/brands`);
  brands.value = brandsSelectFormat(response.data.data);
};

const brandsSelectFormat = (brands) => {
  return Object.keys(brands)
    .filter((k) => brands[k])
    .map((k) => brands[k]);
};
onMounted(() => {
  getBrands();
});
</script>
