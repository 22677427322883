<script>
import TagSpan from "@/components/misc/TagSpan.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { de } from "@formkit/i18n";

export default {
  name: "TagSettingsTab",
  components: { FontAwesomeIcon, TagSpan },
  data() {
    return {
      tags: [],
      tagInput: {
        id: null,
        name: null,
        color: "#cccccc",
      },
    };
  },
  mounted() {
    this.setRandomColor();
    this.getTags();
  },
  methods: {
    setRandomColor() {
      this.tagInput.color =
        "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0");
    },
    createTag(submitted) {
      this.axios.post("tag", submitted).then((r) => {
        this.resetForm();
        this.tags.unshift(r.data.data);
      });
    },
    updateTag(submitted) {
      this.axios.put(`tag/${submitted.id}`, submitted).then((r) => {
        this.resetForm();
        const index = this.tags.findIndex((t) => t.id === submitted.id);
        this.tags[index] = r.data.data;
      });
    },
    deleteTag(tagId) {
      this.axios.delete(`tag/${tagId}`).then((r) => {
        this.resetForm();
        const index = this.tags.findIndex((t) => t.id === tagId);
        this.tags.splice(index, 1);
      });
    },
    handleSubmit(submitted) {
      if (submitted.id) {
        this.updateTag(submitted);
      } else {
        this.createTag(submitted);
      }
    },
    getTags() {
      this.axios.get("tag").then((r) => (this.tags = r.data.data));
    },
    resetForm() {
      this.$formkit.get("tagForm").reset();
      this.setRandomColor();
    },
    editTag(tag) {
      this.tagInput.id = tag.id;
      this.tagInput.name = tag.name;
      this.tagInput.color = tag.color;
    },
  },
};
</script>

<template>
  <div class="w-full flex gap-4">
    <div class="w-1/3 flex flex-col gap-4">
      <h1 v-if="!tagInput.id" class="text-xl font-bold">Etiket Oluştur</h1>
      <div v-else class="flex justify-between items-center">
        <h1 class="text-xl font-bold">Etiket'i Güncelle #{{ tagInput.id }}</h1>
        <font-awesome-icon icon="xmark" class="cursor-pointer" @click="resetForm"
          ><h1 class="text-xl font-bold">
            Etiket'i Güncelle
          </h1></font-awesome-icon
        >
      </div>
      <TagSpan :background="tagInput.color">{{
        tagInput?.name?.length > 0 ? tagInput.name : "Örnek Etiket"
      }}</TagSpan>
      <hr />
      <FormKit
        id="tagForm"
        v-model="tagInput"
        type="form"
        :actions="false"
        @submit="handleSubmit"
      >
        <FormKit type="hidden" name="id" :value="null"></FormKit>
        <FormKit
          type="text"
          validation-label="Etiket İsmi"
          validation="required"
          label="Etiket İsmi"
          autocomplete="off"
          name="name"
        ></FormKit>
        <FormKit
          type="color"
          outer-class="!w-24"
          name="color"
          label="Renk"
          :value="null"
        ></FormKit>

        <div class="flex !text-sm gap-2">
          <FormKit v-if="!tagInput.id" type="submit" input-class="md "
            >Oluştur</FormKit
          >
          <FormKit
            v-if="!tagInput.id"
            type="button"
            input-class="md gray"
            @click="resetForm()"
            >Sıfırla</FormKit
          >
        </div>
        <div class="flex !text-sm gap-2">
          <FormKit v-if="tagInput.id" type="submit" input-class="!success md">
            <font-awesome-icon
              icon="floppy-disk"
              class="mr-2"
            ></font-awesome-icon>
            Güncelle
          </FormKit>
          <FormKit
            v-if="tagInput.id"
            type="button"
            input-class="!danger md"
            @click="deleteTag(tagInput.id)"
          >
            <font-awesome-icon icon="trash" class="mr-2"></font-awesome-icon>
            Sil
          </FormKit>
        </div>
      </FormKit>
    </div>
    <div class="w-3/4 flex flex-wrap gap-2 bg-gray-200 rounded-md p-4">
      <TagSpan
        v-for="(tag, index) in tags"
        :key="index"
        :background="tag.color"
        @click="editTag(tag)"
        >{{ tag.name }}</TagSpan
      >
      <div
        v-if="tags.length === 0"
        class="w-full h-full flex items-center justify-center"
      >
        Hiç etiket bulunamadı.
      </div>
    </div>
  </div>
</template>

<style scoped></style>
