<template>
  <ul class="pagination">
    <li>
      <button
        :disabled="prevButton"
        @click="changePage(pagination.current_page - 1)"
      >
        <font-awesome-icon icon="fas fa-caret-left" />
      </button>
    </li>
    <li
      v-for="page in pages"
      :key="page"
      :class="{active: pagination.current_page == page }"
      @click="changePage(page)"
    >
      <button class="text-sky-500">
        {{ page }}
      </button>
    </li>
    <li>
      <button
        :disabled="nextButton"
        @click="changePage(pagination.current_page + 1)"
      >
        <font-awesome-icon icon="fas fa-caret-right" />
      </button>
    </li>
  </ul>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "Pagination",
  components: {FontAwesomeIcon},
  props: ['data'],
  emits: ['pageChanged'],
  data(){
    return {
      pagination: this.$props.data
    }
  },
  computed:{
    // pages(){
    //   let labels = []
    //   if (this.pagination.last_page < 9){
    //     for (const lastPageKey in this.pagination.last_page) {
    //       labels.push(lastPageKey)
    //     }
    //   } else if (this.pagination.current_page !== this.pagination.last_page){
    //     for (const labelsKey in labels) {
    //
    //     }
    //   }
    // },
    pages() {
      let numShown = 4;
      numShown = Math.min(numShown, this.pagination.last_page);
      let first = this.pagination.current_page - Math.floor(numShown / 2);
      first = Math.max(first, 1);
      first = Math.min(first, this.pagination.last_page - numShown + 1);
      return [...Array(numShown)].map((k,i) => i + first);
    },
    prevButton(){
      return this.pagination.current_page == null || this.pagination.current_page == 1;
    },
    nextButton(){
      return this.pagination.current_page == this.pagination.last_page;
    }
  },
  methods: {
    changePage(page){
      this.$emit('pageChanged', page);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/variables";
.pagination{
  @apply flex flex-row justify-center items-center my-4 gap-3 #{!important}
}
.pagination li button{
  @apply px-3 transition-colors py-1 border bg-white hover:bg-sky-300 text-sky-950 hover:text-white font-bold border-none #{$default-rounded} #{!important}
}
.pagination li button:hover{
  @apply border-none
}
.pagination li.active button{
  @apply bg-sky-950 border-none text-white #{!important}
}
.pagination li button[disabled], button:disabled{
  @apply opacity-50 cursor-not-allowed #{!important}
}
</style>