// formkit.config.js
import { generateClasses } from "@formkit/themes";
import { genesisIcons } from "@formkit/icons";
import theme from "./formkit.theme.js";
import { tr } from "@formkit/i18n";

export default {
  config: {
    classes: generateClasses(theme),
  },
  icons: {
    ...genesisIcons,
  },
  locales: { tr },
  locale: "tr",
};
