<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "IncludeExcludeBox",
  components: { FontAwesomeIcon },
  props: {
    modelValue: Object,
    options: Array,
    disabled: Boolean,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      localValue: {
        in: [],
        ex: [],
      },
    };
  },
  watch: {
    modelValue: function (val) {
      this.localValue = val;
    },
  },
  mounted() {
    this.localValue = this.modelValue;
  },
  methods: {
    isIncluded(id) {
      return this.localValue.in.find((i) => i === id);
    },
    isExcluded(id) {
      return this.localValue.ex.find((e) => e === id);
    },
    toggle(id) {
      if (this.disabled) return false;
      if (this.isIncluded(id)) {
        const inIndex = this.localValue.in.findIndex((i) => i === id);
        this.localValue.in.splice(inIndex, 1);
        this.localValue.ex.push(id);
      } else if (this.isExcluded(id)) {
        const inIndex = this.localValue.ex.findIndex((i) => i === id);
        this.localValue.ex.splice(inIndex, 1);
      } else {
        this.localValue.in.push(id);
      }
      this.$emit("update:modelValue", this.localValue);
    },
  },
};
</script>

<template>
  <fieldset
    class="grid grid-cols-2 mb-4 p-2 rounded border gap-1.5 !border-gray-400"
    :class="{ 'opacity-50': disabled }"
  >
    <legend class="font-bold text-sm">Etiketler</legend>
    <div
      v-for="(option, index) in options"
      :key="index"
      class="flex items-center gap-1.5"
    >
      <div
        class="aspect-square h-5 bg-white rounded-md text-gray-900 flex items-center justify-center cursor-pointer"
        @click.stop="toggle(option.id)"
      >
        <font-awesome-icon
          v-if="isIncluded(option.id)"
          icon="check"
        ></font-awesome-icon>
        <font-awesome-icon
          v-if="isExcluded(option.id)"
          icon="xmark"
        ></font-awesome-icon>
      </div>
      <span
        class="text-sm whitespace-nowrap"
        :class="{
          'line-through': isExcluded(option.id),
        }"
        >{{ option.name }}</span
      >
    </div>
  </fieldset>
</template>

<style scoped></style>
