<template>
  <div
    class="absolute -top-2 left-2 bg-sky-500  border-sky-50 px-3 rounded-full font-bold text-sky-50 text-xs uppercase"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "InputLabel",
}
</script>

<style scoped>

</style>