import { useSettingsStore } from "@/stores/settings";
import { inject } from "vue";
import { useRouter } from "vue-router";

export const useScanHandler = () => {
  const barcodeScanner = inject("barcodeScanner");
  const receiptIdRegex = `(\/receipts?\/(?<receiptId>[0-9]+))(\/order\/(?<orderId>[0-9]+))?`;
  const router = useRouter();

  const openReceipt = (receiptId, markAsShipped) => {
    router.push({
      name: "receipt",
      params: { receiptId },
      query: { markAsShipped },
    });
  };

  const openOrder = (receiptId, orderId, markAsShipped) => {
    router.push({
      name: "receipt",
      params: { receiptId },
      query: { markAsShipped, orderId },
    });
  };
  const settings = useSettingsStore();
  const barcodeScanAction = settings.getSetting("barcodeScanAction");
  barcodeScanner.init((e) => {
    const receiptId = e.match(receiptIdRegex)?.groups?.receiptId;
    const orderId = e.match(receiptIdRegex)?.groups?.orderId;
    if (!receiptId) return;
    switch (barcodeScanAction) {
      case "openReceipt":
        openReceipt(receiptId);
        break;
      case "openOrder":
        openOrder(receiptId, orderId);
        break;
      case "openOrderAndShip":
        openOrder(receiptId, orderId, true);
        break;
      case "openReceiptAndShip":
        openReceipt(receiptId, true);
        break;
    }
  });

  return {
    barcodeScanner,
  };
};
