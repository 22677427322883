<template>
  <DropdownButton no-icon class="info sm aspect-square" title="Ölçüyü Kopyala">
    <font-awesome-icon icon="copy" />
    <template #dropdown>
      <li>
        <button @click="copyDimension(true)">Metin İle Kopyala</button>
      </li>
      <li>
        <button @click="copyDimension(false)">Yalnız Ölçüyü Kopyala</button>
      </li>
    </template>
  </DropdownButton>
</template>

<script setup>
import Schema from "@/lib/Schema";
import DropdownButton from "./DropdownButton.vue";
import axiosClient from "@/lib/AxiosClient";
import { useToast } from "vue-toast-notification";

const props = defineProps({
  schema: {
    type: Object,
    default: null,
  },
});

const toast = useToast();

function copyDimension(withTemplate = true) {
  if (props.schema instanceof Schema) {
    if (props.schema.dimensions.length > 0) {
      if (withTemplate) props.schema.copyModelText(0);
      else props.schema.dimensions[0].copyDimensions();
    }
  } else {
    axiosClient.get("guide/" + props.schema.id).then((r) => {
      const schema = new Schema(r.data);
      if (schema.dimensions.length > 0) {
        if (withTemplate) schema.copyModelText(0);
        else schema.dimensions[0].copyDimensions();
      }
    });
  }

  toast.success("Ölçü Mesajı Kopyalandı!");
}
</script>
