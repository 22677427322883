import Home from "@/views/Home.vue";
import NotFound from "@/views/NotFound.vue";
import Login from "@/views/Login.vue";
import Products from "@/views/Products.vue";
import SchemasPage from "@/pages/SchemasPage.vue";
import EtsyShops from "@/pages/etsy/EtsyShopsPage.vue";
import EtsyShopOrders from "@/pages/etsy/EtsyShopOrders.vue";
import ReceiptPage from "@/pages/ReceiptPage.vue";
import Receipts from "@/views/Receipts.vue";
import SettingsPage from "@/pages/settings/SettingsPage.vue";
import TagSettingsTab from "@/pages/settings/TagSettingsTab.vue";
import UserSettingsTab from "@/pages/settings/UserSettingsTab.vue";
import PermissionSettingsTab from "@/pages/settings/PermissionSettingsTab.vue";
import MainSettingsTab from "@/pages/settings/MainSettingsTab.vue";
import GlobalSettingsTab from "@/pages/settings/GlobalSettingsTab.vue";
import EtsyImagesPage from "@/pages/etsy/EtsyImagesPage.vue";
import SchemaEditPage from "./pages/schema/SchemaEditPage.vue";
import EtsyListingsPage from "./pages/etsy/EtsyListingsPage.vue";
import EtsyListingWizard from "./pages/etsy/EtsyListingWizard.vue";

/** @type {import('vue-router').RouterOptions['routes']} */
export const routes = [
  { path: "/", component: Home, meta: { title: "Home", requiresAuth: true } },
  {
    path: "/login",
    component: Login,
    name: "Login",
    meta: { title: "Login", onlyGuest: true },
  },
  {
    name: "products",
    path: "/products",
    component: Products,
    meta: { title: "Ürünler", requiresAuth: true },
  },
  {
    name: "receipts",
    path: "/receipts",
    component: Receipts,
    meta: { title: "Siparişler", requiresAuth: true },
    children: [
      {
        name: "order",
        path: "/:orderId",
        component: Receipts,
        meta: { showOrderModal: true },
      },
    ],
  },
  {
    name: "schemas",
    path: "/schemas",
    component: SchemasPage,
    meta: { title: "Şemalar", requiredPermissions: ["schema"] },
    // children: [
    //   {
    //     name: "schema",
    //     path: ":schemaId",
    //     component: SchemasPage,
    //     meta: { showSchemaModal: true },
    //   },
    // ],
  },
  {
    name: "etsy_shops",
    path: "/etsy/shops",
    component: EtsyShops,
    meta: { title: "Etsy Shops", requiredPermissions: ["shop"] },
  },
  {
    name: "etsy_shop_orders",
    path: "/etsy/shop/:etsyShopId/orders",
    component: EtsyShopOrders,
    meta: { title: "Etsy Siparişleri", requiredPermissions: ["shop"] },
  },
  {
    name: "etsy_shop_images",
    path: "/etsy/shop/:etsyShopId/images",
    component: EtsyImagesPage,
    meta: { title: "Etsy Görselleri", requiredPermissions: ["shop"] },
  },
  {
    name: "etsy_shop_listings",
    path: "/etsy/shop/:etsyShopId/listings",
    component: EtsyListingsPage,
    meta: { title: "Etsy Listeleri", requiredPermissions: ["shop"] },
  },
  {
    name: "etsy_listing_wizard",
    path: "/etsy/wizard",
    component: EtsyListingWizard,
    meta: { title: "Etsy Liste Aracı", requiredPermissions: ["shop"] },
  },
  {
    name: "settings",
    path: "/settings",
    component: SettingsPage,
    meta: { title: "Ayarlar" },
    children: [
      {
        name: "personal",
        path: "personal",
        component: SettingsPage,
        meta: { tab: MainSettingsTab },
      },
      {
        name: "global",
        path: "global",
        component: SettingsPage,
        meta: { tab: GlobalSettingsTab },
      },
      {
        name: "tags",
        path: "tags",
        component: SettingsPage,
        meta: { tab: TagSettingsTab, requiredPermissions: ["settings.tag"] },
      },
      {
        name: "users",
        path: "users",
        component: SettingsPage,
        meta: { tab: UserSettingsTab, requiredPermissions: ["settings.user"] },
      },
      {
        name: "permissions",
        path: "permissions",
        component: SettingsPage,
        meta: {
          tab: PermissionSettingsTab,
          requiredPermissions: ["settings.permission"],
        },
      },
    ],
  },
  {
    name: "receipt",
    path: "/receipt/:receiptId",
    component: ReceiptPage,
    meta: { title: "Sipariş", requiredPermissions: ["order"] },
  },
  {
    name: "schema",
    path: "/schema/:schemaId",
    component: SchemaEditPage,
    meta: { title: "Şema Düzenle", requiredPermissions: ["schema"] },
  },

  { path: "/:path(.*)", component: NotFound },
];
