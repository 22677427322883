<template>
  <VueFinalModal modal-id="addOrderModal">
    <div class="modal-body w-[80vw] lg:w-[50vw] p-4 rounded-md">
      <div class="flex flex-col items-center justify-center gap-4">
        <ImageViewer v-model:images="images" uploader-mode class="w-full" />
        <FormKit
          type="form"
          submit-label="Add"
          :actions="false"
          @submit="addOrder"
        >
          <FormKit
            type="number"
            name="quantity"
            label="Adet"
            :value="1"
            validation="required"
          />
          <FormKit type="submit" />
        </FormKit>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { VueFinalModal, useVfm } from "vue-final-modal";
import ImageViewer from "../misc/ImageViewer.vue";
import { ref } from "vue";
import axiosClient from "@/lib/AxiosClient";
import { useRoute } from "vue-router";
import emitter from "@/emitter";

const images = ref([]);
const route = useRoute();
const vfm = useVfm();

const addOrder = (formData) => {
  const form = new FormData();
  images.value.forEach((i) => {
    form.append("images[]", i.file);
  });
  form.append("quantity", formData.quantity);
  form.append("receipt_id", route.params.receiptId);
  form.append("status", "PROCESSING");
  axiosClient({
    method: "post",
    url: `/order`,
    data: form,
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: () => {
      console.log(1);
    },
  }).then(() => {
    emitter.emit("receipt.updated", route.params.receiptId);
    vfm.close("addOrderModal");
  });
};
</script>
