<script setup>
//Grid Order
// Etiket Göster
// Sipariş Durumu Göster

// List Orders
// Satır Başına İki Sipariş Göster
// Görevli Göster
// Ürün Başlığını Göster
import { useSettingsStore } from "@/stores/settings";
import { useToast } from "vue-toast-notification";
import { reset } from "@formkit/core";
import { onMounted, ref } from "vue";
import { cloneDeep } from "lodash";
import axiosClient from "@/lib/AxiosClient";

const settings = useSettingsStore();

function setSetting(value, e) {
  console.log(value);
  settings.setSetting(e.name, value);
}

const toast = useToast();

function kioskLogin(value) {
  axiosClient
    .post("/me/kiosk-login", { deviceId: value.deviceId })
    .then((r) => {
      reset("kiosk-form");
      toast.success("Giriş izni gönderildi.");
    });
}
</script>

<template>
  <div class="columns-3 personal">
    <div class="settingGroup">
      <h2>Genel</h2>
      <ul class="flex flex-col gap-2">
        <li>
          <form-kit
            type="checkbox"
            name="receiptsListView"
            :value="settings.getSetting('receiptsListView')"
            @input="setSetting"
          />Siparişleri Liste Halinde Göster
        </li>
      </ul>
    </div>
    <div class="settingGroup">
      <h2>Ürün Listesi</h2>
      <ul class="flex flex-col gap-2">
        <li>
          <form-kit
            type="checkbox"
            name="listOrders.showTags"
            :value="settings.getSetting('listOrders.showTags')"
            @input="setSetting"
          />Etiketleri Göster
        </li>
        <li>
          <form-kit
            type="checkbox"
            name="listOrders.showStatus"
            :value="settings.getSetting('listOrders.showStatus')"
            @input="setSetting"
          />Sipariş Durumunu Göster
        </li>
        <li>
          <form-kit
            type="checkbox"
            name="listOrders.showUsers"
            :value="settings.getSetting('listOrders.showUsers')"
            @input="setSetting"
          />Görevlileri Göster
        </li>
        <li>
          <form-kit
            type="checkbox"
            name="listOrders.showTitle"
            :value="settings.getSetting('listOrders.showTitle')"
            @input="setSetting"
          />Başlığı Göster
        </li>
        <li>
          <form-kit
            type="checkbox"
            name="listOrders.splitRow"
            :value="settings.getSetting('listOrders.splitRow')"
            @input="setSetting"
          />Bir Satırda 2 Ürün Göster
        </li>
      </ul>
    </div>
    <div class="settingGroup">
      <h2>Ürün Grid</h2>
      <ul class="flex flex-col gap-2">
        <li>
          <form-kit
            type="checkbox"
            name="gridOrders.showTags"
            :value="settings.getSetting('gridOrders.showTags')"
            @input="setSetting"
          />Etiketleri Göster
        </li>
        <li>
          <form-kit
            type="checkbox"
            name="gridOrders.showStatus"
            :value="settings.getSetting('gridOrders.showStatus')"
            @input="setSetting"
          />Sipariş Durumunu Göster
        </li>
        <li>
          <form-kit
            type="checkbox"
            name="gridOrders.showUsers"
            :value="settings.getSetting('gridOrders.showUsers')"
            @input="setSetting"
          />Görevlileri Göster
        </li>
      </ul>
    </div>
    <div class="settingGroup">
      <h2>KIOSK Monitör Girişi</h2>
      <form-kit id="kiosk-form" type="form" @submit="kioskLogin">
        <form-kit
          type="text"
          name="deviceId"
          required
          placeholder="Cihaz numarasını girin."
        />
      </form-kit>
    </div>
    <div class="settingGroup">
      <h2>Barkod Okuyucu</h2>
      <form-kit
        type="select"
        name="barcodeScanAction"
        :options="[
          { label: 'Siparişi Aç', value: 'openReceipt' },
          { label: 'Ürünü Aç', value: 'openOrder' },
          {
            label: 'Ürünü Aç ve Siparişi Gönderildi Olarak Güncelle',
            value: 'openOrderAndShip',
          },
          {
            label: 'Siparişi Aç ve Gönderildi Olarak Güncelle',
            value: 'openReceiptAndShip',
          },
        ]"
        :value="settings.getSetting('barcodeScanAction')"
        @input="setSetting"
      />
    </div>
  </div>
</template>

<style scoped>
ul li {
  @apply flex gap-2 items-center h-min;
}
.personal .formkit-outer {
  @apply !mb-0;
}

.settingGroup {
  @apply flex flex-col break-inside-avoid mb-4;
}
</style>
