<script>
import { defineComponent } from "vue";
import Loading from "@/components/misc/Loading.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default defineComponent({
  name: "PermissionSettingsTab",
  components: { FontAwesomeIcon, Loading },
  data() {
    return {
      permissionGroups: [],
      roles: [],
      roleForm: { name: "" },
      rolesLoading: false,
      loading: false,
      activeRole: null,
    };
  },
  mounted() {
    this.getRoles();
    this.getPermissions();
  },
  methods: {
    getPermissions() {
      this.axios.get("/permission").then((r) => {
        this.allPermissions = r.data;
        this.allPermissions.forEach((v) => {
          const groupName = v.name.split(" ")[1].split(".")[0];
          const groupIndex = this.permissionGroups.findIndex(
            (g) => g.name === groupName,
          );
          if (groupIndex === -1) {
            this.permissionGroups.push({
              name: groupName,
              permissions: [v],
            });
          } else {
            this.permissionGroups[groupIndex].permissions.push(v);
          }
        });
      });
    },
    getRoles() {
      this.rolesLoading = true;
      return this.axios.get("/role").then((r) => {
        this.roles = r.data;
        this.rolesLoading = false;
        this.selectRole(r.data[0]);
      });
    },
    createRole() {
      this.axios.post("/role", this.roleForm).then((r) => {
        this.roles.push(r.data);
      });
    },
    selectRole(role) {
      this.loading = true;
      this.axios.get(`/role/${role.id}`).then((r) => {
        this.activeRole = r.data;
        this.loading = false;
      });
    },
    handlePermission(e) {
      let action = this.activeRole?.permissions.find(
        (p) => p.id.toString() === e.target.value,
      )
        ? "remove"
        : "assign";

      this.axios
        .put(
          `/role/${this.activeRole.id}/permission/${e.target.value}/${action}`,
        )
        .then((r) => {
          this.activeRole = r.data;
        });
    },
    hasPermission(permissionName) {
      return this.activeRole?.permissions.find(
        (p) => p.name === permissionName,
      );
    },
  },
});
</script>

<template>
  <div class="flex divide-x gap-4">
    <div class="w-[35%] flex flex-col">
      <FormKit
        v-model="roleForm"
        :actions="false"
        type="form"
        form-class="flex w-full"
        @submit="createRole"
      >
        <FormKit
          type="text"
          name="name"
          :classes="{ inner: 'h-full rounded-r-none', outer: 'w-full' }"
        />
        <button
          type="submit"
          class="!h-[40px] aspect-square !rounded-l-none block info ring-1"
        >
          <font-awesome-icon icon="plus" />
        </button>
      </FormKit>
      <div
        v-for="role in roles"
        :key="role.id"
        class="w-full px-4 py-2 flex items-center gap-2 justify-start cursor-pointer hover:bg-gray-300 flex justify-between group"
        :class="{ 'bg-gray-200': activeRole?.id === role.id }"
        @click="selectRole(role)"
      >
        {{ role.name }}
      </div>
    </div>
    <div class="flex-1 px-4">
      <Loading :is-loading="loading" no-bg></Loading>

      <div v-if="!loading" class="columns-3 gap-4">
        <div
          v-for="(group, index) in permissionGroups"
          :key="index"
          class="mb-4 break-inside-avoid"
        >
          <h3 class="font-bold font uppercase">{{ group.name }}</h3>
          <ul>
            <li
              v-for="(permission, j) in group.permissions"
              :key="j"
              class="capitalize"
            >
              <span>
                <input
                  type="checkbox"
                  :value="permission.id"
                  :checked="hasPermission(permission.name)"
                  @input="handlePermission"
                />
                {{ permission.name }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
