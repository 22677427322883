<template>
  <div class="w-full lg:columns-3 gap-4 pt-4">
    <!--    <template v-if="loading">-->
    <!--      <ReceiptListItemPlaceholder v-for="i in 10" :key="i" />-->
    <!--    </template>-->
    <template v-if="!loading">
      <ReceiptCardItem
        v-for="receipt in receipts"
        :key="receipt.id"
        :receipt="receipt"
        :on-modal-open="onModalOpen"
      />
    </template>
    <p v-if="receipts.length === 0 && !loading" class="text-center">
      Bu özelliklere sahip sipariş bulunamadı.
    </p>
  </div>
</template>

<script>
import ReceiptListItem from "@/components/ReceiptListItem.vue";
import ReceiptListItemPlaceholder from "@/components/placeholders/ReceiptListItemPlaceholder.vue";
import ReceiptCardItem from "@/components/ReceiptCardItem.vue";
export default {
  name: "ReceiptsList",
  components: { ReceiptCardItem, ReceiptListItemPlaceholder },
  props: {
    receipts: {
      type: Object,
    },
    onModalOpen: {
      type: Function,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
.vfm__content {
  width: fit-content;
  margin: 0 auto;
  @apply lg:mb-4;
}
.vfm {
  overflow-y: scroll;
}
.vfm__overlay {
  position: fixed;
}
</style>
