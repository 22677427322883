<script>
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { cloneDeep } from "lodash";
import ConfirmButton from "@/components/form/ConfirmButton.vue";
import Loading from "@/components/misc/Loading.vue";

export default {
  name: "UserSettingsTab",
  components: {
    Loading,
    ConfirmButton,
    FontAwesomeIcon,
    EasyDataTable: Vue3EasyDataTable,
  },
  setup() {
    const headers = [
      { text: "#", value: "id", sortable: true },
      { text: "İsim", value: "name", sortable: true },
      { text: "E-Mail", value: "email" },
      { text: "Roller", value: "roles" },
    ];
    return {
      headers,
    };
  },
  data() {
    return {
      roles: [],
      users: [],
      activeUser: {
        name: "",
        email: "",
        id: null,
        roles: [],
        permissions: [],
      },
    };
  },
  mounted() {
    this.getUsers();
    this.getRoles();
  },
  methods: {
    async getUsers() {
      const { data } = await this.axios.get("/users");
      this.users = data.data;
    },
    toggleUserRole(roleName) {
      if (this.activeUser.roles.includes(roleName)) {
        const roleIndex = this.activeUser.roles.indexOf((r) => r === roleName);
        this.activeUser.roles.splice(roleIndex, 1);
      } else {
        this.activeUser.roles.push(roleName);
      }
    },
    getRoles() {
      this.axios.get("/role").then((r) => {
        this.roles = r.data;
      });
    },
    createUser(formData) {
      this.axios.post("/users", this.activeUser).then((r) => {
        this.getUsers();
      });
    },
    updateUser(formData) {
      this.axios
        .put(`/users/${this.activeUser.id}`, this.activeUser)
        .then((r) => {
          this.getUsers();
          this.$toast.success("Kullanıcı güncellendi.");
        });
    },
    deleteUser(userID) {
      this.axios.delete(`/users/${userID}`).then((r) => {
        this.$formkit.reset("user-form");
        this.getUsers();
      });
    },
    editUser(user) {
      this.activeUser = cloneDeep(user);
    },
    isActiveUser(user) {
      return user.id === this.activeUser.id ? "active__user" : "";
    },
  },
};
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="grid grid-cols-3 gap-8">
      <div class="flex flex-col">
        <h1 class="font-bold text-xl mb-2">
          Kullanıcı {{ activeUser?.id ? "Güncelle" : "Oluştur" }}
        </h1>
        <FormKit
          id="user-form"
          v-model="activeUser"
          type="form"
          :actions="false"
          form-class="w-full"
          @submit="
            (e) => {
              activeUser.id ? updateUser(e) : createUser(e);
            }
          "
        >
          <FormKit label="İsim" name="name" type="text" />
          <FormKit
            inner-class="!w-full"
            label="E-Mail"
            name="email"
            type="text"
          />
          <FormKit label="Parola" name="password" type="password" />
          <div class="flex gap-4">
            <FormKit
              v-if="activeUser.id"
              type="submit"
              label="Güncelle"
              input-class="!info md"
            />
            <FormKit
              v-else
              type="submit"
              label="Yeni Kullanıcı Oluştur"
              input-class="!success md"
            />
            <button
              type="button"
              class="gray md"
              @click="$formkit.reset('user-form')"
            >
              <FontAwesomeIcon icon="fa-close" />
              {{ activeUser.id ? "Sıfırla" : "İptal" }}
            </button>
            <ConfirmButton
              v-if="activeUser.id"
              type="button"
              class="danger md"
              @confirmed="deleteUser(activeUser.id)"
            >
              <FontAwesomeIcon icon="fa-trash" />
              Sil
            </ConfirmButton>
          </div>
        </FormKit>
      </div>
      <div class="flex flex-col">
        <h1 class="font-bold text-xl mb-2">Kullanıcı Rolleri</h1>
        <div class="flex flex-col divide-y border rounded-md">
          <Loading :is-loading="rolesLoading" no-bg />
          <div
            v-for="role in roles"
            :key="role.id"
            class="w-full px-4 py-2 flex items-center gap-2 justify-start cursor-pointer"
            :class="{ 'bg-gray-100': activeUser.roles.includes(role.name) }"
            @click="toggleUserRole(role.name)"
          >
            <span class="w-5">
              <font-awesome-icon
                v-if="activeUser.roles.includes(role.name)"
                icon="check-square"
                size="lg"
              />
            </span>
            {{ role.name }}
          </div>
        </div>
      </div>
    </div>
    <EasyDataTable
      :headers="headers"
      :items="users"
      :body-row-class-name="isActiveUser"
      @clickRow="editUser"
    >
      <template #item-roles="{ roles }">
        <div class="flex flex-wrap gap-2">
          <span
            v-for="(role, index) in roles"
            :key="index"
            class="px-1 py-0.5 rounded-md font-semibold bg-gray-200"
          >
            {{ role }}
          </span>
        </div>
      </template>
    </EasyDataTable>
  </div>
</template>

<style lang="scss">
.vue3-easy-data-table__body tr.active__user td {
  @apply bg-red-300 #{!important};
}
</style>
