<template>
  <div
    class="w-full flex flex-col mt-4 lg:gap-8 gap-4 lg:w-5/6 lg:container mx-auto"
  >
    <template v-if="loading">
      <ReceiptListItemPlaceholder v-for="i in 10" :key="i" />
    </template>
    <template v-if="!loading">
      <ReceiptListItem
        v-for="receipt in receipts"
        :key="receipt.id"
        :receipt="receipt"
        :on-modal-open="onModalOpen"
      />
    </template>
    <p v-if="receipts.length === 0 && !loading" class="text-center">
      Bu özelliklere sahip sipariş bulunamadı.
    </p>
  </div>
</template>

<script>
import ReceiptListItem from "@/components/ReceiptListItem.vue";
import ReceiptListItemPlaceholder from "@/components/placeholders/ReceiptListItemPlaceholder.vue";
export default {
  name: "ReceiptsList",
  components: { ReceiptListItemPlaceholder, ReceiptListItem },
  props: {
    receipts: {
      type: Object,
    },
    onModalOpen: {
      type: Function,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
.vfm__content {
  width: fit-content;
  margin: 0 auto;
  @apply lg:mb-4;
}
.vfm {
  overflow-y: scroll;
}
.vfm__overlay {
  position: fixed;
}
</style>
