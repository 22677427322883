<script>
import { defineComponent } from "vue";
import OrderStatus from "@/components/misc/OrderStatus.vue";
import { decode } from "he";
import { useModal } from "vue-final-modal";
import OrderModal from "@/components/modals/OrderModal.vue";
import { currencySymbol } from "../lib/Helpers.js";
import InlineTags from "@/components/misc/InlineTags.vue";
import OrderNotes from "@/components/misc/OrderNotes.vue";
import UserBadge from "@/components/misc/UserBadge.vue";
import { useAuthStore } from "@/stores/auth";
import { useSettingsStore } from "@/stores/settings";

export default defineComponent({
  name: "OrderListItem",
  components: { UserBadge, OrderNotes, InlineTags, OrderStatus },
  props: [
    "order",
    "receipt",
    "showPrice",
    "onModalOpen",
    "notes",
    "showTitle",
    "small",
  ],
  setup() {
    const auth = useAuthStore();
    const settings = useSettingsStore();
    return {
      decode,
      auth,
      settings,
    };
  },
  data() {
    return {
      orderModalInstance: null,
    };
  },
  methods: {
    currencySymbol,
    openOrderModal() {
      if (!this.onModalOpen) {
        const _this = this;
        useModal({
          component: OrderModal,
          attrs: {
            orderId: this.order.id,
          },
        }).open();
      } else {
        this.onModalOpen(this.order.id);
      }
    },
    listingThumbnail(src) {
      if (src.search("il_fullxfull"))
        return src.replace("il_fullxfull", "il_170x135");
      return;
    },
    thumbnail(image) {
      if (image) return `${this.$assetUrl}/products/${image}`;
      else return "/images/placeholder.png";
    },
    showTags(tag) {
      const invalid = ["finished", "cancelled"];
      return !invalid.find((t) => t === tag);
    },
  },
});
</script>

<template>
  <div class="flex flex-col">
    <div
      class="flex flex-row hover:bg-gray-200 p-2 gap-4 lg:p-4 transition-colors cursor-pointer"
      @click="openOrderModal"
    >
      <img
        v-if="(order.images && order.images.length > 0) || order.product"
        :src="
          order.images && order.images.length > 0
            ? listingThumbnail(order.images[0].src)
            : thumbnail(order.product.image1)
        "
        alt=""
        class="w-40 h-32 object-cover rounded"
      />
      <div
        v-else
        class="w-40 h-32 bg-gray-300 rounded flex items-center justify-center text-gray-900"
      >
        <FontAwesomeIcon class="fa-xl" icon="image" />
      </div>

      <div class="flex flex-col">
        <div class="flex gap-4">
          <div class="flex text-xs mb-2 gap-2 items-center flex-wrap">
            <span
              class="text-gray-700 bg-gray-300 px-2 py-0.5 rounded-full w-min h-min whitespace-nowrap"
              >#{{ order.id }}</span
            >
            <OrderStatus :status="order.status" />
            <InlineTags :tags="order.tags"></InlineTags>
            <div class="flex gap-2 flex-wrap">
              <UserBadge
                v-for="user in order.users"
                :key="user.id"
                class="pl-0.5 pr-1.5"
                :user="user"
              />
            </div>
          </div>
          <div
            v-if="showPrice && auth.can('view order.price')"
            class="lg:hidden"
          >
            {{ currencySymbol(order.currency) }}{{ order.price }}
          </div>
        </div>
        <div
          v-if="showTitle || settings.getSetting('listOrders.showTitle')"
          class="text-lg font-semibold pb-4"
        >
          {{
            order.product
              ? decode(order.product.name)
              : decode(order.listing_title)
          }}
        </div>
        <div
          v-if="
            order.product &&
            order.product.guides &&
            order.product.guides.length > 0
          "
          class="mb-4"
        >
          <span v-for="guide in order.product.guides" :key="guide.id">
            {{ guide.compatible_brand }}</span
          >
        </div>
        <div class="flex flex-col">
          <div class="text-sm">
            Adet: <span class="font-bold">{{ order.quantity }}</span>
          </div>
          <div v-if="order.customer_note" class="text-sm">Müşteri Notu Var</div>
          <div
            v-for="option in order.options"
            :key="option.property_id"
            class="text-sm"
          >
            {{ option.formatted_name ? option.formatted_name : option.name }}:
            <span class="font-bold">{{
              option.formatted_value
                ? decode(option.formatted_value)
                : decode(option.value)
            }}</span>
          </div>
        </div>
      </div>
      <div
        v-if="showPrice && auth.can('view order.price')"
        class="lg:block hidden flex-1 text-right"
      >
        {{ currencySymbol(receipt.currency) }}{{ order.price }}
      </div>
    </div>
    <div v-if="notes" :class="{ 'p-4': order.notes.length > 0 }">
      <OrderNotes
        v-model:notes="order.notes"
        hide-if-empty
        :order-id="order.id"
        :submitable="false"
        class="border-none !bg-gray-100"
      ></OrderNotes>
    </div>
  </div>
</template>

<style scoped></style>
