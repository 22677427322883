<template>
  <MainHeader />
  <div class="flex justify-start items-start">
    <SearchMenu
      ref="searchMenuComponent"
      v-model="extra"
      endpoint="receipt"
      :extra-sort-options="[
        {
          label: 'Öncelikli Kargo',
          value: {
            by: 'shipping_date',
            order: 'ASC',
          },
        },
      ]"
      :auto-search-fields="['tags', 'status', 'omo', 'shop']"
      @reset="
        (e) => {
          extra = custom();
        }
      "
      @loading="(args) => (loading = args)"
      @loaded="
        (response) => {
          receipts = response.data;
        }
      "
    >
      <template #before>
        <div class="flex mb-4 gap-2 font-bold">
          <button
            type="button"
            :class="{ info: extra.onlyMe, default: !extra.onlyMe }"
            @click="
              () => {
                extra.onlyMe = true;
                $refs.searchMenuComponent.getResources();
              }
            "
          >
            <font-awesome-icon icon="user" />
            Görevlerim
          </button>
          <button
            type="button"
            :class="{ info: !extra.onlyMe, default: extra.onlyMe }"
            @click="
              () => {
                extra.onlyMe = false;
                $refs.searchMenuComponent.getResources();
              }
            "
          >
            Tüm Siparişler
          </button>
        </div>
      </template>
      <IncludeExcludeBox
        v-if="tags.length > 0"
        v-model="extra.tags"
        :options="tags"
        :disabled="$refs?.searchMenuComponent?.loading"
      />
      <FormKit
        id="status"
        name="status"
        label="Sipariş Durumu"
        label-class="!text-white"
        type="checkbox"
        :value="['processing']"
        :options="[
          {
            label: 'Gönderilenler',
            value: 'finished',
          },
          {
            label: 'Kontrol Edilenler',
            value: 'ready',
          },
          {
            label: 'Yapılacaklar',
            value: 'processing',
          },
          {
            label: 'İptal Edilenler',
            value: 'cancelled',
          },
        ]"
      />
      <form-kit
        id="omo"
        name="omo"
        :on-value="true"
        :off-value="false"
        type="checkbox"
        label-class="!text-white"
        label="Sadece Tam Eşleşen Siparişler"
      />
      <form-kit
        id="shop"
        name="shop"
        label="Kanal"
        type="select"
        :options="shop_filters"
      />
    </SearchMenu>
    <div class="flex flex-col items-center w-full lg:w-3/4 mx-4 lg:p-0 mb-8">
      <div
        v-if="extra.onlyMe && receipts.length === 0 && !loading"
        class="mt-4 items-center"
      >
        <span class="text-xl font-bold text-red-500">Görev Modundasınız</span>
      </div>
      <component
        :is="
          settings.getSetting('receiptsListView') ? ReceiptsList : ReceiptsCard
        "
        :receipts="receipts"
        :loading="loading"
        :on-modal-open="openOrderModal"
      />
      <button
        v-if="extra.onlyMe && receipts.length === 0 && !loading"
        class="info w-min whitespace-nowrap mt-4 font-bold"
        @click="
          () => {
            extra.onlyMe = false;
            $refs.searchMenuComponent.getResources();
          }
        "
      >
        Tüm Siparişleri Gör
      </button>
    </div>
  </div>
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import OrderModal from "@/components/modals/OrderModal.vue";
import { useModal } from "vue-final-modal";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SearchMenu from "@/components/misc/SearchMenu.vue";
import IncludeExcludeBox from "@/components/form/IncludeExcludeBox.vue";
import ReceiptsCard from "@/components/ReceiptsCard.vue";
import ReceiptsList from "@/components/ReceiptsList.vue";
import { useSettingsStore } from "@/stores/settings";

export default {
  name: "Receipts",
  components: {
    IncludeExcludeBox,
    ReceiptsList,
    FontAwesomeIcon,
    MainHeader,
    SearchMenu,
  },
  setup() {
    const settings = useSettingsStore();
    const custom = () => {
      return {
        onlyMe: false,
        tags: {
          in: [],
          ex: [],
        },
      };
    };
    return {
      settings,
      custom,
    };
  },
  data() {
    return {
      modal: null,
      loading: false,
      tags: [],
      extra: this.custom(),
      receipts: [],
      pagination: {
        next_cursor: null,
        current_page: null,
        last_page: null,
        per_page: 25,
        links: null,
      },
      showSearch: false,
      sort: {
        sort_by: this.$route.query.sort_by || "id",
        sort_order: this.$route.query.sort_order || "DESC",
      },
      lastSearch: null,
      search: {
        search: "",
        order_id: null,
        status: [],
        omo: false, // Only Matching Orders
      },
      shop_filters: [],
      selected_shop: null,
    };
  },
  computed: {
    ReceiptsList() {
      return ReceiptsList;
    },
    ReceiptsCard() {
      return ReceiptsCard;
    },
    totalShown() {
      return this.pagination.per_page < this.pagination.total
        ? this.pagination.per_page
        : this.pagination.total;
    },
    searchParameters() {
      return {
        search: this.search.search,
        page: this.pagination.current_page,
        per_page: this.pagination.per_page,
        order_id: this.search.order_id,
        sort_by: this.sort.sort_by,
        sort_order: this.sort.sort_order,
        status:
          this.search.status.length > 0 ? this.search.status.join(",") : null,
        shop: this.selected_shop,
        omo: this.search.omo ? 1 : null,
      };
    },
  },
  mounted() {
    // EVENT HANDLERS
    window.Echo.private("Receipts")
      .listen("ReceiptUpdated", (event) => {
        this.$refs.searchMenuComponent.handleUpdateEvent(event.data.receipt_id);
      })
      .listen("ReceiptDeleted", (e) => this.removeReceipt(e.data.receipt_id))
      .listen("ReceiptCreated", (e) =>
        this.$refs.searchMenuComponent.getResources()
      )
      .listen("OrderUpdated", (event) => {
        this.$refs.searchMenuComponent.handleUpdateEvent(event.data.receipt_id);
      });
    // END EVENT HANDLERS
    window.addEventListener("popstate", () => {
      if (this.$route.name === "order") {
        this.openOrderModal(this.$route.params.orderId);
      } else if (this.$route.name !== "order") {
        this.$emitter.emit("closeOrderModal");
      }
    });

    if (this.$route.name === "order") {
      this.openOrderModal(this.$route.params.orderId);
    }
    this.getTags();
    this.getShopsFilter();
  },
  methods: {
    getTags() {
      this.axios.get("tag").then((r) => (this.tags = r.data.data));
    },
    removeReceipt(receipt_id) {
      const index = this.receipts.findIndex((r) => r.id === receipt_id);
      if (index > -1) this.receipts.splice(index, 1);
    },
    openOrderModal(orderId) {
      const _this = this;
      if (this.modal) this.modal.close();
      this.modal = useModal({
        component: OrderModal,
        attrs: {
          orderId: orderId,
          onOpened() {
            _this.orderModalHandler(orderId);
          },
          onClosed() {
            _this.orderModalCloseHandler();
          },
        },
      });
      this.modal.open();
    },
    orderModalCloseHandler() {
      if (this.$route.name === "order")
        this.$router.push({
          name: "receipts",
          query: this.$route.query,
        });
    },
    orderModalHandler(orderId) {
      this.$router.push({
        name: "order",
        params: {
          orderId,
        },
        query: this.$route.query,
      });
    },
    getShopsFilter() {
      this.axios.get("/receipt/get-shops").then((r) => {
        this.shop_filters = [{ label: "Tümü", value: null }, ...r.data];
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
