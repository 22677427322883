<template>
  <VueFinalModal @update:model-value="(val) => emit('update:modelValue', val)">
    <div v-if="listing" class="modal-body w-[80vw] p-4">
      <div class="flex flex-col">
        <div class="flex gap-4">
          <label class="block mb-1 font-bold text-sm">Şema Bağlantıları</label>
          <button
            class="!py-0.5 text-xs font-bold info"
            @click="() => schemaModal.open()"
          >
            Yeni Şema Oluştur
          </button>
        </div>
        <MultipleSchemaAttacher
          :guides="listing.guides"
          class="mb-4"
          @update-schemas="handleSchemaUpdate"
        />
        <ImageViewer
          v-if="listing.images"
          disabled
          :images="
            listing.images.map((i) => ({
              src: i.url_fullxfull,
              type: 'remote',
            }))
          "
          class="mb-4 w-1/2 mx-auto compact"
        />
        <FormKit
          v-model="listingPayload"
          type="form"
          :actions="false"
          @submit="handleSubmit"
          @input="() => (isDirty = true)"
        >
          <FormKit label="Başlık" type="text" name="title" />
          <div class="formkit-label block text-sm mb-4">
            <span class="font-bold">SKU:</span>
            <span class="ml-2">{{
              listing.skus.length > 0 ? listing.skus[0] : "-"
            }}</span>
          </div>
          <FormKit
            label="Açıklama"
            type="textarea"
            name="description"
            :classes="{ input: 'h-[500px]' }"
          />
          <ModelSelect
            v-if="shopSections.length > 0"
            v-model="listingPayload.shop_section_id"
            name="shop_section_id"
            type="select"
            label="Bölüm"
            :options="shopSections"
            class="mb-4"
          />
          <div class="flex gap-4 justify-end">
            <a
              class="btn etsy font-bold !h-11"
              :href="`https://www.etsy.com/your/shops/me/listing-editor/edit/${listing.listing_id}`"
              target="_blank"
            >
              <FontAwesomeIcon size="lg" icon="fa-brands fa-etsy" /> Etsy'de
              Düzenle
            </a>
            <FormKit type="submit" :disabled="!isDirty">
              <FontAwesomeIcon icon="save" size="lg" class="mr-2" />
              Kaydet
            </FormKit>
            <FormKit
              type="button"
              :classes="{ input: 'danger' }"
              @click="() => emit('update:modelValue', false)"
            >
              <FontAwesomeIcon icon="times" size="lg" class="mr-2" />
              İptal</FormKit
            >
          </div>
        </FormKit>
      </div>
      <slot />
    </div>
  </VueFinalModal>
</template>

<script setup>
import axiosClient from "@/lib/AxiosClient";
import { onMounted } from "vue";
import { ref, computed } from "vue";
import { useModal, VueFinalModal } from "vue-final-modal";
import { decode } from "he";
import { useToast } from "vue-toast-notification";
import { ModelSelect } from "vue-search-select";
import { useCacheStore } from "@/stores/cache";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import MultipleSchemaAttacher from "@/components/form/MultipleSchemaAttacher.vue";
import CreateSchemaModal from "@/components/modals/CreateSchemaModal.vue";
import SchemaModal from "@/components/modals/SchemaModal.vue";
import ImageViewer from "@/components/misc/ImageViewer.vue";

const props = defineProps({
  listingId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["close", "update:modelValue", "listingUpdated"]);

const isDirty = ref(false);
const listing = ref(null);
const listingPayload = ref({});
function getListing() {
  axiosClient.get(`etsy/listing/${props.listingId}`).then((r) => {
    listing.value = r.data.data;
    listingPayload.value = {
      title: decode(listing.value.title),
      description: decode(listing.value.description),
      shop_section_id: listing.value.shop_section_id,
    };
  });
}
const toast = useToast();

onMounted(() => {
  getListing();
});

const shopSections = computed(() => {
  if (listing.value?.shop?.sections) {
    return listing.value.shop.sections.map((s) => ({
      text: s.title,
      value: s.shop_section_id,
    }));
  }
  return [];
});

function handleSubmit() {
  axiosClient
    .put(`etsy/listing/${props.listingId}`, listingPayload.value)
    .then((r) => {
      emit("update:modelValue", false);
      emit("listingUpdated", r.data.data);
      toast.open({
        message: "Listeleme başarıyla güncellendi.",
        type: "success",
      });
    });
}

function handleSchemaUpdate(schemas) {
  axiosClient
    .put(`etsy/listing/${props.listingId}/guides/sync`, {
      guideIds: schemas.map((s) => s.value),
    })
    .then((r) => {
      listing.value.guides = r.data;
      emit("listingUpdated", listing.value);
    });
}

function handleSchemaCreated(schema) {
  listing.value.guides.push(schema);
  handleSchemaUpdate(listing.value.guides.map((s) => ({ value: s.id })));
}

const schemaModal = useModal({
  component: SchemaModal,
  attrs: {
    onSchemaCreated: handleSchemaCreated,
  },
});
</script>
