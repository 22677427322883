<template>
  <button
    ref="dropdownButton"
    class="dropdown_button"
    @blur="handleBlur"
    @click.stop="show = true"
  >
    <slot name="default" />
    <font-awesome-icon v-if="!noIcon" icon="fas fa-caret-down" />
    <div
      v-if="show"
      ref="floating"
      class="dropdown_menu whitespace-nowrap"
      :style="floatingStyles"
    >
      <ul tabindex="-1">
        <slot name="dropdown" />
      </ul>
    </div>
  </button>
</template>

<script setup>
import { ref } from "vue";
import { useFloating, offset, autoPlacement } from "@floating-ui/vue";
function handleBlur(e) {
  // console.log(e);
  // if (dropdownButton.value.contains(e.relatedTarget)) {
  //   e.relatedTarget.click();
  // }
  setTimeout(() => (show.value = false), 150);
}
const props = defineProps({
  noIcon: Boolean,
  closeOnSelect: Boolean,
});
const show = ref(false);

const dropdownButton = ref(null);
const floating = ref(null);
const { floatingStyles } = useFloating(dropdownButton, floating, {
  middleware: [autoPlacement({ alignment: "start" }), offset(10)],
  placement: "bottom",
});

function closeDropdown() {
  dropdownButton.value.blur();
}

// export default {
//   name: "DropdownButton",
// props: {
//   noIcon: Boolean,
//   closeOnSelect: Boolean,
//   align: String,
// },
//   methods: {
// closeDropdown() {
//   this.$refs.dropdownButton.blur();
// },
//   },
// };
</script>

<style>
.dropdown_button {
  @apply relative flex items-center gap-2;
}
.dropdown_button > .dropdown_menu {
  @apply shadow rounded-md ring-1 ring-gray-200 bg-gray-950 text-gray-50 text-sm z-[55];
}

.dropdown_button > .dropdown_menu > ul > li > button {
  @apply w-full cursor-pointer hover:bg-gray-200 transition-all px-4 py-2 whitespace-nowrap text-left;
}
.dropdown_button > .dropdown_menu > ul > li > button:hover {
  @apply !bg-gray-800 rounded-md;
}
.dropdown_button > .dropdown_menu > ul > li svg {
  @apply mr-2;
}
</style>
