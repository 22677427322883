<template>
  <div class="bg-sky-950 fixed w-full h-full center text-white flex-col">
    <div class="mb-12">
      <Logo :credit="true" />
    </div>
    <ul class="text-red-500 list-disc mb-2 list-inside px-2">
      <li v-for="error in errors">
        {{ error[0] }}
      </li>
    </ul>
    <form-kit type="form" @submit="login">
      <form-kit
        v-model="email"
        type="email"
        validation="required|email"
        label="E-Mail"
        placeholder="E-Mail"
      />
      <form-kit
        v-model="password"
        type="password"
        label="Parola"
        validation="required"
        placeholder="Parola"
        @suffix-icon-click="handleIconClick"
      >
        <template #suffixIcon="context">
          <font-awesome-icon
            :icon="icon"
            class="text-gray-400 mr-2 hover:text-sky-500 transition-colors cursor-pointer w-4"
            @click="handleIconClick(context.node)"
          />
        </template>
      </form-kit>
    </form-kit>
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";
import { useAuthStore } from "@/stores/auth.js";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  name: "Login",
  components: { FontAwesomeIcon, Logo },
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  data() {
    return {
      icon: "eye-slash",
      email: "",
      password: "",
      errors: [],
      loading: false,
    };
  },
  methods: {
    handleIconClick(node, e) {
      this.icon =
        this.icon === "eye-slash"
          ? (this.icon = "eye")
          : (this.icon = "eye-slash");
      node.props.type = node.props.type === "password" ? "text" : "password";
    },
    login() {
      this.loading = true;
      this.authStore
        .login(this.email, this.password)
        .then((r) => {
          this.$router.push("/");
        })

        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
