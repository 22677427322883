import { defineStore } from "pinia";
import axios from "@/lib/AxiosClient";

import _ from "lodash";

function initialSettings() {
  const initial = {
    showImportedReceipts: false,
    barcodeScanAction: "openReceipt",
    schemaSearch: {
      groupByBrand: true,
    },
    receiptsListView: true,
    gridOrders: {
      showTags: true,
      showStatus: true,
      showUsers: true,
    },
    listOrders: {
      showTags: true,
      showStatus: true,
      showUsers: true,
      showTitle: false,
      splitRow: false,
    },
  };
  if (!localStorage.getItem("settings"))
    localStorage.setItem("settings", JSON.stringify(initial));
  return _.merge(initial, JSON.parse(localStorage.getItem("settings")));
}
export const useSettingsStore = defineStore("settings", {
  state: () => ({
    settings: initialSettings(),
    globalSettings: [],
    users: [],
    tags: [],
  }),
  getters: {
    getSetting: (state) => {
      return (path) => {
        return _.get(state.settings, path);
      };
    },
    getGlobalSetting: (state) => {
      return (key) => {
        return state.globalSettings.find((s) => s.key === key)?.value;
      };
    },
    getGlobalSettings: (state) => {
      return state.globalSettings;
    },
    getUsers: (state) => {
      return state.users;
    },
    getTags: (state) => {
      return state.tags;
    },
  },
  actions: {
    async setSetting(settingPath, settingValue) {
      //console.log(_.set(this.settings, settingPath, settingValue));
      localStorage.setItem("settings", JSON.stringify(this.settings));
    },
    async fetchUsers() {
      await axios.get("/users").then((r) => {
        this.users = r.data.data.map((u) => {
          return { name: u.name, id: u.id };
        });
      });
    },
    async fetchTags() {
      return axios.get("/tag").then((r) => {
        this.tags = r.data.data.map((tag) => {
          return { name: tag.name, id: Number(tag.id), color: tag.color };
        });
      });
    },
    async fetchGlobalSettings() {
      return axios.get("/settings").then((r) => {
        this.globalSettings = r.data.map((setting) => {
          return { key: setting.key, value: setting.value };
        });
      });
    },
  },
});
