import { defineStore } from "pinia";
import { safeJsonParse } from "@/lib/Helpers";
import axiosClient from "@/lib/AxiosClient";

const LOCAL_SHOPS_KEY = "cache/shops";
export const useCacheStore = defineStore("cache", {
  state: () => ({
    shops: safeJsonParse(localStorage.getItem(LOCAL_SHOPS_KEY)),
    shopsCachedAt: localStorage.getItem(LOCAL_SHOPS_KEY + "_cached_at"),
  }),
  getters: {
    getShops: (state) => {
      if (
        state.shops &&
        state.shopsCachedAt &&
        Date.now() - state.shopsCachedAt < 1000 * 60 * 60
      )
        return state.shops;
      else return null;
    },
  },
  actions: {
    updateShopsCache() {
      return axiosClient.get("/etsy/shop").then((response) => {
        this.shops = response.data;
        localStorage.setItem(LOCAL_SHOPS_KEY, JSON.stringify(response.data));
        localStorage.setItem(LOCAL_SHOPS_KEY + "_cached_at", Date.now());
        return this.shops;
      });
    },
  },
});
