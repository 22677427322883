<template>
  <router-link
    :to="{ name: 'receipt', params: { receiptId: receipt.id } }"
    class="flex flex-col w-full text-gray-800 rounded-md overflow-hidden bg-white mb-4"
  >
    <div
      class="flex w-full justify-between items-center px-4 py-2 border-b bg-gray-200 hover:bg-gray-300 transition-colors cursor-pointer"
    >
      <div class="flex flex-row items-center gap-4 text-sm">
        <UrgentNotification v-if="receipt.is_urgent" />
        <div class="h-full text-xl flex items-center monte">
          #
          <h1 class="text-xl font-bold">{{ $props.receipt.id }}</h1>
        </div>
        <!--        CUSTOMER -->
        <div v-if="receipt.customer" class="flex items-center text-sky-600">
          <div
            class="rounded-full w-6 h-6 border-2 flex justify-center items-center font-bold text-[0.6rem] bg-sky-100 border-sky-600"
          >
            {{ customerLetters }}
          </div>
          <div class="ml-2 font-bold whitespace-nowrap">
            {{ receipt.customer.name }}
          </div>
        </div>
        <div
          v-if="!receipt.customer && receipt.shipment_customer_name"
          class="flex items-center font-bold"
        >
          {{ receipt.shipment_customer_name }}
        </div>
        <!--        CUSTOMER END-->
      </div>
    </div>

    <div
      class="grid aspect-[3/2]"
      :class="[
        `grid-cols-${receipt.orders.length != 2 ? Math.floor(receipt.orders.length / 2) : 2}`,
      ]"
    >
      <OrderCardItem
        v-for="order in $props.receipt.orders"
        :key="order.id"
        :order="order"
        :on-modal-open="onModalOpen"
      />
    </div>
    <div
      v-if="
        receipt.orders_count && receipt.orders.length !== receipt.orders_count
      "
      class="w-full text-center text-sm"
    >
      <hr />
      <div class="py-2">
        Diğer {{ receipt.orders_count - receipt.orders.length }} ürün
        gösterilmiyor.
      </div>
    </div>
  </router-link>
</template>
<script>
import { decode } from "he";
import { generateUniqueColor } from "@/lib/Helpers.js";
import UrgentNotification from "@/components/misc/UrgentNotification.vue";
import OrderCardItem from "@/components/OrderCardItem.vue";

export default {
  components: { OrderCardItem, UrgentNotification },
  props: {
    receipt: {
      type: Object,
    },
    onModalOpen: {
      type: Function,
      default: undefined,
    },
  },
  setup() {
    return {
      decode,
      generateUniqueColor,
    };
  },
  data() {
    return {
      tags: ["Acil", "Tekrar", "Cart", "Geç Kargo", "Alo"],
      styles: ["danger", "warning", "info", "success"],
      imagesCount: Math.round(Math.random() * 6),
    };
  },
  computed: {
    getColsClass(orderCount) {
      // const className =
      //   orderCount < 5 ? `grid-cols-${orderCount}` : "grid-cols-4";
      // const classObj = {};
      // classObj[className] = true;
      // return classObj;
      const clName = `grid-cols-${Math.ceil(this.$props.receipt.orders.length / 2)}`;
      const classes = {};
      classes[clName] = true;
      return;
    },
    customerLetters() {
      return this.$props.receipt.customer.name
        .split(" ")
        .map((w) => w.charAt(0).toUpperCase())
        .join("");
    },
  },
};
</script>
