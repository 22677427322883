<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueEasyLightbox from "vue-easy-lightbox";

import { useModal } from "vue-final-modal";
import SchemaModal from "@/components/modals/SchemaModal.vue";
import DropdownButton from "@/components/misc/DropdownButton.vue";
import Schema from "@/lib/Schema";
import SchemaDimension from "@/components/misc/custom_fields/SchemaDimension.vue";
export default {
  name: "SchemaView",
  components: {
    SchemaDimension,
    DropdownButton,
    FontAwesomeIcon,
    VueEasyLightbox,
  },
  props: {
    initialGuide: {
      required: true,
      type: Object,
    },
    overwriteable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:guide"],
  data() {
    return {
      showLightbox: false,
      shownImage: null,
      schemaHelper: null,
      guide: null,
      axes: [
        { key: "width", label: "Genişlik" },
        { key: "height", label: "Yükseklik" },
        { key: "depth", label: "Derinlik" },
      ],
    };
  },
  watch: {
    guides(newValue) {
      this.copyToLocal(newValue);
    },
  },
  mounted() {
    this.guide = this.$props.initialGuide;
    this.schemaHelper = new Schema(this.guide);
  },
  methods: {
    showImage(src) {
      this.shownImage = src;
      this.showLightbox = true;
    },
    hideImage() {
      this.showLightbox = false;
    },
    detachSchema(pivot) {
      this.axios
        .get(`order/${pivot.order_id}/remove-schema/${pivot.guide_id}`)
        .then(() => {
          this.$emitter.emit("orderUpdated", pivot.order_id);
        });
    },
    openSchema(schemaId) {
      const routeData = this.$router.resolve({
        name: "schema",
        params: { schemaId },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<template>
  <div
    v-if="guide"
    class="flex flex-col gap-2 guide group bg-white p-2 relative rounded border"
  >
    <dropdown-button
      class="default w-6 !absolute top-2 right-2 !hidden group-hover:!flex"
      align="left"
    >
      <template #dropdown>
        <li>
          <button @click="openSchema(guide.id)">Şemayı Aç</button>
        </li>
        <li>
          <button @click="detachSchema(guide.pivot)">
            <font-awesome-icon icon="link-slash"></font-awesome-icon>
            Şemayı Kaldır
          </button>
        </li>
      </template>
    </dropdown-button>
    <table class="main">
      <tr v-if="guide?.name">
        <td>Şema Adı</td>
        <td>
          {{ guide.name }}
        </td>
      </tr>

      <tr v-if="guide?.compatible_brand || guide?.compatible_model">
        <td>Uyumlu Ürün</td>
        <td>
          <div class="flex flex-col">
            <span class="border-b p-1">{{ guide?.compatible_brand }}</span>
            <!--            <hr class="w-1/2 my-1 border-gray-300 mx-auto" />-->
            <span class="p-1">{{ guide?.compatible_model }}</span>
          </div>
        </td>
      </tr>
    </table>
    <VueEasyLightbox
      :imgs="shownImage"
      :zoom-scale="1"
      :max-zoom="5"
      :visible="showLightbox"
      @hide="hideImage"
    />
    <div class="flex gap-4">
      <div
        v-for="(image, index) in guide.images"
        :key="index"
        class="h-20 border rounded-md overflow-hidden"
      >
        <img
          :src="image.src"
          class="h-full aspect-square object-cover cursor-pointer"
          alt=""
          @click="() => showImage(image.src)"
        />
      </div>
    </div>
    <SchemaDimension
      v-for="(dimension, index) in guide.dimensions"
      :key="index"
      v-model:dimension="guide.dimensions[index]"
      :overwriteable="$props.overwriteable"
      :editable="false"
      :schema="schemaHelper"
      :copyable="false"
    />
    <div v-if="guide?.description" class="flex-col">
      <div class="font-bold mb-2">Şema Detayı</div>
      <div>
        {{ guide.description }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
//table {
//  & {
//    @apply w-full;
//  }
//  & td {
//    @apply px-2 py-1 bg-white rounded;
//  }
//  :not(.guide) > & tr:not(:last-child) {
//    @apply border-b;
//  }
//  :not(.guide) > & tr:not(:last-child) td {
//    @apply pb-4;
//  }
//  & tr:last-child td {
//    @apply pt-4;
//  }
//  .guide > & td {
//    @apply border;
//  }
//}
table.main tr td:first-child {
  @apply w-1/4 text-right pr-4 font-bold;
}
table.dimensions tr td:first-child {
  @apply w-1/4 text-right pr-4 font-bold;
}
table.dimensions td:first-child:not([colspan="2"]) {
  @apply bg-gray-100;
}
table.dimensions {
  @apply border;
}
table.dimensions tr {
  &:nth-child(even) td:not(:first-child) {
    @apply bg-gray-50;
  }
  td {
    & {
      @apply px-2 py-2 transition-colors;
    }
    &:hover:not(:first-child) {
      @apply bg-gray-300 #{!important};
    }
  }
}
</style>
