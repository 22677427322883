<template>
  <div class="flex flex-nowrap relative">
    <input-label v-if="$props.labelText">
      {{ $props.labelText }}
    </input-label>
    <input
      type="number"
      class="px-3 z-0 w-full"
      step="0.1"
      min="0"
      :class="{
        '!border-red-300 bg-red-100': $props.isChanged,
        '!rounded-r-none  ': $props.endField,
        '!rounded-l-none !border-l-0': $props.flatLeft,
      }"
      :value="modelValue / 10"
      :placeholder="$props.placeholder"
      @input="$emit('update:modelValue', Number($event.target.value * 10))"
    />
    <div
      v-if="$props.endField"
      class="border border-l-0 rounded-r-md px-3 min-w-[2.5rem] border-gray-300 bg-gray-200 text-sm flex items-center pointer-events-none justify-center"
      :class="{ 'rounded-r-none': $props.flatRight }"
    >
      {{ $props.endField }}
    </div>
  </div>
</template>
<script>
import InputLabel from "@/components/misc/InputLabel.vue";

export default {
  name: "SchemaDimensionInput",
  components: { InputLabel },
  props: {
    modelValue: {
      default: "",
      required: true,
    },
    isChanged: {
      type: Boolean,
      default: false,
    },
    labelText: {
      type: String,
      default: null,
      required: false,
    },
    placeholder: {
      type: String,
      default: null,
      required: false,
    },
    buttonFunction: {
      type: Function,
      default: null,
    },
    flatRight: {
      default: false,
    },
    flatLeft: {
      default: false,
    },
    endField: {
      default: null,
    },
  },
};
</script>
<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@import "@/style/variables";
input {
  &[type="text"],
  &[type="email"],
  &[type="number"],
  &[type="password"] {
    @apply py-2 px-3 border border-gray-300 text-base outline-#{$default-color}-500 #{$default-rounded} outline-1;
  }
}
</style>
