class Dimensions {
  constructor(dimension) {
    this.id = dimension.id;
    this.dimension = dimension;
  }

  getDimensionsText() {
    const text = `In CM
    Length:  ${this.getAllValues("width")} CM
    Height:  ${this.getAllValues("height")} CM
    Depth:  ${this.getAllValues("depth")} CM 
    
    In INCH
    Length:  ${this.getAllValues("width", true)} INCH
    Height:  ${this.getAllValues("height", true)} INCH
    Depth:  ${this.getAllValues("depth", true)} INCH 
    `.replaceAll("    ", "");
    return text;
  }
  async copyDimensions() {
    const text = await this.getDimensionsText();
    await navigator.clipboard.writeText(text).catch((e) => console.log(e));
  }
  getAllValues(key, inch = false) {
    return this.dimension[key].length > 0
      ? this.dimension[key]
          .map((d) => (inch ? this.toInch(d / 10) : d / 10))
          .reduce((d, r) => `${d} / ${r}`)
      : "-";
  }

  toInch(cm) {
    return (Number(cm) * 0.393700787).toFixed(2);
  }
}

export default Dimensions;
