<script setup>
import { computed, ref } from "vue";
import OrderStatus from "@/components/misc/OrderStatus.vue";
import DropdownButton from "@/components/misc/DropdownButton.vue";
const showStates = ref(false);
defineEmits(["update:status"]);
const props = defineProps({
  status: {
    required: true,
    type: String,
  },
});
const allStates = ["finished", "ready", "processing", "cancelled"];
const availableStates = computed(() =>
  allStates.filter((s) => s !== props.status),
);
</script>

<template>
  <div class="flex flex-col relative status__selector">
    <dropdown-button ref="statusDropdown" no-icon close-on-select>
      <OrderStatus :status="props.status" />
      <template #dropdown>
        <div class="flex flex-col gap-3 text-base">
          <OrderStatus
            v-for="(state, i) in availableStates"
            :key="i"
            :status="state"
            @click="
              (e) => {
                $emit('update:status', state);
              }
            "
          />
        </div>
      </template>
    </dropdown-button>
  </div>
</template>

<style>
.status__selector .dropdown_menu {
  @apply !bg-gray-800 rounded-2xl p-2;
}
</style>
