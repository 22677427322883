<template>
  <div :class="{ show: showSearch }" class="search__wrapper">
    <div class="search">
      <Logo class="lg:hidden pb-4"></Logo>
      <FormKit
        id="searchForm"
        v-model="searchForm"
        value=""
        type="form"
        action="none"
        form-class="w-full"
        :actions="false"
        :disabled="loading"
        @input="handleInput"
        @submit="handleSubmit"
      >
        <slot name="before" />
        <form-kit
          name="keyword"
          autocomplete="off"
          type="search"
          label="Arama"
          :placeholder="searchPlaceholder"
          @input="(i) => (i === '' ? null : live ? (isTyping = true) : null)"
        />
        <slot name="sort">
          <form-kit
            v-if="sortable"
            name="sort"
            type="select"
            label="Sıralama"
            :options="allSortOptions()"
          />
        </slot>
        <form-kit
          name="per_page"
          type="select"
          label="Sayfa Başına Gösterim"
          :options="[
            {
              label: '25 Adet',
              value: 25,
            },
            {
              label: '50 Adet',
              value: 50,
            },
            {
              label: '100 Adet',
              value: 100,
            },
          ]"
        />
        <slot name="default" />
        <div class="grid grid-cols-3 gap-2">
          <form-kit
            type="submit"
            :classes="{
              outer: 'col-span-2',
            }"
            input-class="info w-full flex center h-12"
            prefix-icon="search"
          >
            Ara
          </form-kit>
          <form-kit
            type="button"
            prefix-icon="close"
            input-class="gray w-full flex center h-12"
            @click="resetSearch"
          >
            Sıfırla
          </form-kit>
        </div>
      </FormKit>
      <slot name="outter"></slot>
      <slot
        v-if="meta?.last_page > 1"
        name="count"
        :total="meta?.total"
        :per_page="meta?.per_page"
      >
        <div
          v-if="meta?.total && showCount"
          class="success rounded flex items-center justify-center mb-4 text-xs text-center"
        >
          {{ meta.total }} sonuç arasından {{ meta.per_page }} adet
          gösteriliyor.
        </div>
      </slot>
      <PaginationMeta
        v-if="meta && pagination && meta.last_page > 1"
        :data="meta"
        :disabled="loading"
        @page-changed="onPageChange"
      />
    </div>
    <div class="expand__button lg:hidden">
      <button @click="showSearch = !showSearch">
        <font-awesome-icon
          icon="angles-right"
          :class="{ hidden: showSearch }"
        />
        <font-awesome-icon
          icon="angles-left"
          :class="{ hidden: !showSearch }"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { FormKit } from "@formkit/vue";
import { getNode } from "@formkit/core";
import PaginationMeta from "./PaginationMeta.vue";
import {
  debounce,
  omit,
  isEqual,
  reduce,
  toPairs,
  differenceWith,
  fromPairs,
} from "lodash";
import Logo from "@/components/Logo.vue";
import { toRaw } from "vue";
import _ from "lodash";
import loading from "@/components/misc/Loading.vue";
const objectDiff = (newObject, originalObject) => {
  const objectsDiff = (newObject, originalObject) => {
    return _.transform(newObject, function (result, value, key) {
      if (!_.isEqual(value, originalObject[key])) {
        result[key] =
          _.isObject(value) && _.isObject(originalObject[key])
            ? objectsDiff(value, originalObject[key])
            : value;
      }
    });
  };
  return objectsDiff(newObject, originalObject);
};

export default {
  name: "SearchMenu",
  components: { Logo, FormKit, PaginationMeta },
  props: {
    endpoint: {
      type: String,
      required: true,
    },
    live: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    showCount: {
      type: Boolean,
      default: true,
    },
    searchPlaceholder: {
      type: String,
      default: "Anahtar kelime ile arayın...",
    },
    modelValue: {
      type: Object,
      default: null,
    },
    select: {
      type: Array,
      default: null,
    },
    extraSortOptions: {
      type: Array,
      default: () => [],
    },
    autoSearchFields: {
      type: Array,
      default: () => [],
    },
    sortable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["loaded", "loading", "update:modelValue", "reset"],
  data() {
    return {
      sortOptions: [
        {
          label: "Yeniden Eskiye",
          value: {
            by: "created_at",
            order: "DESC",
          },
        },
        {
          label: "Eskiden Yeniye",
          value: {
            by: "created_at",
            order: "ASC",
          },
        },
      ],
      searchForm: {},
      shownIds: [],
      page: 1,
      userInput: false,
      loading: false,
      showSearch: false,
      isTyping: false,
      meta: null,
      done: false,
      lastQuery: null,
      extraInitial: null,
    };
  },
  watch: {
    "$props.parameters": {
      handler() {
        console.log("changed");
      },
      deep: true,
    },
    loading: function (value) {
      this.$emit("loading", value);
    },
    "search.keyword": debounce(function () {
      this.isTyping = false;
    }, 500),
  },
  activated() {
    if (!this.$route.query?.q) {
      this.setURLParameters();
    }
  },
  mounted() {
    this.initEventListeners();

    if (this.$route.query?.q) {
      this.handleURLParameters().then(() => {
        // console.log("query found");
        this.getResources();
      });
    } else {
      // console.log("no query found creating one");
      this.setURLParameters().then(() => {
        this.getResources();
      });
    }
  },
  unmounted() {
    this.$emitter.off(this.getEventName("updated"), this.handleUpdateEvent);
    this.$emitter.off(this.getEventName("created"), this.handleUpdateEvent);
    this.$emitter.off(this.getEventName("deleted"), this.handleUpdateEvent);
  },
  methods: {
    getEventName(eventName) {
      return `${this.endpoint}.${eventName}`;
    },
    initEventListeners() {
      this.$emitter.on(this.getEventName("updated"), this.handleUpdateEvent);
      this.$emitter.on(this.getEventName("created"), this.handleUpdateEvent);
      this.$emitter.on(this.getEventName("deleted"), this.handleUpdateEvent);
    },
    handleUpdateEvent(resource_id) {
      if (!resource_id || this.shownIds.includes(resource_id)) {
        this.getResources(null, true);
      }
    },
    handleInput(e) {
      if (!this.userInput) return;
      // TODO manual input detection
    },
    resetSearch() {
      this.$emit("reset");
      this.$formkit.reset("searchForm");
      this.$formkit.submit("searchForm");
    },
    allSortOptions() {
      return [...this.sortOptions, ...this.extraSortOptions];
    },
    async handleURLParameters() {
      const queryValues = JSON.parse(atob(this.$route.query.q));
      const extraKeys = this.modelValue ? Object.keys(this.modelValue) : [];
      const val = {};

      for (const extraKeyIndex in extraKeys) {
        const extraKey = extraKeys[extraKeyIndex];
        if (queryValues[extraKey]) {
          val[extraKey] = queryValues[extraKey];
        } else {
          val[extraKey] = this.modelValue[extraKey];
        }
      }
      // console.log("url", atob(this.$route.query.q));
      this.searchForm = omit(queryValues, "page");
      this.page = Number.parseInt(queryValues.page);
      if (Object.keys(val).length > 0) this.$emit("update:modelValue", val);
    },
    async setURLParameters() {
      this.$router.replace({
        name: this.$route.name,
        query: { q: this.encodeQuery(this.getAllParams()) },
        params: this.$route.params,
      });
    },
    encodeQuery(query) {
      return btoa(JSON.stringify(query));
    },
    getAllParams() {
      return {
        ...this.searchForm,
        ...this.modelValue,
        page: this.page,
      };
    },
    onPageChange(p) {
      this.page = Number.parseInt(p);
      this.getResources();
    },
    handleSubmit(params) {
      this.getResources(this.getAllParams());
    },
    getResources(params, silent = false) {
      if (!params) {
        params = this.getAllParams();
      }
      if (
        this.lastQuery &&
        JSON.stringify(omit(params, "page")) !== JSON.stringify(this.lastQuery)
      ) {
        this.page = 1;
        params.page = 1;
      }
      if (!this.userInput) this.userInput = true;
      if (!silent) this.loading = true;
      this.setURLParameters(params);
      this.axios
        .get(`/${this.endpoint}`, { params })
        .then((r) => {
          this.lastQuery = JSON.parse(JSON.stringify(omit(params, "page")));
          this.meta = r.data.meta;
          this.shownIds = r.data.data.map((r) => r.id);
          this.$emit("loaded", r.data);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.expand__button {
  & {
    @apply h-full translate-x-full flex items-center absolute top-0 right-0 z-[55] lg:hidden;
  }
  & button {
    @apply bg-sky-600 text-white w-8 h-10 rounded-r-full pr-2;
  }
}
.search__wrapper {
  &.show {
    @apply translate-x-0 #{!important};
  }
  @apply z-20 w-2/3 md:w-1/2 lg:w-1/4 2xl:w-[20%] h-[100vh] flex fixed lg:sticky left-0 top-0 lg:transform-none -translate-x-full transition-transform;
}
.search {
  & {
    @apply overflow-y-auto w-full h-full z-50 lg:m-0 mb-4 bg-sky-800 text-white p-4 shadow-2xl scrollbar-thin #{!important};
  }
}
</style>
