<template>
  <MainHeader />
  <div class="flex mx-auto container gap-4 lg:gap-12 mt-4 relative">
    <div class="search flex-col gap-2 w-[25%] sticky top-4 h-full mt-12">
      <FormKit
        v-model="search.keyword"
        type="text"
        placeholder="Arama yapın..."
        label="Anahtar Kelime"
        :help="helpText"
      />
      <FormKit v-model="search.exact" type="checkbox" label="Tam Eşleşme" />
      <FormKit
        v-model="search.hasGuides"
        type="select"
        label="Şema Bağlantısı"
        :options="[
          { label: 'Fark Etmez', value: null },
          { label: 'Var', value: true },
          { label: 'Yok', value: false },
        ]"
      />

      <FormKit
        v-if="shops && shops.length"
        v-model="selectedShopId"
        type="select"
        label="Mağaza"
        :options="[{ label: 'Tümü', value: null }, ...getShopOptions]"
      />
      <MultiSelect
        v-if="selectedShopId"
        :selected-options="selectedSectionIds"
        label="Bölüm"
        :options="getSectionOptions"
        class="mb-4"
        placeholder="Bölüm seçin..."
        @select="onSectionSelect"
      />
      <FormKit type="button" label="Ara" @click="() => handleSearch()" />
      <p v-if="metaData" class="text-sm text-gray-500">
        {{ metaData.total }} sonuç arasından {{ listings.length }} adet
        görüntüleniyor.
      </p>
    </div>
    <div
      v-if="listings.length > 0 || loading"
      class="w-full flex flex-col gap-4 pb-4"
    >
      <PaginationMeta
        v-if="metaData && listings.length > 0"
        :disabled="loading"
        :data="metaData"
        @page-changed="handleSearch"
      />
      <div
        v-if="!loading"
        class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 w-full gap-4"
      >
        <EtsyListingCard
          v-for="listing in listings"
          :key="listing.id"
          :listing="listing"
          link-to="modal"
          @listing-updated="handleSearch(metaData.current_page, true)"
        />
      </div>
      <div v-else class="grid grid-cols-4 xl:grid-cols-5 w-full gap-4">
        <div
          v-for="i in 25"
          :key="i"
          class="h-48 bg-white animate-pulse w-full rounded-md"
        ></div>
      </div>
      <PaginationMeta
        v-if="metaData && listings.length > 0"
        :disabled="loading"
        :data="metaData"
        @page-changed="handleSearch"
      />
    </div>
    <div v-else class="text-center w-full">Sonuç bulunamadı!</div>
  </div>
</template>

<script setup>
import MainHeader from "@/components/MainHeader.vue";
import axiosClient from "@/lib/AxiosClient";
import { reactive, ref, onMounted, computed } from "vue";
import EtsyListingCard from "./EtsyListingCard.vue";
import PaginationMeta from "@/components/misc/PaginationMeta.vue";
import { MultiSelect } from "vue-search-select";
import { useCacheStore } from "@/stores/cache";

const search = reactive({
  keyword: "",
  exact: true,
  hasGuides: null,
});

const helpText = computed(() => {
  return search.keyword.includes(" ")
    ? "Aramanız boşluk karakteri içeriyor, yanlışlıkla eklendiyse silin."
    : "";
});

const listings = ref([]);

const metaData = ref(null);

const loading = ref(false);

const handleSearch = (page = 1, silent = false) => {
  if (!silent) loading.value = true;
  axiosClient
    .get("/etsy/listing", {
      params: {
        page: page,
        ...search,
        shopId: selectedShopId.value,
        sectionIds: selectedSectionIds.value.map((s) => s.value),
        hasGuides: search.hasGuides,
      },
    })
    .then((response) => {
      listings.value = response.data.data;
      metaData.value = response.data.meta;
    })
    .finally(() => (loading.value = false));
};

const cache = useCacheStore();

const shops = ref([]);
const selectedShopId = ref(null);

// function getShops() {
//   axiosClient.get("/etsy/shop").then((response) => {
//     shops.value = response.data;
//   });
// }

const getShopOptions = computed(() => {
  return shops.value.map((shop) => ({
    label: shop.shop_name,
    value: shop.shop_id,
  }));
});

const selectedSectionIds = ref([]);
const getSectionOptions = computed(() => {
  const shopIndex = shops.value.findIndex((s) => {
    return s.shop_id == selectedShopId.value;
  });
  if (shopIndex == -1) return [];
  console.log(shops.value[0]);
  return shops.value[shopIndex].sections.map((s) => ({
    text: s.title,
    value: s.shop_section_id,
  }));
});

function onSectionSelect(selected) {
  selectedSectionIds.value = selected;
}

onMounted(() => {
  // getShops();
  if (cache.getShops) shops.value = cache.getShops;
  else {
    cache.updateShopsCache().then((s) => (shops.value = s));
  }
});
</script>
