<template>
  <div class="flex flex-nowrap relative w-full">
    <input-label v-if="$props.labelText">{{$props.labelText}}</input-label>
    <select
      class="rounded border border-r-0 px-3 outline-0 w-full h-10"
      :value="selectedIndex"
      @input="$emit('update:modelValue', options[parseInt($event.target.value)][valueKey])"
    >
      <option
        v-for="(i, index) in options"
        :key="valueKey ? i[valueKey] : i"
        :value="index"
      >
        {{ nameKey ? i[nameKey] : i}}
      </option>
    </select>
    <div
      class="bg-white border border-l-0 rounded-r-md w-10 hover:bg-gray-300 transition-all flex items-center justify-center cursor-pointer"
      v-if="$props.resetable"
      @click="$emit('update:modelValue', null)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import InputLabel from "@/components/misc/InputLabel.vue";
export default {
  components: {InputLabel},
    props: {
        labelText:{
          default: null,
        },
        modelValue: {
            default: null,
            required: true,
        },
        nameKey: {
            type: String,
            default: 'name',
            required: false
        },
        valueKey: {
          type: String,
          default: 'value',
          required: false
        },
        options: {
          type: Array,
          default: [],
          required: true
        },
        resetable: {
          type: Boolean,
          default: false
        },
      },
  computed:{
      selectedIndex(){
        return this.options.findIndex(o => {
          return _.isEqual(o.value, this.modelValue)
        })
      }
  }


}
</script>