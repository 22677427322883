<template>
  <MainHeader />
  <div class="container mx-auto py-8 flex gap-8 items-center justify-center">
    <div class="flex flex-col items-center justify-center">
      <component
        :is="chartComponents[chart.type]"
        v-for="(chart, index) in charts"
        :key="index"
        :data="chart.data"
        :title="chart.name"
      />
    </div>
  </div>
</template>

<script setup>
import MainHeader from "@/components/MainHeader.vue";
import axiosClient from "@/lib/AxiosClient";
import { onMounted } from "vue";
import { ref } from "vue";
import BarChart from "@/components/charts/BarChart.vue";
import LineChart from "@/components/charts/LineChart.vue";
const chartComponents = {
  linechart: LineChart,
  barchart: BarChart,
};
const charts = ref([]);
function getStats() {
  axiosClient.get("/stats").then((r) => {
    r.data.data.forEach((stat) => {
      if (stat.type.endsWith("chart")) charts.value.push(stat);
    });
  });
}

onMounted(() => {
  getStats();
});
</script>
