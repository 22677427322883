<template>
  <div class="flex flex-col bg-white p-2 rounded-md group shadow h-auto">
    <div class="relative">
      <DropdownButton
        v-if="!!slots['dropdown']"
        class="!absolute left-[98%] -translate-x-full z-10 bg-white top-1 border px-1.5 rounded group-hover:visible invisible"
        no-icon
      >
        <FontAwesomeIcon icon="ellipsis" />
        <template #dropdown>
          <slot name="dropdown" />
        </template>
      </DropdownButton>
      <a
        v-if="props.linkTo !== 'modal'"
        :href="
          linkTo === 'editLink'
            ? `https://www.etsy.com/your/shops/me/listing-editor/edit/${listing.listing_id}`
            : listing.url
        "
        target="_blank"
      >
        <EtsyImage :src="listing.images[0]['url_570xN']" />
      </a>
      <EtsyImage
        v-else
        :src="listing.images[0]['url_570xN']"
        @click="() => listingModal.open()"
      />
    </div>
    <p class="line-clamp-2 font-bold py-1" :title="decode(listing.title)">
      {{ decode(listing.title) }}
    </p>
    <ul v-if="!isHidden('details')" class="text-sm details text-gray-500">
      <li v-if="!isHidden('shopName')">
        <div class="icon">
          <FontAwesomeIcon
            icon="store"
            size="xs"
            class="text-gray-600 mx-auto"
          />
        </div>
        <span class="truncate" :title="listing.shop.shop_name">
          {{ listing.shop.shop_name }}
        </span>
      </li>
      <li v-if="listing.skus.length > 0 && !isHidden('sku')">
        <div class="icon">
          <FontAwesomeIcon
            icon="circle"
            size="2xs"
            class="text-gray-600 mx-auto"
          />
        </div>
        <span> SKU: {{ listing.skus[0] }} </span>
      </li>
      <li v-if="listing.guides.length > 0 && !isHidden('sku')">
        <div class="icon">
          <FontAwesomeIcon
            icon="link"
            size="2xs"
            class="text-gray-600 mx-auto"
          />
        </div>
        <span> {{ listing.guides.length }} Şema Bağlantısı </span>
      </li>
    </ul>
  </div>
</template>
<script setup>
import DropdownButton from "@/components/misc/DropdownButton.vue";
import EtsyImage from "./EtsyImage.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { decode } from "he";
import { useSlots } from "vue";
import { useModal } from "vue-final-modal";
import ListingEditModal from "./modal/ListingEditModal.vue";
import { link } from "@formkit/icons";

const emit = defineEmits(["listingUpdated"]);
const slots = useSlots();
const props = defineProps({
  listing: {
    type: Object,
    required: true,
  },
  hidden: {
    type: Array,
    required: false,
    default: () => [],
  },
  linkTo: {
    type: String,
    required: false,
    default: "listing",
  },
});

function isHidden(key) {
  return props.hidden.includes(key);
}

const listingModal = useModal({
  component: ListingEditModal,
  listingId: props.listing.listing_id,
  attrs: {
    listingId: props.listing.listing_id,
    onListingUpdated: (listing) => {
      emit("listingUpdated", listing);
    },
  },
});
</script>
<style scoped lang="scss">
.details {
  li {
    .icon {
      @apply flex items-center justify-end w-5 p-1 box-border;
    }
    span {
      @apply pl-2;
    }
    @apply flex items-center;
  }
}
</style>
