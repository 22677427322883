<template>
  <vue-final-modal @closed="emitClose">
    <div class="modal-body relative p-4 pb-0 w-full lg:w-[70vw]">
      <!--        INFO SECTION START-->
      <section id="info" class="w-full flex flex-col gap-2">
        <table>
          <tr>
            <td>Şema İsmi</td>
            <td>
              <form-kit v-model="schemaInput.name" type="text" name="name" />
            </td>
          </tr>
        </table>
        <table class="compatible">
          <tr>
            <td>Uyumlu Marka</td>
            <td>
              <BrandSelector v-model="schemaInput.compatible_brand" />
            </td>
          </tr>
          <tr>
            <td>Uyumlu Model</td>
            <td>
              <form-kit
                v-model="schemaInput.compatible_model"
                placeholder="Uyumlu modelin ismi..."
                type="text"
              />
            </td>
          </tr>
        </table>
        <SchemaDimension
          v-for="(dimension, index) in schemaInput.dimensions"
          :key="index"
          v-model:dimension="schemaInput.dimensions[index]"
          :schema="schemaHelper"
          @deleted="
            () => {
              schemaInput.dimensions.splice(index, 1);
            }
          "
        />
        <button class="info" @click="addDimension">
          <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
          Ölçü Ekle
        </button>
        <div class="grid grid-cols-2 gap-4">
          <div class="w-full">
            <h3>Detaylar</h3>
            <FormKit
              v-model="schemaInput.description"
              type="textarea"
              placeholder="Bu alana yazılan metin, sipariş sayfasında görünür."
              help="Bu alana yazılan metin, sipariş sayfasında görünür."
              rows="5"
            />
          </div>
          <div class="w-full">
            <h3>Özel Bilgi</h3>
            <FormKit
              v-model="schemaInput.attention"
              input-class="bg-red-50"
              type="textarea"
              placeholder="Bu alan sipariş sayfasında görünmez."
              help="Bu alan sipariş sayfasında görünmez."
              rows="5"
            />
          </div>
        </div>
      </section>
      <!--        INFO SECTION END-->
      <!--        SAVE BUTTONS START-->
      <section
        class="flex justify-end col-span-2 gap-4 bg-gray-300 px-8 py-4 -mx-4 rounded-b-md"
      >
        <button class="gray col-span-2" @click="emitClose">
          <font-awesome-icon icon="close" /> Kapat
        </button>
        <button class="success" @click="createSchema">
          <font-awesome-icon icon="save" />
          Oluştur
        </button>
      </section>
      <!--        SAVE BUTTONS END-->
    </div>
  </vue-final-modal>
</template>

<script setup>
import { ref } from "vue";
import "vue-search-select/dist/VueSearchSelect.css";
import { VueFinalModal } from "vue-final-modal";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import SchemaDimension from "../misc/custom_fields/SchemaDimension.vue";
import "vue-select/dist/vue-select.css";

import axios from "@/lib/AxiosClient.js";
import BrandSelector from "../form/BrandSelector.vue";

const schemaHelper = ref(null);
const schemaInput = ref({
  name: null,
  description: null,
  attention: null,
  compatible_brand: null,
  compatible_model: null,
  dimensions: [],
});
const emit = defineEmits(["update:modelValue", "schemaCreated"]);

const addDimension = () => {
  schemaInput.value.dimensions.push({
    width: [0],
    height: [0],
    depth: [0],
    name: null,
  });
};

const createSchema = () => {
  axios
    .post("guide", {
      ...schemaInput.value,
    })
    .then((r) => {
      emit("schemaCreated", r.data.data);
      emit("update:modelValue", false);
    });
};

const emitClose = () => {
  emit("update:modelValue", false);
};
</script>
<style lang="scss">
.right {
  .formkit-help {
    @apply text-sm;
  }
  .formkit-inner,
  .formkit-input {
    @apply max-w-full m-0 #{!important};
  }
  .formkit-outer {
    @apply mb-0 #{!important};
  }
}
</style>
<style lang="scss" scoped>
h3 {
  @apply text-lg font-bold mb-1;
}
.left table {
  & {
    @apply w-full;
  }
  tr th {
    @apply font-semibold bg-gray-200 border text-xs #{!important};
  }
  tr:nth-child(even) td {
    @apply bg-gray-50;
  }
  tr {
    td {
      @apply border py-2 px-2 text-center;
    }
  }
}
.right table {
  tr {
    td:first-child {
      @apply w-1/3 text-right bg-white relative;
    }
    td:first-child span.date {
      @apply absolute right-2 top-2 bg-gray-600 rounded-full py-0.5 px-2 text-sm text-gray-100 text-center;
    }
    td:first-child span.orderID {
      @apply absolute left-2 top-2 bg-sky-600 rounded-full py-0.5 px-2 text-sm text-sky-100 text-center font-bold;
    }
    td {
      @apply border py-2 px-4;
    }
  }
}
.compatible {
  tr {
    td {
      @apply align-middle h-4;
    }
    //td:nth-child(2){
    //  @apply font-bold ;
    //}
  }
}
</style>
