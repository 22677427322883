<script setup>
import { useSettingsStore } from "@/stores/settings";
import { onMounted, ref } from "vue";

const tags = ref([]);

onMounted(async () => {
  tags.value = await useSettingsStore().getTags;
});
</script>

<template>
  <form-kit
    type="select"
    :options="tags.map((t) => ({ label: t.name, value: t.id }))"
  />
</template>

<style scoped></style>
