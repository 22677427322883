<script>
import MainHeader from "@/components/MainHeader.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { decode } from "he";
import Loading from "@/components/misc/Loading.vue";
import { currencySymbol } from "../../lib/Helpers.js";
import EtsyReceiptPlaceHolder from "@/components/placeholders/EtsyReceiptPlaceHolder.vue";
import { useSettingsStore } from "@/stores/settings";
import dayjs from "dayjs";
import EtsyShopCard from "./EtsyShopCard.vue";

export default {
  name: "EtsyShopOrders",
  components: {
    EtsyReceiptPlaceHolder,
    Loading,
    FontAwesomeIcon,
    MainHeader,
    EtsyShopCard,
  },
  setup() {
    const settings = useSettingsStore();
    return {
      settings,
      dayjs,
    };
  },
  data() {
    return {
      receipts: [],
      importing: [],
      loading: false,
    };
  },
  mounted() {
    this.getReceipts();
  },
  methods: {
    currencySymbol,
    decode,
    isImporting(receipt_id) {
      return this.importing.includes(receipt_id);
    },
    importReceipt(receiptId) {
      this.importing.push(receiptId);
      this.axios
        .post(
          `etsy/shop/${this.$route.params.etsyShopId}/orders/${receiptId}/import`,
        )
        .then((r) => {
          // this.getReceipts()
          const rIndex = this.receipts.findIndex(
            (r) => r.receipt_id === receiptId,
          );
          if (!this.settings.getSetting("showImportedReceipts")) {
            this.receipts.splice(rIndex, 1);
          } else {
            this.receipts[rIndex].imported = true;
          }
          const impIndex = this.importing.findIndex((a) => a === receiptId);
          if (impIndex !== -1) this.importing.splice(impIndex, 1);
          this.$emitter.emit("receipt.created");
        });
    },
    getReceipts(noCache = false) {
      this.loading = true;
      this.axios
        .get(`etsy/shop/${this.$route.params.etsyShopId}/orders`, {
          params: {
            clear_cache: noCache,
            showImported: this.settings.getSetting("showImportedReceipts"),
          },
        })
        .then((r) => {
          this.receipts = r.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<template>
  <MainHeader />
  <div class="container mx-auto">
    <EtsyShopCard
      :shop-id="$route.params.etsyShopId"
      class="my-4"
      no-dropdown
    />
    <div class="flex mt-2 justify-between">
      <h2>Siparişler</h2>
      <div class="flex gap-4">
        <span class="center">
          <input
            type="checkbox"
            class="w-4 h-4 mr-2"
            :disabled="loading"
            :checked="settings.getSetting('showImportedReceipts')"
            @input="
              (e) => {
                settings.setSetting('showImportedReceipts', e.target.checked);
                getReceipts();
              }
            "
          />
          İçe Aktarılanları Göster
        </span>
        <button class="success" :disabled="loading" @click="getReceipts(true)">
          <font-awesome-icon icon="repeat" size="xs" /> Yenile
        </button>
      </div>
    </div>

    <template v-if="loading">
      <EtsyReceiptPlaceHolder v-for="i in 4" :key="i" />
    </template>
    <!--    <Loading :is-loading="loading" />-->
    <div
      v-if="receipts.length === 0"
      class="bg-gray-200 px-5 py-8 mt-8 rounded text-center font-semibold"
    >
      Sipariş Bulunamadı!
    </div>
    <div class="grid grid-cols-1 my-4 gap-6">
      <div
        v-for="receipt in receipts"
        :key="receipt.id"
        class="w-full border-1 bg-white rounded-md shadow-md relative"
      >
        <Loading :is-loading="isImporting(receipt.receipt_id)" />
        <div class="top-bar bg-gray-300 px-5 py-2 flex justify-between">
          <div class="flex gap-4 items-center">
            <span class="font-bold">{{ receipt.name }}</span
            ><span class="text-sm"
              >Kargo Tarihi:
              {{
                dayjs.unix(receipt.expected_ship_date).format("DD/MM/YYYY")
              }}</span
            >
          </div>
          <div class="flex">
            <button
              v-if="!receipt.imported"
              class="info px-2 rounded"
              @click="importReceipt(receipt.receipt_id)"
            >
              Tümünü İçe Aktar
            </button>
            <span
              v-if="receipt.imported"
              class="success px-2 rounded cursor-pointer"
            >
              <font-awesome-icon icon="check" size="xs" />
              İçe Aktarıldı
            </span>
          </div>
        </div>
        <div class="flex flex-col md:flex-row px-5 py-3 hover:bg-gray-100">
          <div class="w-full md:w-3/4 md:pr-4">
            <div class="flex text-sm items-center gap-4">
              <a
                :href="`https://www.etsy.com/your/orders/sold?order_id=${receipt.receipt_id}`"
                class="underline"
              >
                #{{ receipt.receipt_id }}
              </a>
              <span>
                {{ currencySymbol(receipt.subtotal.currency_code)
                }}{{ receipt.subtotal.amount / receipt.subtotal.divisor }}
              </span>
            </div>
            <div class="flex flex-col">
              <div
                v-if="receipt.message_from_buyer"
                class="rounded w-full info px-2 border mt-2"
              >
                {{ receipt.message_from_buyer }}
              </div>
              <div
                v-for="item in receipt.transactions"
                :key="item.transaction_id"
                class="flex"
              >
                <img
                  v-if="item.images"
                  :src="item.images[0].url_170x135"
                  class="object-cover rounded w-36 h-36 mt-4"
                  alt=""
                />
                <div
                  class="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4 ml-4 lg:w-3/4"
                >
                  <div
                    class="col-span-2 md:col-span-4 text-sm font-bold lg:w-3/4 w-full truncate"
                    :title="decode(item.title)"
                  >
                    {{ decode(item.title) }}
                  </div>
                  <div class="text-sm text-gray-600">
                    Quantity:
                    <span class="font-bold text-black">{{
                      item.quantity
                    }}</span>
                  </div>
                  <div
                    v-for="option in item.variations"
                    :key="option.property_id"
                    class="text-sm text-gray-600"
                  >
                    {{ option.formatted_name }}:
                    <span class="font-bold text-black">{{
                      decode(option.formatted_value)
                    }}</span>
                  </div>
                </div>
                <div class="mt-4 text-sm">
                  <div class="flex gap-4">
                    <span
                      :class="{ 'line-through text-xs': item.buyer_coupon > 0 }"
                      >{{ currencySymbol(item.price.currency_code)
                      }}{{
                        (item.price.amount / item.price.divisor).toFixed(2)
                      }}</span
                    >
                    <div
                      v-if="item.buyer_coupon !== 0"
                      class="flex text-xs justify-center items-center gap-1"
                      title="%10 off"
                    >
                      -{{ currencySymbol(item.price.currency_code)
                      }}{{ item.buyer_coupon }}
                      <font-awesome-icon
                        icon="tag"
                        class="mr-1 text-gray-600"
                      />
                    </div>
                  </div>
                  <div v-if="item.buyer_coupon !== 0">
                    {{ currencySymbol(item.price.currency_code)
                    }}{{
                      (
                        item.price.amount / item.price.divisor -
                        item.buyer_coupon
                      ).toFixed(2)
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="my-4 md:hidden" />
          <div class="w-full md:w-1/4">
            <span class="font-bold">{{ receipt.name }}</span> <br />
            {{ receipt.address.formatted_address }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
