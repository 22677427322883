<script>
import { defineComponent } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ConfirmButton from "@/components/form/ConfirmButton.vue";

export default defineComponent({
  name: "UserBadge",
  components: { ConfirmButton, FontAwesomeIcon },
  props: {
    user: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["detachUser"],
});
</script>

<template>
  <div
    class="bg-gray-500 text-gray-100 px-2 py-0.5 rounded-full w-min flex items-center gap-1 transition-colors cursor-default group whitespace-nowrap"
    :class="{ 'hover:bg-gray-50 hover:text-gray-500 ': editable }"
  >
    <ConfirmButton
      v-if="editable"
      class="hidden group-hover:block w-4 hover:text-red-500"
      text="Bu kullanıcıyı görevden almak istediğinizden emin misiniz?"
      @confirmed="
        () => {
          $emit('detachUser', user);
        }
      "
    >
      <font-awesome-icon icon="link-slash" class="w-4" />
    </ConfirmButton>
    <font-awesome-icon
      icon="circle-user"
      class="w-4"
      :class="{ 'group-hover:hidden': editable }"
    />
    {{ user.name }}
  </div>
</template>

<style scoped></style>
