<template>
  <div class="w-full flex items-start h-screen">
    <div class="flex-[0.3] p-2 h-full">
      <div>
        <h4 class="text-center pb-2">
          Eşleşen Ürünler ({{ props.etsyListings?.length }})
        </h4>
        <p
          v-if="!props.etsyListings || props.etsyListings?.length === 0"
          class="pb-4 text-center"
        >
          Eşleşen ürün bulunamadı. Ürün eşleştirmek için sağdaki arama
          kutucuğunu kullanın.
        </p>
      </div>
      <div class="flex flex-col gap-4 h-full overflow-y-auto scrollbar-thin">
        <EtsyListingCard
          v-for="listing in props.etsyListings"
          :key="listing.id"
          :listing="listing"
        >
          <template #dropdown>
            <button class="px-2" @click="unlinkEtsyListing(listing)">
              <FontAwesomeIcon icon="link-slash" size="sm" />
              Eşleşmeyi kaldır
            </button>
          </template>
        </EtsyListingCard>
      </div>
    </div>

    <EtsyListingSelector
      class="flex-[0.7]"
      :default-keyword="props.defaultKeyword"
      :exclude="listingIds"
      @listing-selected="linkEtsyListing"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import EtsyListingSelector from "../etsy/EtsyListingSelector.vue";

import axiosClient from "@/lib/AxiosClient";
import EtsyListingCard from "../etsy/EtsyListingCard.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const props = defineProps({
  etsyListings: {
    type: Array,
    required: true,
  },
  defaultKeyword: {
    type: String,
    required: false,
    default: null,
  },
  schemaId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["update:etsyListings"]);
const listingIds = computed(() => {
  return props.etsyListings.map((el) => el.listing_id);
});

const unlinkEtsyListing = async (listing) => {
  const { data } = await axiosClient.put(
    `guide/${props.schemaId}/unlink-etsy-listing/${listing.listing_id}`
  );
  emit("update:etsyListings", data.data);
};

const linkEtsyListing = async (listing) => {
  const { data } = await axiosClient.put(
    `guide/${props.schemaId}/link-etsy-listing/${listing.listing_id}`
  );
  emit("update:etsyListings", data.data);
};
</script>
