import { defineStore } from "pinia";
import axios from "@/lib/AxiosClient";
import { useLocalStorage } from "@vueuse/core";
import { useToast } from "vue-toast-notification";
import { safeJsonParse } from "@/lib/Helpers";
const toast = useToast();

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: safeJsonParse(localStorage.getItem("auth/user")),
    token: localStorage.getItem("auth/token"),
  }),
  getters: {
    getUser: (state) => state.user,
    getToken: (state) => state.token,
    isAuthenticated: (state) => state.token !== null,
  },
  actions: {
    async login(email, password) {
      return await axios
        .post(`${import.meta.env.VITE_BACKEND_URL}/auth/login`, {
          email,
          password,
        })
        .then((r) => {
          if (!r?.data?.token) return r;
          window.Echo.connector.options.auth.headers["Authorization"] =
            `Bearer ${r.data.token}`;
          toast.success("Logged in successfully!");
          this.updateUser(r.data.user);
          this.token = r.data.token;
          localStorage.setItem("auth/token", r.data.token);
          return this.user;
        });
    },
    async logout() {
      window.Echo.leave(`User.${this.user.id}`);
      this.user = {};
      this.token = null;
      localStorage.removeItem("auth/user");
      localStorage.removeItem("auth/token");
      return axios
        .get(`${import.meta.env.VITE_BACKEND_URL}/auth/logout`)
        .finally((r) => {
          return this.user;
        });
    },
    async fetchUser() {
      const { data } = await axios.get(
        `${import.meta.env.VITE_BACKEND_URL}/user`
      );
      this.updateUser(data);
    },
    updateUser(user) {
      this.user = user;
      localStorage.setItem("auth/user", JSON.stringify(user));
    },
    can(perm) {
      if (!this.token) return false;
      return (
        Boolean(this.user?.roles.find((r) => r === "Super Admin")) ||
        this.user?.permissions.includes(perm)
      );
    },
    canAnyOf(resource) {
      const regExp = new RegExp(`^.+${resource}.*$`);
      if (!this.token) return false;
      return (
        Boolean(this.user?.roles?.find((r) => r === "Super Admin")) ||
        Boolean(this.user?.permissions?.find((p) => p.match(regExp)))
      );
    },
    canLike(term) {
      if (!this.token) return false;
      return (
        Boolean(this.user?.roles?.find((r) => r === "Super Admin")) ||
        Boolean(this.user?.permissions?.find((p) => p.contains(term)))
      );
    },
  },
});
