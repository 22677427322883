<template>
  <MainHeader />
  
  
  <SearchMenu
    endpoint="product"
    @loaded="response => products = response.data"
  />
  <div class="container mx-auto flex flex-col">
    <div class="flex flex-col mt-4">
      <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-4">
        <ProductCard
          v-for="product in products"
          :key="product.id"
          :product="product"
        />
      </div>
      <Pagination
        :data="pagination"
        @page-changed="changePage"
      />
    </div>
  </div>
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";

import ProductCard from "@/components/cards/ProductCard.vue";
import InputText from "@/components/form/InputText.vue";
import InputSelect from "@/components/form/InputSelect.vue";
import Pagination from "@/components/misc/Pagination.vue";
import SearchMenu from "@/components/misc/SearchMenu.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Products",
  components: { Pagination, InputSelect, InputText, ProductCard, MainHeader, SearchMenu },
  filters:{

  },
  beforeRouteUpdate(to, from){
    if (to.fullPath === '/products'){
      this.search.search = ''
      this.pagination.current_page = 1
      this.getProducts()
      this.getProductsData()
    }
  },
  data(){
    return {
      etsy_shops: [],
      products: [],
    }
  },
  computed:{
    etsyShops(){
      return [{
        name: 'Tümü',
        value: null
      }, ...this.etsy_shops.map(s => {
        return {
          name: s.shop_name,
          value: s.etsy_shop_id
        }
      })]
    },
  },
  mounted() {
    // TODO: Cache etsy shops
    this.getEtsyShops()
  },
  methods:{
    getEtsyShops(){
      this.axios.get('etsy/shop')
        .then(r => {
          this.etsy_shops = r.data
        })
    },
  }

}
</script>

<style scoped>

</style>