<template>
  <div class="w-min">
    <h2
      class="text-3xl font-light cursor-pointer monte text-white"
      @click="$router.push('/')"
    >
      ORDER<span class="font-black">BASE</span>
    </h2>
    <div v-if="$props.credit" class="text-xs text-right w-full font-light">
      BY HEXPRESS
    </div>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    credit: {
      default: false,
    },
  },
};
</script>

<style scoped></style>
